<template>
    <div>
        <el-dialog 
            :visible.sync="RecordDialogVisible"
            :append-to-body="true"
            width="50%"
            :center="true"
            top="10vh"
            :close-on-click-modal="false"
            :before-close="() => closeRecirdDialog()"
            v-loading.fullscreen.lock="login_loading"
        >
            <template #title>
                <span style="font-weight: bold;">{{dialogFormTitle}}</span>
            </template>
            <div>
                <el-form :model="form" :rules="rules" ref="RecordForm" label-width="100px" size="small">
                    <div v-if="RecordDialogType">
                        <fieldset class="fieldsetc bottomc">
                            <legend class="legendc">备案备注</legend>
                            <el-form-item label="备案信息">
                                <el-input type="textarea" :rows="5" v-model="form.record_note"></el-input>
                            </el-form-item>
                        </fieldset>
                        <common-upload-component
                            ref="record_upload"
                            :ffileList="form.record_other"
                            upload_url="contract/record_upload"
                        ></common-upload-component>
                    </div>
                    <div v-else>
                        <fieldset class="fieldsetc bottomc">
                            <legend class="legendc">备案信息</legend>
                            <el-descriptions
                                class="margin-top" 
                                :column="1"
                                :labelStyle="{'width':'120px','text-align':'center'}"
                                :contentStyle="{'text-align': 'center'}"
                                border
                            >
                                <el-descriptions-item :contentStyle="{'text-align':'left','white-space':'pre-line'}">
                                    <template slot="label">
                                        <span>备案备注</span>
                                    </template>
                                    <span>{{form.record_note}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>备案时间</span>
                                    </template>
                                    <span>{{form.record_time}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>备案时间</span>
                                    </template>
                                    <span>{{form.record_staff_name}}</span>
                                </el-descriptions-item>
                            </el-descriptions>
                        </fieldset>
                        <common-upload-component
                            v-show="form.record_other.length"
                            ref="record_upload"
                            :ffileList="form.record_other"
                            upload_url="contract/record_upload"
                            :disabled="true"
                        ></common-upload-component>
                    </div>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer">
                <div v-if="RecordDialogType===0">
                    <el-button v-if="record_edit_auth" type="primary" plain @click="$emit('edit_recird')">修改</el-button>
                    <el-button v-if="record_del_auth" type="danger" plain @click="DelRecird()">删除</el-button>
                    <el-button plain @click="closeRecirdDialog()">关闭</el-button>
                </div>
                <div v-else-if="RecordDialogType===1">
                    <el-button type="success" plain @click="InsertRecird()">确定</el-button>
                    <el-button plain @click="closeRecirdDialog()">取消</el-button>
                </div>
                <div v-else>
                    <el-button type="success" plain @click="EditRecird()">确定修改</el-button>
                    <el-button plain @click="closeRecirdDialog()">取消操作</el-button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import CommonUploadComponent from '@/components/common/CommonUploadComponent'
import {
    contract_record_add_request,
    contract_record_edit_request,
    contract_record_del_request
} from '@/network/finance/Contract.js'

export default {
    name:'',
    data(){
        return {
            login_loading: false,
            form: {
                record_note: '',
                record_other: []
            },
            rules: {
                record_note: [
                    {
                        required: true,
                        message: '请填写备案信息',
                        trigger: 'blur',
                    }
                ]
            }
        }
    },
    computed:{
        dialogFormTitle() {
            if(this.RecordDialogType === 0) {
                return '备案信息详情'
            } else if (this.RecordDialogType === 1) {
                return '添加备案信息'
            } else {
                return '修改备案信息'
            }
        },
    },
    methods:{
        closeRecirdDialog() {
            this.form = {
                record_note: '',
                record_other: []
            }
            this.$emit('exitRecirdDialog')
        },
        InsertRecird() {
            this.login_loading = true
            let d = this.form
            d.id = this.id
            d.record_other = this.$refs.record_upload.fileList
            contract_record_add_request(d)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.$message.success('添加成功!')
                        this.closeRecirdDialog()
                        this.$emit('refresh_details_index')
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        EditRecird() {
            this.login_loading = true
            let d = this.form
            d.id = this.id
            d.record_other = this.$refs.record_upload.fileList
            contract_record_edit_request(d)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.$message.success('修改成功!')
                        this.closeRecirdDialog()
                        this.$emit('refresh_details_index')
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        DelRecird() {
            this.$confirm(
                '是否确定删除？（一经删除，将无法再进行恢复，请谨慎操作！）',
                '温馨提示',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            )
                .then(() => {
                    contract_record_del_request(this.id)
                        .then((s) => {
                            if (s.status === 0) {
                                this.$message.success('删除成功!')
                                this.closeRecirdDialog()
                                this.$emit('refresh_details_index')
                            } else {
                                this.$message.error(s.msg)
                            }
                        })
                        .catch((err) => {
                            this.$message.error(err)
                        })
                        .finally(() => this.closeRecirdDialog())
                })
                .catch((err) => {})
        }
    },
    filters:{},
    props:{
        id: {
            type: Number,
            default() {
                return 0
            }
        },
        RecordDialogVisible: {
            type: Boolean,
            default() {
                return false
            }
        },
        RecordDialogType: {
            type: Number,
            default() {
                return 0
            }
        },
        record_edit_auth: {
            type: Boolean,
            default() {
                return false
            }
        },
        record_del_auth: {
            type: Boolean,
            default() {
                return false
            }
        },
        contract_record_details_data: {
            type: Object,
            default() {
                return {}
            }
        },
        contract_record_history_data: {
            type: Array,
            default() {
                return []
            }
        }
    },
    created(){},
    destoryed(){},
    mounted(){},
    updated(){},
    components:{
        CommonUploadComponent
    },
    watch:{
        contract_record_details_data(newVal) {
            this.form = newVal
        },
        contract_record_history_data(newVal) {
            this.form.record_other = newVal
        }
    }
}
</script>

<style>
</style>