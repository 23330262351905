<template>
    <div>
        <el-dialog
            :visible.sync="show_print"
            :append-to-body="true"
            width="60%"
            :center="true"
            top="10vh"
            :close-on-click-modal="false"
            :before-close="() => $emit('exit_print')"
        >
            <div ref="PrintContract">
                <p style="text-align: center;">
                    <b>
                        <span style="font-family: Microsoft YaHei;font-size:18px;">{{data | title_name}}</span>
                    </b>
                </p>
                <table style="font-family: Microsoft YaHei;font-size:15px;width: 100%;border: 1px solid black;">
                    <tr style="border: 1px solid black;">
                        <td style="height: 40px;border: 1px solid black;text-align: center;font-weight:bold;">合同标题</td>
                        <td style="border: 1px solid black;text-align: center;" colspan=3>{{data.title}}</td>
                    </tr>
                    <tr style="border: 1px solid black;">
                        <td style="height: 50px;width: 15%;border: 1px solid black;text-align: center;font-weight:bold;">我方企业</td>
                        <td style="width: 45%;border: 1px solid black;text-align: center;" colspan=3>{{data.enterprise_name}}</td>                        
                    </tr>
                    <tr style="border: 1px solid black;">
                        <td style="width: 15%; height: 40px;border: 1px solid black;text-align: center;font-weight:bold;">主体数量</td>
                        <td style="width: 35%;border: 1px solid black;text-align: center;">{{AllSubjectNum[data.subject_num]}}</td>
                        <td style="width: 15%;border: 1px solid black;text-align: center;font-weight:bold;">到期选项</td>
                        <td style="width: 35%;border: 1px solid black;text-align: center;">{{['到期终止','顺延一年','自动顺延'][data.expire_option]}}</td>
                    </tr>
                    <tr style="border: 1px solid black;">
                        <td style="width: 15%; height: 40px;border: 1px solid black;text-align: center;font-weight:bold;">备案状态</td>
                        <td style="width: 35%;border: 1px solid black;text-align: center;">{{data.record_state_name}}</td>
                        <td style="width: 15%;border: 1px solid black;text-align: center;font-weight:bold;">履行状态</td>
                        <td style="width: 35%;border: 1px solid black;text-align: center;">{{data.perform_state_name}}</td>
                    </tr>
                    <tr style="border: 1px solid black;">
                        <td style="width: 15%;height: 40px;border: 1px solid black;text-align: center;font-weight:bold;">合同类型</td>
                        <td style="width: 35%;border: 1px solid black;text-align: center;">{{AllContractType[data.contract_type]}}</td>
                        <td style="width: 15%;border: 1px solid black;text-align: center;font-weight:bold;">业务类型</td>
                        <td style="width: 35%;border: 1px solid black;text-align: center;">{{AllBusinessType[data.business_type]}}</td>
                    </tr>
                    <tr style="border: 1px solid black;">
                        <td style="width: 15%;height: 50px;border: 1px solid black;text-align: center;font-weight:bold;">甲方公司</td>
                        <td style="width: 35%;border: 1px solid black;text-align: center;" >{{data.company_a_name}}</td>
                        <td style="width: 15%;border: 1px solid black;text-align: center;font-weight:bold;">乙方公司</td>
                        <td style="width: 35%;border: 1px solid black;text-align: center;">{{data.company_b_name}}</td>
                    </tr>
                    <tr style="border: 1px solid black;">
                        <td style="width: 15%;height: 40px;border: 1px solid black;text-align: center;font-weight:bold;">甲方联系人</td>
                        <td style="width: 35%;border: 1px solid black;text-align: center;">{{data.company_a_person}}</td>
                        <td style="width: 15%;height: 40px;border: 1px solid black;text-align: center;font-weight:bold;">乙方联系人</td>
                        <td style="width: 45%;border: 1px solid black;text-align: center;">{{data.company_b_person}}</td>                        
                    </tr>
                    <tr style="border: 1px solid black;">
                        <td style="width: 15%;height: 40px;border: 1px solid black;text-align: center;font-weight:bold;">甲方电话</td>
                        <td style="width: 35%;border: 1px solid black;text-align: center;">{{data.company_a_phone}}</td>
                        <td style="width: 15%;border: 1px solid black;text-align: center;font-weight:bold;">乙方电话</td>
                        <td style="width: 25%;border: 1px solid black;text-align: center;">{{data.company_b_phone}}</td>                                 
                    </tr>
                    <tr style="border: 1px solid black;" v-if="data.subject_num>0">
                        <td style="height: 50px;border: 1px solid black;text-align: center;font-weight:bold;">丙方公司</td>
                        <td style="border: 1px solid black;text-align: center;">{{data.company_c_name}}</td>
                        <td style="border: 1px solid black;text-align: center;font-weight:bold;">丁方公司</td>
                        <td style="border: 1px solid black;text-align: center;">{{data.company_d_name}}</td>
                    </tr>
                    <tr style="border: 1px solid black;" v-if="data.subject_num>0">
                        <td style="width: 15%;height: 40px;border: 1px solid black;text-align: center;font-weight:bold;">丙方联系人</td>
                        <td style="width: 45%;border: 1px solid black;text-align: center;">{{data.company_c_person}}</td>
                        <td style="width: 15%;height: 40px;border: 1px solid black;text-align: center;font-weight:bold;">丁方联系人</td>
                        <td style="width: 45%;border: 1px solid black;text-align: center;">{{data.company_d_person}}</td>  
                    </tr>
                    <tr style="border: 1px solid black;" v-if="data.subject_num===2">
                        <td style="width: 15%;border: 1px solid black;text-align: center;font-weight:bold;">丙方电话</td>
                        <td style="width: 25%;border: 1px solid black;text-align: center;">{{data.company_c_phone}}</td>
                        <td style="width: 15%;border: 1px solid black;text-align: center;font-weight:bold;">丁方电话</td>
                        <td style="width: 25%;border: 1px solid black;text-align: center;">{{data.company_d_phone}}</td>                                              
                    </tr>
                    <tr style="border: 1px solid black;">
                        <td style="width: 15%;height: 40px;border: 1px solid black;text-align: center;font-weight:bold;">开始日期</td>
                        <td style="width: 45%;border: 1px solid black;text-align: center;">{{data.start_date}}</td>
                        <td style="width: 15%;border: 1px solid black;text-align: center;font-weight:bold;">结束日期</td>
                        <td style="width: 25%;border: 1px solid black;text-align: center;">{{data.end_date}}</td>
                    </tr>
                    <tr style="border: 1px solid black;">
                        <td style="width: 15%;height: 40px;border: 1px solid black;text-align: center;font-weight:bold;">发票类型</td>
                        <td style="width: 45%;border: 1px solid black;text-align: center;">{{data.invoice_type_name}}</td>
                        <td style="width: 15%;border: 1px solid black;text-align: center;font-weight:bold;">结算币种</td>
                        <td style="width: 25%;border: 1px solid black;text-align: center;">{{data.settlement_currency_name}}</td>
                    </tr>
                    <tr style="border: 1px solid black;">
                        <td style="height: 40px;border: 1px solid black;text-align: center;font-weight:bold;">结算方式</td>
                        <td style="border: 1px solid black;text-align: center;" colspan=3>{{ settlement_mode_name }}</td>
                    </tr>
                    <tr style="border: 1px solid black;">
                    <td style="width: 15%;border: 1px solid black;text-align: center;font-weight:bold;">结算周期</td>
                        <td style="height: 40px;width: 25%;border: 1px solid black;text-align: center;">{{AllSettlementCycle[data.settlement_cycle]}}</td>
                        <td style="width: 15%;border: 1px solid black;text-align: center;font-weight:bold;">支付方式</td>
                        <td style="width: 45%;border: 1px solid black;text-align: center;">{{data.pay_way_name}}</td>
                    </tr>
                    <tr style="border: 1px solid black;">
                        <td style="height: 110px; border: 1px solid black;text-align: center;font-weight:bold;">赔付风险</td>
                        <td style="border: 1px solid black; white-space: pre-line;" colspan=3>{{data.risk}}</td>
                    </tr>
                    <tr style="border: 1px solid black;">
                        <td style="height: 110px; border: 1px solid black;text-align: center;font-weight:bold;">合同备注</td>
                        <td style="border: 1px solid black; white-space: pre-line;"  colspan=3>{{data.contract_note}}</td>
                    </tr>                   
                    <tr style="border: 1px solid black;">
                        <td style="height: 40px;border: 1px solid black;text-align: center;font-weight:bold;">审批人</td>
                        <td style="border: 1px solid black;text-align: center;" colspan=3>{{examine}}</td>
                    </tr>
                    <tr style="border: 1px solid black;">
                        <td style="height: 40px;width: 15%;border: 1px solid black;text-align: center;font-weight:bold;">负责人</td>
                        <td style="width: 25%;border: 1px solid black;text-align: center;" colspan=3>{{data.staff_name}}</td>
                    </tr>
                </table>
                <div v-for="(iteam,index) in data.print" :key="iteam.time" style="font-family: Microsoft YaHei;font-size:12px;">
                    {{'【历史】此合同为第'+(index+1)+'次打印，打印人：'+iteam.staff+'，打印时间：'+iteam.time}}
                </div>
                <div style="font-family: Microsoft YaHei;font-size:12px;">
                    {{'【当前】此合同为第'+(data.print.length+1)+'次打印，打印人：'+$store.state.user.name+'，打印时间：' + NowTime}}
                </div>
                <br/>
                
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button
                    plain
                    type="primary"
                    @click="printBtn('PrintContract')"
                    >打印</el-button
                >
                <el-button
                    plain
                    @click="() => $emit('exit_print')"
                    >关闭</el-button
                >
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { contract_print_request } from '@/network/finance/Contract.js'
import { now } from '@/assets/js/common.js'

export default {
    name:'ContractPrintComponent',
    data(){
        return {
            examine: '',
            NowTime: ''
        }
    },
    computed:{
        settlement_mode_name() {
            if((this.data.settlement_mode !=='') && (this.data.settlement_day_type !=='') ) {
                if(this.data.settlement_mode === 7) {
                    return '次月' + this.data.settlement_day_num + '日之前'
                } else if(this.data.settlement_mode === 10) {
                    return '本月' + this.data.settlement_day_num + '日之前'
                }else {
                    return this.AllSettlementMode[this.data.settlement_mode] + this.data.settlement_day_num+'个'+this.AllSettlementDayType[this.data.settlement_day_type]+'内'
                }
            } else {
                return ''
            }
        }
    },
    methods:{
        printBtn(formRef) {
            this.login_loading = true
            contract_print_request(this.data.id)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.$print(this.$refs[formRef])
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
    },
    filters:{
        title_name(v) {
            let a = '合同：'+v.name+' '
            if(v.state === 0) {
                a += '【待提交】'
            } else if(v.state === 1) {
                a += '【审批中】'
            } else if(v.state === 2) {
                a += '【已通过】'
            } else if(v.state === 3) {
                a += '【已拒绝】'
            }

            return a
        }
    },
    props:{
        show_print: {
            type: Boolean,
            default() {
                return false
            }
        },
        data: {
            type: Object,
            default() {
                return {}
            }
        },
        ApprovalData: {
            type: Array,
            default() {
                return []
            }
        },
        AllUrgentState: Array,
        AllBusinessType: Array,
        AllContractType: Array,
        AllSubjectNum: Array,
        AllSettlementMode: Array,
        AllSettlementDayType: Array,
        AllSettlementCycle: Array
    },
    created(){},
    destoryed(){},
    mounted(){},
    updated(){
        this.NowTime = now()
    },
    components:{},
    watch:{
        ApprovalData(newVal) {
            this.examine = ''
            newVal.forEach(v => {
                if(v.start_staff_id) {
                    this.examine = v.start_staff_name+'(发起)'
                }
                if(v.staff_id === -1) {
                    this.examine += '→'+v.staff_name+'(撤回)'
                }
                if((v.staff_id>0) && (!v.time)) {
                    this.examine += '→'+v.staff_name+'(待审批)'
                }
                if(v.opinion) {
                    this.examine += '→'+v.staff_name+'('+v.opinion+')'
                }
                if(v.staff_id === 0) {
                    this.examine += '→流程结束'
                }
            });
        }
    }
}
</script>

<style>
</style>