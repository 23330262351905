import request from '../request'

//补签申请列表
export const supplement_clock_in_index_request = p => {
    return request({
        method:'GET',
        url:'supplement_clock_in/index',
        params: p
    })
}

//发起补签申请
export const supplement_clock_in_add_request = d => {
    return request({
        method:'POST',
        url:'supplement_clock_in/add',
        data: d
    })
}

//删除补签申请
export const supplement_clock_in_del_request = id => {
    return request({
        method:'DELETE',
        url:'supplement_clock_in/del',
        data: {
            id: id
        }
    })
}

//修改补签申请
export const supplement_clock_in_edit_request = data => {
    return request({
        method:'PUT',
        url:'supplement_clock_in/edit',
        data
    })
}

//获取补签申请详情
export const supplement_clock_in_details_request = id => {
    return request({
        method:'GET',
        url:'supplement_clock_in/details',
        params: {
            id
        }
    })
}

// 提交补签申请
export const supplement_clock_in_to_approval_request = d => {
    return request({
        method:'PUT',
        url:'supplement_clock_in/to_approval',
        data: {
            id:d.id,
            note:d.note
        }
    })
}

// 查看补签申请审批记录
export const supplement_clock_in_sel_approval_request = id => {
    return request({
        method:'GET',
        url:'supplement_clock_in/sel_approval',
        params: {
            id
        }
    })
}

// 审批补签申请
export const supplement_clock_in_approval_request = d => {
    return request({
        method:'PUT',
        url:'supplement_clock_in/approval',
        data: {
            id: d.id,
            opinion: d.opinion,
            note: d.note,
            other: d.other,
            go_staff_id: d.go_staff_id
        }
    })
}

// 撤回补签申请
export const supplement_clock_in_withdraw_request = d => {
    return request({
        method:'PUT',
        url:'supplement_clock_in/withdraw',
        data: {
            id: d.id
        }
    })
}

// 打印补签申请
export const supplement_clock_in_print_request = id => {
    return request({
        method:'PUT',
        url:'supplement_clock_in/print',
        data: {
            id
        }
    })
}

// 补充补签申请附件
export const supplement_clock_in_add_file_request = data => {
    return request({
        method:'PUT',
        url:'supplement_clock_in/add_file',
        data
    })
}