import request from '../request'

//中远订单申请列表
export const cop_order_index_request = p => {
    return request({
        method:'GET',
        url:'cop_order/index',
        params: p
    })
}

//新增中远订单申请
export const cop_order_add_request = d => {
    return request({
        method:'POST',
        url:'cop_order/add',
        data: d
    })
}

//删除中远订单申请
export const cop_order_del_request = id => {
    return request({
        method:'DELETE',
        url:'cop_order/del',
        data: {
            id: id
        }
    })
}

//中远订单申请详情
export const cop_order_details_request = id => {
    return request({
        method:'GET',
        url:'cop_order/details',
        params: {
            id
        }
    })
}

// 提交中远订单申请
export const cop_order_to_approval_request = d => {
    return request({
        method:'PUT',
        url:'cop_order/to_approval',
        data: {
            id:d.id,
            note:d.note
        }
    })
}

// 查看中远订单申请审批记录
export const cop_order_sel_approval_request = id => {
    return request({
        method:'GET',
        url:'cop_order/sel_approval',
        params: {
            id
        }
    })
}

// 审批中远订单申请
export const cop_order_approval_request = d => {
    return request({
        method:'PUT',
        url:'cop_order/approval',
        data: {
            id: d.id,
            opinion: d.opinion,
            note: d.note,
            other: d.other,
            go_staff_id: d.go_staff_id
        }
    })
}

// 撤回中远订单申请
export const cop_order_withdraw_request = d => {
    return request({
        method:'PUT',
        url:'cop_order/withdraw',
        data: {
            id: d.id
        }
    })
}