<template>
    <div>
        <el-dialog
            :visible.sync="dialogFormVisible"
            :append-to-body="true"
            width="80%"
            :center="true"
            top="1vh"
            :close-on-click-modal="false"
            :before-close="() => closeDialog()"
            v-loading.fullscreen.lock="login_loading"
        >
            <template #title>
                <span style="font-weight: bold;">{{dialogFormTitle}}</span>
                <el-tag v-if="StaffEntry.state===0" type="info" size="mini">待提交</el-tag>
                <el-tag v-if="StaffEntry.state===1" size="mini">审批中</el-tag>
                <el-tag v-if="StaffEntry.state===2" type="success" size="mini">已通过</el-tag>
                <el-tag v-if="StaffEntry.state===3" type="danger" size="mini">已驳回</el-tag>
                <el-button
                    v-if="(StaffEntry.state>0) && ($_has(print_auth))"
                    plain
                    circle
                    icon="el-icon-printer"
                    @click="clk_print()"
                    ></el-button
                >
            </template>
            <div>
                <el-form
                    :model="StaffEntry"
                    :rules="rules"
                    ref="StaffEntryEditForm"
                    label-width="100px"
                    size="small"
                >
                    <div v-if="show_type">
                        <fieldset class="fieldsetc">
                            <legend class="legendc">职位信息</legend>
                            <el-row :gutter="10">
                                <el-col :span="12">
                                    <el-form-item label="入职企业" prop="entry_enterprise">
                                        <el-select
                                            v-model="StaffEntry.entry_enterprise"
                                            placeholder="选择企业"
                                            class="entry_enterprisec"
                                            filterable
                                            clearable
                                            @change="enterprise_change"
                                        >
                                            <el-option
                                                v-for="enterprise in AllEnterprise"
                                                :key="enterprise.name"
                                                :label="enterprise.name"
                                                :value="enterprise.id"
                                            ></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="入职部门" prop="entry_department">
                                        <el-select
                                            v-model="StaffEntry.entry_department"
                                            filterable
                                            placeholder="选择部门"
                                            class="entry_departmentc"
                                            clearable
                                            @change="department_change"
                                        >
                                            <el-option
                                                v-for="department in AllDepartment"
                                                :key="department.id"
                                                :label="department.name"
                                                :value="department.id"
                                            ></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row :gutter="10">
                                <el-col :span="12">
                                    <el-form-item label="入职职位" prop="entry_position">
                                        <el-select
                                            v-model="StaffEntry.entry_position"
                                            filterable
                                            placeholder="选择职位"
                                            class="entry_positionc"
                                            clearable
                                        >
                                            <el-option
                                                v-for="position in AllPosition"
                                                :key="position.id"
                                                :label="position.name"
                                                :value="position.id"
                                            ></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="入职职级" prop="rank">
                                        <el-select
                                            v-model="StaffEntry.rank"
                                            filterable
                                            placeholder="选择职级"
                                            class="rankc"
                                            clearable
                                        >
                                            <el-option
                                                v-for="(v,i) in AllRank"
                                                :key="i"
                                                :label="v"
                                                :value="i"
                                            ></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row :gutter="10">
                                <el-col :span="12">
                                    <el-form-item label="入职日期" prop="entry_date">
                                        <el-date-picker
                                            v-model="StaffEntry.entry_date"
                                            type="date"
                                            value-format="yyyy-MM-dd"
                                            placeholder="选择日期">
                                        </el-date-picker>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item  label="试用期限" prop="trial_time">
                                        <el-input type="text" v-model="StaffEntry.trial_time" class="trial_timec"></el-input>                            
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row :gutter="10">
                                <el-col :span="12">
                                    <el-form-item label="试用薪资" prop="trial_money">
                                        <el-input-number
                                            v-model="StaffEntry.trial_money"
                                            :min="0"
                                            :step="10"
                                            :precision="2"
                                            :step-strictly="true"
                                            size="small"
                                            class="input_number"
                                        ></el-input-number>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item  label="转正薪资" prop="formal_money">
                                        <el-input-number
                                            v-model="StaffEntry.formal_money"
                                            :min="0"
                                            :step="10"
                                            :precision="2"
                                            :step-strictly="true"
                                            size="small"
                                            class="input_number"
                                        ></el-input-number>                         
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-form-item label="其它福利待遇">
                                <el-input type="textarea" :rows="3" v-model="StaffEntry.other_benefits" placeholder="填写特殊福利待遇，如需要公司提供住房，公司报销油费等"></el-input>
                            </el-form-item>
                        </fieldset>
                        <fieldset class="fieldsetc">
                            <legend class="legendc">入职人员信息</legend>
                            <el-row :gutter="20">
                                <el-col :span="12">
                                    <el-form-item  label="姓名" prop="staff_name">
                                        <el-input type="text" v-model="StaffEntry.staff_name" class="staff_namec"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="性别" prop="sex">
                                        <el-radio-group v-model="StaffEntry.sex">
                                            <el-radio
                                                v-for="(v, i) in this.$store.state.sex"
                                                :label="i"
                                                :key="i"
                                                >{{ v }}</el-radio
                                            >
                                        </el-radio-group>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row :gutter="20">
                                <el-col :span="12">
                                    <el-form-item  label="身份证" prop="icard">
                                        <el-input type="text" v-model="StaffEntry.icard" class="icardc"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="学历" prop="education">
                                        <el-select
                                            v-model="StaffEntry.education"
                                            placeholder="请选择学历"
                                            filterable
                                            clearable
                                            class="educationc"
                                        >
                                            <el-option
                                                v-for="(v, i) in this.$store.state.education"
                                                :label="v"
                                                :value="i"
                                                :key="i"
                                            ></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row :gutter="20">
                                <el-col :span="12">
                                    <el-form-item label="婚姻状态" prop="marriage">
                                        <el-radio-group v-model="StaffEntry.marriage">
                                            <el-radio
                                                v-for="(v, i) in this.$store.state.marriage"
                                                :label="i"
                                                :key="i"
                                                >{{ v }}</el-radio
                                            >
                                        </el-radio-group>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item  label="手机号" prop="phone">
                                        <el-input type="text" v-model="StaffEntry.phone" class="phonec"></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-form-item label="住址" prop="address">
                                <el-input type="textarea" :rows="3" v-model="StaffEntry.address"></el-input>
                            </el-form-item>
                        </fieldset>
                        <fieldset class="fieldsetc">
                            <legend class="legendc">紧急联系人信息</legend>
                            <el-row :gutter="20">
                                <el-col :span="8">
                                    <el-form-item label="姓名" prop="family_name">
                                        <el-input type="text" v-model="StaffEntry.family_name" class="family_namec"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="关系" prop="family_relation">
                                        <el-input type="text" v-model="StaffEntry.family_relation" class="family_relationc"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="电话" prop="family_phone">
                                        <el-input type="text" v-model="StaffEntry.family_phone" class="family_phonec"></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </fieldset>
                        <common-upload-component
                            ref="upload"
                            :ffileList="StaffEntry.other"
                            upload_url="staff_entry/upload"
                            title="简历及相关附件"
                        ></common-upload-component>
                    </div>
                    <div v-else>
                        <fieldset class="fieldsetc">
                            <legend class="legendc">职位信息</legend>
                            <el-descriptions
                                class="margin-top"
                                :column="2"
                                :labelStyle="{'width':'120px','text-align':'center'}"
                                :contentStyle="{'text-align': 'center'}"
                                border
                            >
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>入职企业</span>
                                    </template>
                                    <span>{{StaffEntry.entry_enterprise_name}}</span>
                                </el-descriptions-item>                            
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>入职部门</span>
                                    </template>
                                    <span>{{StaffEntry.entry_department_name}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>入职职位</span>
                                    </template>
                                    <span>{{StaffEntry.entry_position_name}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>入职职级</span>
                                    </template>
                                    <span>{{StaffEntry.rank_name}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>入职日期</span>
                                    </template>
                                    <span>{{StaffEntry.entry_date}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>试用期限</span>
                                    </template>
                                    <span>{{StaffEntry.trial_time}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>试用薪资</span>
                                    </template>
                                    <span>{{StaffEntry.trial_money | fomatFloatMoney}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>转正薪资</span>
                                    </template>
                                    <span>{{StaffEntry.formal_money | fomatFloatMoney}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item :span="2" :contentStyle="{'text-align':'left','white-space':'pre-line'}">
                                    <template slot="label">
                                        <span>其它福利</span>
                                    </template>
                                    <span>{{StaffEntry.other_benefits}}</span>
                                </el-descriptions-item>
                            </el-descriptions>
                        </fieldset>
                        <fieldset class="fieldsetc">
                            <legend class="legendc">入职人员信息</legend>
                            <el-descriptions
                                class="margin-top"
                                :column="2"
                                :labelStyle="{'width':'120px','text-align':'center'}"
                                :contentStyle="{'text-align': 'center'}"
                                border
                            >
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>姓名</span>
                                    </template>
                                    <span>{{StaffEntry.staff_name}}</span>
                                </el-descriptions-item>                            
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>性别</span>
                                    </template>
                                    <span>{{StaffEntry.sex_name}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>身份证</span>
                                    </template>
                                    <span>{{StaffEntry.icard}}</span>
                                </el-descriptions-item>                            
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>最高学历</span>
                                    </template>
                                    <span>{{StaffEntry.education_name}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>婚姻状态</span>
                                    </template>
                                    <span>{{StaffEntry.marriage_name}}</span>
                                </el-descriptions-item>                            
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>手机</span>
                                    </template>
                                    <span>{{StaffEntry.phone}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item :span="2" :contentStyle="{'text-align':'left','white-space':'pre-line'}">
                                    <template slot="label">
                                        <span>住址</span>
                                    </template>
                                    <span>{{StaffEntry.address}}</span>
                                </el-descriptions-item>
                            </el-descriptions>
                        </fieldset>
                        <fieldset class="fieldsetc">
                            <legend class="legendc">紧急联系人信息</legend>
                            <el-descriptions
                                class="margin-top"
                                :column="3"
                                :labelStyle="{'width':'120px','text-align':'center'}"
                                :contentStyle="{'text-align': 'center'}"
                                border
                            >
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>姓名</span>
                                    </template>
                                    <span>{{StaffEntry.family_name}}</span>
                                </el-descriptions-item>                            
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>关系</span>
                                    </template>
                                    <span>{{StaffEntry.family_relation}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>电话</span>
                                    </template>
                                    <span>{{StaffEntry.family_phone}}</span>
                                </el-descriptions-item>
                            </el-descriptions>
                        </fieldset>
                        <common-upload-component
                            v-show="StaffEntry.other.length"
                            ref="upload"
                            :ffileList="StaffEntry.other"
                            upload_url="staff_entry/upload"
                            :disabled="true"
                            title="简历及相关附件"
                        ></common-upload-component>
                        <common-upload-component
                            v-show="AddFileDialogVisible"
                            ref="file_upload"
                            :ffileList="file_arr"
                            upload_url="staff_entry/upload"
                            title="补充附件"
                        ></common-upload-component>
                    </div>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer">
                <div v-if="show_type===0">
                    <div v-if="AddFileDialogVisible === false">
                        <el-button
                            v-if="add_file_auth"
                            type="success"
                            plain
                            @click="add_file()"
                            >补充附件</el-button
                        >
                        <el-button
                            v-if="to_approval_auth"
                            type="success"
                            plain
                            @click="to_approval()"
                            >提交</el-button
                        >
                        <el-button
                            v-if="sel_approval_auth"
                            type="warning"
                            plain
                            @click="sel_approval()"
                            >查看审批</el-button
                        >
                        <el-button
                            v-if="withdraw_auth"
                            type="danger"
                            plain
                            @click="StaffEntryWithdraw()"
                            >审批撤回</el-button
                        >
                        <el-button
                            v-if="edit_auth"
                            type="primary"
                            plain
                            @click="edit_clk()"
                            >修改</el-button
                        >
                        <el-button
                            v-if="del_auth"
                            type="danger"
                            plain
                            @click="StaffEntryDel()"
                            >删除</el-button
                        >
                        <el-button
                            plain
                            @click="closeDialog()"
                            >关闭</el-button
                        >
                    </div>
                    <div v-else>
                        <el-button
                            type="success"
                            plain
                            @click="submit_add_file()"
                            >确定</el-button
                        >
                        <el-button
                            plain
                            @click="exit_add_file()"
                            >取消</el-button
                        >
                    </div>
                </div>
                <div v-else-if="show_type===1">
                    <el-button
                        type="success"
                        plain
                        @click="submitInsert('StaffEntryEditForm')"
                        >确定</el-button
                    >
                    <el-button
                        type="warning"
                        plain
                        @click="resetForm('StaffEntryEditForm')"
                        >重置</el-button
                    >
                    <el-button
                        plain
                        @click="closeDialog()"
                        >关闭</el-button
                    >
                </div>
                <div v-else>
                    <el-button
                        type="success"
                        plain
                        @click="submitEdit('StaffEntryEditForm')"
                        >确定修改</el-button
                    >
                    <el-button
                        plain
                        @click="closeDialog()"
                        >取消操作</el-button
                    >
                </div>
                <common-approval-dialog
                    :id="id"
                    :ApprovalDialogVisible="ApprovalDialogVisible"
                    :data="ApprovalData"
                    :current_examine_staff_id="StaffEntry.current_examine_staff_id"
                    :ApprovalAuth="ApprovalAuth"
                    :ApprovalRequest="StaffEntryApprovalRequest"
                    @exitApprovalDialog="exitApprovalDialog"
                    @refresh="$emit('frefresh')"
                >
                </common-approval-dialog>
                <staff-entry-print-component
                    :show_print="show_print"
                    :data="StaffEntry"
                    :ApprovalData="ApprovalData"
                    @exit_print="show_print = false"
                >
                </staff-entry-print-component>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import CommonUploadComponent from '@/components/common/CommonUploadComponent'
import CommonApprovalDialog from '@/components/common/CommonApprovalDialog'
import StaffEntryPrintComponent from './StaffEntryPrintComponent'

import { department_list_request,position_list_request } from '@/network/list.js'
import { thousandBitSeparator } from '@/assets/js/common.js'

import {
    staff_entry_add_request,
    staff_entry_del_request,
    staff_entry_edit_request,
    staff_entry_approval_request,
    staff_entry_sel_approval_request,
    staff_entry_to_approval_request,
    staff_entry_withdraw_request,
    staff_entry_add_file_request
} from '@/network/hrm/StaffEntry.js'

export default {
    name: 'StaffEntryEditComponent',
    data() {
        return {
            login_loading: false,
            ApprovalDialogVisible: false,
            StaffEntry: {
                entry_enterprise: '',
                entry_department: '',
                entry_position: '',
                rank: '',
                entry_date: '',
                trial_time: '',
                trial_money: '',
                formal_money: '',
                other_benefits: '',
                staff_name: '',
                sex: '',
                icard: '',
                education: '',
                marriage: '',
                phone: '',
                address: '',
                family_name: '',
                family_relation: '',
                family_phone: '',
                other: [],
                print: []
            },
            rules: {
                entry_enterprise: [
                    {
                        required: true,
                        message: '请填选择入职企业',
                        trigger: 'blur',
                    },
                ],
                entry_department: [
                    {
                        required: true,
                        message: '请填选择入职部门',
                        trigger: 'blur',
                    },
                ],
                entry_position: [
                    {
                        required: true,
                        message: '请填选择入职职位',
                        trigger: 'blur',
                    },
                ],
                rank: [
                    {
                        required: true,
                        message: '请选择职级',
                        trigger: 'blur',
                    }
                ],
                entry_date: [
                    {
                        required: true,
                        message: '请选择入职日期',
                        trigger: 'blur',
                    },
                ],
                trial_time: [
                    {
                        required: true,
                        message: '请填写试用期限',
                        trigger: 'blur',
                    },
                ],
                trial_money: [
                    {
                        required: true,
                        message: '请填写试用薪资',
                        trigger: 'blur',
                    },
                ],
                formal_money: [
                    {
                        required: true,
                        message: '请填写转正薪资',
                        trigger: 'blur',
                    },
                ],
                staff_name: [
                    {
                        required: true,
                        message: '请填写入职员工姓名',
                        trigger: 'blur',
                    },
                ],
                sex: [
                    {
                        required: true,
                        message: '请填写入职员工性别',
                        trigger: 'blur',
                    },
                ],
                icard: [
                    {
                        required: true,
                        message: '请填写入职员工身份证',
                        trigger: 'blur',
                    },
                ],
                education: [
                    {
                        required: true,
                        message: '请选择入职员工最高学历',
                        trigger: 'blur',
                    },
                ],
                marriage: [
                    {
                        required: true,
                        message: '请选择入职员工婚姻状态',
                        trigger: 'blur',
                    },
                ],
                phone: [
                    {
                        required: true,
                        message: '请填写入职员工电话',
                        trigger: 'blur',
                    },
                ],
                address: [
                    {
                        required: true,
                        message: '请填写入职员工住址',
                        trigger: 'blur',
                    },
                ],
                family_name: [
                    {
                        required: true,
                        message: '请填写紧急联系人姓名',
                        trigger: 'blur',
                    },
                ],
                family_relation: [
                    {
                        required: true,
                        message: '请填写紧急联系人关系',
                        trigger: 'blur',
                    },
                ],
                family_phone: [
                    {
                        required: true,
                        message: '请填写紧急联系人住址',
                        trigger: 'blur',
                    },
                ]
            },
            ApprovalData: [],
            StaffEntryApprovalRequest:staff_entry_approval_request,
            show_print: false,
            AddFileDialogVisible: false,
            //补充附件
            file_arr: [],
            AllDepartment: [],
            AllPosition: []
        }
    },
    computed: {
        dialogFormTitle() {
            if(this.show_type === 0) {
                return this.StaffEntry.name ? '员工入职登记表：' + this.StaffEntry.name : '员工入职登记表详情'
            } else if (this.show_type === 1) {
                return '添加员工入职登记表'
            } else {
                return '修改员工入职登记表'
            }
        },
    },
    methods: {
        closeDialog() {
            this.StaffEntry = {
                entry_enterprise: '',
                entry_department: '',
                entry_position: '',
                rank: '',
                entry_date: '',
                trial_time: '',
                trial_money: '',
                formal_money: '',
                other_benefits: '',
                staff_name: '',
                sex: '',
                icard: '',
                education: '',
                marriage: '',
                phone: '',
                address: '',
                family_name: '',
                family_relation: '',
                family_phone: '',
                other: [],
                print: []
            }
            this.$emit('exitDialog')
        },
        submitInsert(formName) {
            this.$refs[formName].validate((valid) => {
                if (!valid) {
                    return
                }
                
                this.StaffEntryInsert(formName)
            })
        },
        StaffEntryInsert(formName) {
            this.StaffEntry.other = this.$refs.upload.fileList

            if(this.StaffEntry.other.length === 0) {
                this.$message.error('请上传简历及相关附件')
                return
            }

            this.login_loading = true
            staff_entry_add_request(this.StaffEntry)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.$message.success('添加成功!')
                        this.$emit('exitDialog')
                        this.$emit('search')
                        this.$emit('details_row',s.result.id*1)
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        resetForm(formName) {
            this.$refs[formName].resetFields()
        },
        edit_clk() {
            //获取部门
            department_list_request({enterprise_id: this.StaffEntry.entry_enterprise})
                .then((s) => {
                    if (s.status === 0) {
                        this.AllDepartment = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
            //获取职位
            position_list_request({department_id :this.StaffEntry.entry_department})
            .then((s) => {
                    if (s.status === 0) {
                        this.AllPosition = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })

            this.$emit('show_edit')
        },
        submitEdit(formName) {
            this.$refs[formName].validate((valid) => {
                if (!valid) {
                    return
                }
                
                this.StaffEntryEdit(formName)
            })
        },
        StaffEntryEdit(formName) {
            this.StaffEntry.id = this.id
            this.StaffEntry.other = this.$refs.upload.fileList

            if(this.StaffEntry.other.length === 0) {
                this.$message.error('请上传简历及相关附件')
                return
            }

            this.login_loading = true
            staff_entry_edit_request(this.StaffEntry)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.$message.success('修改成功!')
                        this.$emit('exitDialog')
                        this.$emit('search')
                        this.$emit('details_row',this.StaffEntry.id)
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        StaffEntryDel(formName) {
            this.$confirm(
                '是否确定删除？（一经删除，将无法再进行恢复，请谨慎操作！）',
                '温馨提示',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            )
                .then(() => {
                    staff_entry_del_request(this.id)
                        .then((s) => {
                            if (s.status === 0) {
                                this.$message.success('删除成功!')
                                this.$emit('search')
                            } else {
                                this.$message.error(s.msg)
                            }
                        })
                        .catch((err) => {
                            this.$message.error(err)
                        })
                        .finally(() => this.closeDialog())
                })
                .catch((err) => {})
        },
        to_approval() {
            this.$prompt('请填写申请原因', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                }).then(({ value }) => {
                    this.login_loading = true
                    staff_entry_to_approval_request({id:this.id,note:value})
                        .then((s) => {
                            this.login_loading = false
                            if (s.status === 0) {
                                    this.$message.success('提交成功!')
                                    this.$emit('search')
                            } else {
                                this.$message.error(s.msg)
                            }
                        })
                        .catch((err) => {
                            this.login_loading = false
                            this.$message.error(err)
                        })
                        .finally(() => this.closeDialog())
                }).catch(()=>{});
        },
        exitApprovalDialog() {
            this.ApprovalDialogVisible = false
        },
        sel_approval() {
            this.login_loading = true
            staff_entry_sel_approval_request(this.id)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.ApprovalData = s.result
                        this.ApprovalDialogVisible = true
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        StaffEntryWithdraw() {
            this.$confirm(
                '是否确定撤回？（请谨慎操作）',
                '温馨提示',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            )
                .then(() => {
                    this.login_loading = true
                    staff_entry_withdraw_request({id:this.id})
                        .then((s) => {
                            this.login_loading = false
                            if (s.status === 0) {
                                this.$message.success('已撤回!')
                                this.$emit('frefresh')
                                this.$emit('search')
                            } else {
                                this.$message.error(s.msg)
                            }
                        })
                        .catch((err) => {
                            this.login_loading = false
                            this.$message.error(err)
                        })
                })
                .catch((err) => {})
        },
        clk_print() {
            this.login_loading = true
            staff_entry_sel_approval_request(this.id)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.ApprovalData = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
            this.show_print = true
        },
        add_file() {
            this.AddFileDialogVisible = true
        },
        exit_add_file() {
            this.AddFileDialogVisible = false
            this.file_arr = []
        },
        submit_add_file() {
            let data = {}
            this.file_arr = this.$refs.file_upload.fileList
            data['id'] = this.id
            data['file'] = this.file_arr
            staff_entry_add_file_request(data)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.$message.success('补充附件成功!')
                        this.$emit('frefresh')
                        this.AddFileDialogVisible = false
                        this.file_arr = []
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        enterprise_change(e) {
            this.AllDepartment = []
            this.AllPosition = []
            this.StaffEntry.entry_department = ''
            this.StaffEntry.entry_position = ''

            if(e==='') {
                return
            }

            department_list_request({enterprise_id:e})
                .then((s) => {
                    if (s.status === 0) {
                        this.AllDepartment = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        },
        department_change(e) {
            this.AllPosition = []
            this.StaffEntry.entry_position = ''

            if(e==='') {
                return
            }

            position_list_request({department_id:e})
                .then((s) => {
                    if (s.status === 0) {
                        this.AllPosition = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        }
    },
    filters: {
        fomatFloatMoney(data) {
            return data ? thousandBitSeparator(data) + '元' : ''
        }
    },
    props: {
        dialogFormVisible: Boolean,
        id: {
            type: Number,
            default() {
                return 0
            },
        },
        staff_entry_details_data: {
            type: Object,
            default() {
                return {}
            },
        },
        show_type: {
            type:Number,
            default() {
                return 0
            },
        },
        edit_auth: {
            type:Boolean,
            default() {
                return false
            }
        },
        del_auth: {
            type:Boolean,
            default() {
                return false
            }
        },
        add_file_auth: {
            type:Boolean,
            default() {
                return false
            }
        },
        to_approval_auth: {
            type:Boolean,
            default() {
                return false
            }
        },
        sel_approval_auth: {
            type:Boolean,
            default() {
                return false
            }
        },
        ApprovalAuth: {
            type:Boolean,
            default() {
                return false
            }
        },
        withdraw_auth: {
            type:Boolean,
            default() {
                return false
            }
        },
        print_auth: String,
        AllEnterprise: {
            type: Array,
            default() {
                return []
            }
        },
        AllRank: {
            type: Array,
            default() {
                return []
            }
        }
    },
    created() {},
    destoryed() {},
    mounted() {},
    updated() {},
    components: {
        CommonUploadComponent,
        CommonApprovalDialog,
        StaffEntryPrintComponent
    },
    watch: {
            staff_entry_details_data(newVal) {
            this.StaffEntry = newVal
            this.StaffEntry.id = this.id
        },
    },
}
</script>

<style> 
.el-date-editor.el-input {
    width: 100%;
}
.entry_enterprisec,.entry_departmentc,.entry_positionc,.rankc,.trial_timec,.staff_namec,.icardc,.educationc,.phonec,.family_namec,.family_relationc,.family_phone {
    width: 100%;
}
.input_number {
    width: 100%;
}
</style>