<template>
    <el-dialog
        title="费用结算单"
        :visible.sync="fjdDialogVisible"
        width="85%"
        :center="true"
        top="2vh"
        :append-to-body="true"
        :close-on-click-modal="false"
        :before-close="() => closeDialog()"
    >
        <common-upload-component
            v-show="fjd_details.fileData.length"
            :ffileList="fjd_details.fileData"
            :disabled="true"
        ></common-upload-component>
        <fieldset class="fieldsetc bottomc">
            <legend class="legendc">业务信息</legend>
            <el-descriptions
                class="margin-top"
                :column="4"
                :labelStyle="{'width':'120px','text-align':'center'}"
                :contentStyle="{'text-align': 'center'}"
                border
                size='small'
            >
                <el-descriptions-item>
                    <template slot="label">
                        <span>业务时间</span>
                    </template>
                    <span>{{fjd_details.b_time | diy_date}}</span>
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <span>业务类型</span>
                    </template>
                    <span>{{fjd_details.b_project}}</span>
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <span>委托单位</span>
                    </template>
                    <span>{{fjd_details.b_com}}</span>
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <span>货物名称</span>
                    </template>
                    <span>{{fjd_details.hw_name}}</span>
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <span>公司编号</span>
                    </template>
                    <span>{{fjd_details.b_job}}</span>
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <span>业务编号</span>
                    </template>
                    <span>{{fjd_details.b_number}}</span>
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <span>主提单号</span>
                    </template>
                    <span>{{fjd_details.b_bill}}</span>
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <span>航次</span>
                    </template>
                    <span>{{fjd_details.b_fre}}</span>
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <span>订舱代理</span>
                    </template>
                    <span>{{fjd_details.b_dccd}}</span>
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <span>船公司</span>
                    </template>
                    <span>{{fjd_details.b_cgs}}</span>
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <span>起运地</span>
                    </template>
                    <span>{{fjd_details.b_depport}}</span>
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <span>目的地</span>
                    </template>
                    <span>{{fjd_details.b_arrport}}</span>
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <span>实际毛利</span>
                    </template>
                    <span>{{fjd_details.tax_totalrmb | diy_money}}</span>
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <span>实际毛利率</span>
                    </template>
                    <span>{{fjd_details.tax_rate | diy_rate}}</span>
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <span>运输条款</span>
                    </template>
                    <span>{{fjd_details.b_ystk}}</span>
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <span>贸易条款</span>
                    </template>
                    <span>{{fjd_details.b_mytk}}</span>
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <span>服务环节</span>
                    </template>
                    <span>{{fjd_details.b_servicelinksname}}</span>
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <span>申请人</span>
                    </template>
                    <span>{{fjd_details.b_oper}}</span>
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <span>督办人</span>
                    </template>
                    <span>{{fjd_details.b_sale}}</span>
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <span>记账汇率</span>
                    </template>
                    <span>{{fjd_details.b_exchangerate}}</span>
                </el-descriptions-item>
                <el-descriptions-item :contentStyle="{'text-align': 'center','color': '#2828FF'}">
                    <template slot="label">
                        <span>应收人民币</span>
                    </template>
                    <span>{{fjd_details.c_rmbtotal | diy_money2}}</span>
                </el-descriptions-item>
                <el-descriptions-item :contentStyle="{'text-align': 'center','color': '#2828FF'}">
                    <template slot="label">
                        <span>应收外币</span>
                    </template>
                    <span>{{fjd_details.c_wbtotal | diy_money2}}</span>
                </el-descriptions-item>
                <el-descriptions-item :contentStyle="{'text-align': 'center','color': '#2828FF'}">
                    <template slot="label">
                        <span>应付人民币</span>
                    </template>
                    <span>{{fjd_details.p_rmbtotal | diy_money2}}</span>
                </el-descriptions-item>
                <el-descriptions-item :contentStyle="{'text-align': 'center','color': '#2828FF'}">
                    <template slot="label">
                        <span>应付外币</span>
                    </template>
                    <span>{{fjd_details.p_wbtotal | diy_money2}}</span>
                </el-descriptions-item>
                <el-descriptions-item :contentStyle="{'text-align': 'center','color': '#00BB00'}">
                    <template slot="label">
                        <span>已收人民币</span>
                    </template>
                    <span>{{fjd_details.collect_RMB_Received | diy_money2}}</span>
                </el-descriptions-item>
                <el-descriptions-item :contentStyle="{'text-align': 'center','color': '#00BB00'}">
                    <template slot="label">
                        <span>已收外币</span>
                    </template>
                    <span>{{fjd_details.collect_USD_Received | diy_money2}}</span>
                </el-descriptions-item>
                <el-descriptions-item :contentStyle="{'text-align': 'center','color': '#00BB00'}">
                    <template slot="label">
                        <span>已付人民币</span>
                    </template>
                    <span>{{fjd_details.pay_RMB_paid | diy_money2}}</span>
                </el-descriptions-item>
                <el-descriptions-item :contentStyle="{'text-align': 'center','color': '#00BB00'}">
                    <template slot="label">
                        <span>已付外币</span>
                    </template>
                    <span>{{fjd_details.pay_USD_paid | diy_money2}}</span>
                </el-descriptions-item>
                <el-descriptions-item :contentStyle="{'text-align': 'center','color': '#FF0000'}">
                    <template slot="label">
                        <span>未收人民币</span>
                    </template>
                    <span>{{fjd_details.collect_RMB_UnReceived | diy_money2}}</span>
                </el-descriptions-item>
                <el-descriptions-item :contentStyle="{'text-align': 'center','color': '#FF0000'}">
                    <template slot="label">
                        <span>未收外币</span>
                    </template>
                    <span>{{fjd_details.collect_USD_UnReceived | diy_money2}}</span>
                </el-descriptions-item>
                <el-descriptions-item :contentStyle="{'text-align': 'center','color': '#FF0000'}">
                    <template slot="label">
                        <span>未付人民币</span>
                    </template>
                    <span>{{fjd_details.pay_RMB_Unpaid | diy_money2}}</span>
                </el-descriptions-item>
                <el-descriptions-item :contentStyle="{'text-align': 'center','color': '#FF0000'}">
                    <template slot="label">
                        <span>未付外币</span>
                    </template>
                    <span>{{fjd_details.pay_USD_Unpaid | diy_money2}}</span>
                </el-descriptions-item>
                <el-descriptions-item :span="4" :contentStyle="{'text-align':'left','white-space':'pre-line','color':'#921AFF'}">
                    <template slot="label">
                        <span>亏损说明</span>
                    </template>
                    <span>{{fjd_details.b_remark}}</span>
                </el-descriptions-item>
            </el-descriptions>
        </fieldset>
        <fieldset class="fieldsetc bottomc">
            <legend class="legendc">应收信息</legend>
            <common-table-component
                table_size="mini"
                table_height="250px"
                :col_arr="ys_col_arr"
                :table_data="fjd_details.collect"
            ></common-table-component>
        </fieldset>
        <fieldset class="fieldsetc bottomc">
            <legend class="legendc">应付信息</legend>
            <common-table-component
                table_size="mini"
                table_height="250px"
                :col_arr="yf_col_arr"
                :table_data="fjd_details.pay"
            ></common-table-component>
        </fieldset>
    </el-dialog>
</template>

<script>
import { fomatFloat,thousandBitSeparator } from '@/assets/js/common.js'

import CommonTableComponent from '@/components/common/CommonTableComponent'
import CommonUploadComponent from '@/components/common/CommonUploadComponent'

export default {
    name: 'BusinessPaymentFjdComponent',
    data() {
        return {
            ys_col_arr: [
                {
                    prop: 'c_examine_color',
                    label: '状态',
                    minWidth: '80px',
                    tag: (d) => {
                        if(d===0) {
                            return {
                                type: 'info',
                                filter_data: '待审批'
                            }
                        } else if(d===1) {
                            return {
                                type: 'success',
                                filter_data: '已审批'
                            }
                        }
                    }
                },
                {
                    prop: 'c_com',
                    label: '单位名称',
                    minWidth: '150px',
                    show_overflow_tooltip: true
                },
                {
                    prop: 'c_contract',
                    label: '合同',
                    minWidth: '80px',
                    tag: (d) => {
                        if(d==='已签') {
                            return {
                                type: 'success',
                                filter_data: '已签'
                            }
                        } else if(d==='过期') {
                            return {
                                type: 'warning',
                                filter_data: '过期'
                            }
                        } else if(d==='不签') {
                            return {
                                type: 'danger',
                                filter_data: '不签'
                            }
                        } else if(d==='待签') {
                            return {
                                type: 'info',
                                filter_data: '待签'
                            }
                        }
                    }
                },
                {
                    prop: 'c_costname',
                    label: '费用类型',
                    minWidth: '100px',
                    show_overflow_tooltip: true
                },
                {
                    prop: 'c_b_bz',
                    label: '币种',
                    minWidth: '70px',
                    show_overflow_tooltip: true
                },
                {
                    prop: 'c_price',
                    label: '单价',
                    minWidth: '100px',
                    align: 'right',
                    show_overflow_tooltip: true,
                    editdata: (row) => {
                        return thousandBitSeparator(fomatFloat(row['c_price']))
                    }
                },
                {
                    prop: 'c_number',
                    label: '数量',
                    minWidth: '70px',
                    show_overflow_tooltip: true
                },
                {
                    prop: 'c_jfdw',
                    label: '计费单位',
                    minWidth: '80px',
                    show_overflow_tooltip: true
                },
                {
                    prop: 'c_hl',
                    label: '汇率',
                    minWidth: '80px',
                    editdata: (row) => {
                        return thousandBitSeparator(fomatFloat(row['c_hl'],4))
                    }
                },
                {
                    prop: 'c_money',
                    label: '应收金额',
                    minWidth: '100px',
                    align: 'right',
                    show_overflow_tooltip: true,
                    editdata: (row) => {
                        return thousandBitSeparator(fomatFloat(row['c_money']))
                    }
                },
                {
                    prop: 'c_collected_money',
                    label: '已收金额',
                    minWidth: '100px',
                    align: 'right',
                    show_overflow_tooltip: true,
                    editdata: (row) => {
                        return thousandBitSeparator(fomatFloat(row['c_collected_money']))
                    }
                },
                {
                    prop: 'c_invoicetype',
                    label: '发票类型',
                    minWidth: '80px',
                    show_overflow_tooltip: true
                },
                {
                    prop: 'c_invoice',
                    label: '发票号',
                    minWidth: '100px',
                    show_overflow_tooltip: true
                },
                {
                    prop: 'c_remarks',
                    label: '备注',
                    minWidth: '100px',
                    show_overflow_tooltip: true
                }
            ],
            yf_col_arr: [
                {
                    prop: 'p_examine_color',
                    label: '状态',
                    minWidth: '80px',
                    tag: (d) => {
                        if(d===0) {
                            return {
                                type: 'info',
                                filter_data: '待审批'
                            }
                        } else if(d===1) {
                            return {
                                type: 'success',
                                filter_data: '已审批'
                            }
                        }
                    }
                },
                {
                    prop: 'p_com',
                    label: '单位名称',
                    minWidth: '150px',
                    show_overflow_tooltip: true
                },
                {
                    prop: 'p_contract',
                    label: '合同',
                    minWidth: '80px',
                    tag: (d) => {
                        if(d==='已签') {
                            return {
                                type: 'success',
                                filter_data: '已签'
                            }
                        } else if(d==='过期') {
                            return {
                                type: 'warning',
                                filter_data: '过期'
                            }
                        } else if(d==='不签') {
                            return {
                                type: 'danger',
                                filter_data: '不签'
                            }
                        } else if(d==='待签') {
                            return {
                                type: 'info',
                                filter_data: '待签'
                            }
                        }
                    }
                },
                {
                    prop: 'p_costname',
                    label: '费用类型',
                    minWidth: '100px',
                    show_overflow_tooltip: true
                },
                {
                    prop: 'p_b_bz',
                    label: '币种',
                    minWidth: '70px',
                    show_overflow_tooltip: true
                },
                {
                    prop: 'p_price',
                    label: '单价',
                    minWidth: '100px',
                    align: 'right',
                    show_overflow_tooltip: true,
                    editdata: (row) => {
                        return thousandBitSeparator(fomatFloat(row['p_price']))
                    }
                },
                {
                    prop: 'p_number',
                    label: '数量',
                    minWidth: '70px',
                    show_overflow_tooltip: true
                },
                {
                    prop: 'p_jfdw',
                    label: '计费单位',
                    minWidth: '80px',
                    show_overflow_tooltip: true
                },
                {
                    prop: 'p_hl',
                    label: '汇率',
                    minWidth: '80px',
                    editdata: (row) => {
                        return thousandBitSeparator(fomatFloat(row['p_hl'],4))
                    }
                },
                {
                    prop: 'p_money',
                    label: '应付金额',
                    minWidth: '100px',
                    align: 'right',
                    show_overflow_tooltip: true,
                    editdata: (row) => {
                        return thousandBitSeparator(fomatFloat(row['p_money']))
                    }
                },
                {
                    prop: 'p_payment_money',
                    label: '已付金额',
                    minWidth: '100px',
                    align: 'right',
                    show_overflow_tooltip: true,
                    editdata: (row) => {
                        return thousandBitSeparator(fomatFloat(row['p_payment_money']))
                    }
                },
                {
                    prop: 'p_invoicetype',
                    label: '发票类型',
                    minWidth: '80px',
                    show_overflow_tooltip: true
                },
                {
                    prop: 'p_invoice',
                    label: '发票号',
                    minWidth: '100px',
                    show_overflow_tooltip: true
                },
                {
                    prop: 'p_remarks',
                    label: '备注',
                    minWidth: '100px',
                    show_overflow_tooltip: true
                }
            ]
        }
    },
    computed: {},
    methods: {
        closeDialog() {
            this.$emit('exitDialog')
        }
    },
    filters: {
        diy_money(v) {
            if(v) {
                return '￥ ' + thousandBitSeparator(fomatFloat(v))
            }

            return '￥ 0.00'
        },
        diy_rate(v) {
            if(v) {
                return fomatFloat(v*100) + '%'
            }

            return '0.00%'
        },
        diy_date(v) {
            if(v) {
                return v.substr(0,10)
            }
            
            return ''
        },
        diy_money2(v) {
            if(v) {
                return thousandBitSeparator(fomatFloat(v))
            }
            
            return '0.00'
        },
    },
    props: {
        fjdDialogVisible: {
            type: Boolean,
            default() {
                return false
            }
        },
        fjd_details: {
            type: Object,
            default() {
                return {}
            }
        },
    },
    created() {},
    destoryed() {},
    mounted() {},
    updated() {},
    components: {
        CommonTableComponent,
        CommonUploadComponent
    }
}
</script>

<style lang='less'></style>