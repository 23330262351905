<template>
    <div>
        <el-dialog
            :visible.sync="dialogFormVisible"
            :append-to-body="true"
            width="95%"
            :center="true"
            top="2vh"
            :close-on-click-modal="false"
            :before-close="() => closeDialog('BusinessPaymentEditForm')"
            v-loading.fullscreen.lock="login_loading"
        >
            <template #title>
                <span style="font-weight: bold;">{{dialogFormTitle}}</span>
                <el-tag v-if="BusinessPayment.state===0" type="info" size="mini">待提交</el-tag>
                <el-tag v-if="BusinessPayment.state===1" size="mini">审批中</el-tag>
                <el-tag v-if="BusinessPayment.state===2" type="success" size="mini">已通过</el-tag>
                <el-tag v-if="BusinessPayment.state===3" type="danger" size="mini">已驳回</el-tag>
            </template>
            <div>
                <el-form
                    :model="BusinessPayment"
                    :rules="rules"
                    ref="BusinessPaymentEditForm"
                    label-width="100px"
                    size="small"
                >
                    <div v-if="show_type">
                        <fieldset  class="fieldsetc">
                            <legend class="legendc">基础信息</legend>
                            <el-row :gutter="20">
                                <el-col :span="12">
                                    <el-form-item label="申请部门" prop="department_id">
                                        <el-cascader
                                            v-model="BusinessPayment.department_id"
                                            :options="enterprise_department"
                                            :props="{ expandTrigger: 'hover' }"
                                            clearable
                                            style="width: 100%"
                                        >
                                        </el-cascader>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="紧急程度" prop="urgent_state">
                                        <el-select
                                            v-model="BusinessPayment.urgent_state"
                                            placeholder="选择紧急程度"
                                            class="urgent_statec"
                                            clearable
                                        >
                                            <el-option
                                                v-for="(v,i) in ['一般','较急','特急']"
                                                :key="i"
                                                :label="v"
                                                :value="i"
                                            ></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row :gutter="20">
                                <el-col :span="24">
                                    <el-form-item label="备注" prop="content">
                                        <el-input type="textarea" :rows="3" v-model="BusinessPayment.note"></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </fieldset>
                        <fieldset class="fieldsetc bottomc" v-if="BusinessPayment.with_zhwl_id">
                            <legend class="legendc">付款单信息</legend>
                            <el-descriptions
                                class="margin-top"
                                :column="4"
                                :labelStyle="{'width':'120px','text-align':'center'}"
                                :contentStyle="{'text-align': 'center'}"
                                border
                                size='small'
                            >
                                <el-descriptions-item :span="2">
                                    <template slot="label">
                                        <span>收款单位</span>
                                    </template>
                                    <span>{{BusinessPayment.zhwl_arr.zhwl_pay_entertain_name}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>付款币种</span>
                                    </template>
                                    <span>{{BusinessPayment.zhwl_arr.zhwl_currency}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>付款金额</span>
                                    </template>
                                    <span>{{BusinessPayment.zhwl_arr.zhwl_money | diy_money}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>付款编号</span>
                                    </template>
                                    <span>{{BusinessPayment.zhwl_arr.zhwl_yw_id}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>内外贸</span>
                                    </template>
                                    <span>{{BusinessPayment.zhwl_arr.zhwl_domestic_foreign}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>申请人</span>
                                    </template>
                                    <span>{{BusinessPayment.zhwl_arr.zhwl_operator_name}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>发起人</span>
                                    </template>
                                    <span>{{BusinessPayment.zhwl_arr.zhwl_apply_staff_name}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>付款方式</span>
                                    </template>
                                    <span>{{BusinessPayment.zhwl_arr.zhwl_pay_type}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>最晚付款日期</span>
                                    </template>
                                    <span>{{BusinessPayment.zhwl_arr.zhwl_pay_time.slice(0,10)}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item :span="2">
                                    <template slot="label">
                                        <span>开户行</span>
                                    </template>
                                    <span>{{BusinessPayment.zhwl_arr.zhwl_bank}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item :span="2">
                                    <template slot="label">
                                        <span>付款单位</span>
                                    </template>
                                    <span>{{BusinessPayment.zhwl_arr.zhwl_collection_entertain_name}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item :span="2">
                                    <template slot="label">
                                        <span>银行卡号</span>
                                    </template>
                                    <span>{{BusinessPayment.zhwl_arr.zhwl_card}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item :span="2" :contentStyle="{'text-align':'left','white-space':'pre-line'}">
                                    <template slot="label">
                                        <span>付款用途</span>
                                    </template>
                                    <span>{{BusinessPayment.zhwl_arr.zhwl_note}}</span>
                                </el-descriptions-item>
                            </el-descriptions>
                        </fieldset>
                        <fieldset class="fieldsetc bottomc" v-else>
                            <legend class="legendc">选择付款单</legend>
                            <common-table-component
                                table_size="mini"
                                table_height="300px"
                                :col_arr="fcol_arr"
                                :table_data="all_payment_zhwl"
                                button_name="选择"
                                button_namedel="删除"
                                @sel_row="sel_row"
                                @del_row="del_row"
                            ></common-table-component>
                        </fieldset>
                        <fieldset class="fieldsetc bottomc" v-if="BusinessPayment.with_zhwl_id">
                            <legend class="legendc">费用结算单</legend>
                            <common-table-component
                                button_name="查看"
                                table_size="mini"
                                table_height="200px"
                                :col_arr="fjd_col_arr"
                                :table_data="all_fjd_zhwl"
                                @watch_row="details_fjd"
                            ></common-table-component>
                        </fieldset>
                        <common-upload-component
                            ref="upload"
                            :ffileList="BusinessPayment.other"
                            upload_url="business_payment/upload"
                        ></common-upload-component>
                    </div>
                    <div v-else>
                        <fieldset  class="fieldsetc">
                            <legend class="legendc">基础信息</legend>
                            <el-descriptions
                                class="margin-top"
                                :column="2"
                                :labelStyle="{'width':'120px','text-align':'center'}"
                                :contentStyle="{'text-align': 'center'}"
                                border
                                size='small'
                            >
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>申请部门</span>
                                    </template>
                                    <span>{{BusinessPayment.enterprise_name ? BusinessPayment.enterprise_name + '/' + BusinessPayment.department_name : ''}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>紧急程度</span>
                                    </template>
                                    <el-tag v-if="BusinessPayment.urgent_state===0" type="info" size="small">一般</el-tag>
                                    <el-tag v-if="BusinessPayment.urgent_state===1" type="warning" size="small">较急</el-tag>
                                    <el-tag v-if="BusinessPayment.urgent_state===2" type="danger" size="small">特急</el-tag>
                                </el-descriptions-item>
                                <el-descriptions-item :span="2" :contentStyle="{'text-align':'left','white-space':'pre-line'}">
                                    <template slot="label">
                                        <span>备注</span>
                                    </template>
                                    <span>{{BusinessPayment.note}}</span>
                                </el-descriptions-item>
                            </el-descriptions>
                        </fieldset>
                        <fieldset class="fieldsetc bottomc" v-if="BusinessPayment.with_zhwl_id">
                            <legend class="legendc">付款单信息</legend>
                            <el-descriptions
                                class="margin-top"
                                :column="4"
                                :labelStyle="{'width':'120px','text-align':'center'}"
                                :contentStyle="{'text-align': 'center'}"
                                border
                                size='small'
                            >
                                <el-descriptions-item :span="2">
                                    <template slot="label">
                                        <span>收款单位</span>
                                    </template>
                                    <span>{{BusinessPayment.zhwl_arr.zhwl_pay_entertain_name}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>付款币种</span>
                                    </template>
                                    <span>{{BusinessPayment.zhwl_arr.zhwl_currency}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>付款金额</span>
                                    </template>
                                    <span>{{BusinessPayment.zhwl_arr.zhwl_money | diy_money}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>付款编号</span>
                                    </template>
                                    <span>{{BusinessPayment.zhwl_arr.zhwl_yw_id}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>内外贸</span>
                                    </template>
                                    <span>{{BusinessPayment.zhwl_arr.zhwl_domestic_foreign}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>申请人</span>
                                    </template>
                                    <span>{{BusinessPayment.zhwl_arr.zhwl_operator_name}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>发起人</span>
                                    </template>
                                    <span>{{BusinessPayment.zhwl_arr.zhwl_apply_staff_name}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>付款方式</span>
                                    </template>
                                    <span>{{BusinessPayment.zhwl_arr.zhwl_pay_type}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>最晚付款日期</span>
                                    </template>
                                    <span>{{BusinessPayment.zhwl_arr.zhwl_pay_time.slice(0,10)}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item :span="2">
                                    <template slot="label">
                                        <span>开户行</span>
                                    </template>
                                    <span>{{BusinessPayment.zhwl_arr.zhwl_bank}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item :span="2">
                                    <template slot="label">
                                        <span>付款单位</span>
                                    </template>
                                    <span>{{BusinessPayment.zhwl_arr.zhwl_collection_entertain_name}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item :span="2">
                                    <template slot="label">
                                        <span>银行卡号</span>
                                    </template>
                                    <span>{{BusinessPayment.zhwl_arr.zhwl_card}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item :span="2" :contentStyle="{'text-align':'left','white-space':'pre-line'}">
                                    <template slot="label">
                                        <span>付款用途</span>
                                    </template>
                                    <span>{{BusinessPayment.zhwl_arr.zhwl_note}}</span>
                                </el-descriptions-item>
                            </el-descriptions>
                        </fieldset>
                        <fieldset class="fieldsetc bottomc" v-if="BusinessPayment.with_zhwl_id">
                            <legend class="legendc">费用结算单</legend>
                            <common-table-component
                                button_name="查看"
                                table_size="mini"
                                table_height="200px"
                                :col_arr="fjd_col_arr"
                                :table_data="all_fjd_zhwl"
                                @watch_row="details_fjd"
                            ></common-table-component>
                        </fieldset>
                        <common-upload-component
                            v-show="BusinessPayment.other.length"
                            ref="upload"
                            :ffileList="BusinessPayment.other"
                            upload_url="business_payment/upload"
                            :disabled="true"
                        ></common-upload-component>
                        <common-upload-component
                            v-show="AddFileDialogVisible"
                            ref="file_upload"
                            :ffileList="file_arr"
                            upload_url="business_payment/upload"
                            title="补充附件"
                        ></common-upload-component>
                    </div>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer">
                <div v-if="show_type===0">
                    <div v-if="AddFileDialogVisible === false">
                        <el-button
                            v-if="add_file_auth"
                            type="success"
                            plain
                            @click="add_file()"
                            >补充附件</el-button
                        >
                        <el-button
                            v-if="pay_auth"
                            type="primary"
                            round
                            @click="BusinessPaymentPay('BusinessPaymentEditForm')"
                            >汇款</el-button
                        >
                        <el-button
                            v-if="to_approval_auth"
                            type="success"
                            plain
                            @click="to_approval('BusinessPaymentEditForm')"
                            >提交</el-button
                        >
                        <el-button
                            v-if="sel_approval_auth"
                            type="warning"
                            plain
                            @click="sel_approval()"
                            >查看审批</el-button
                        >
                        <el-button
                            v-if="withdraw_auth"
                            type="danger"
                            plain
                            @click="BusinessPaymentWithdraw('BusinessPaymentEditForm')"
                            >审批撤回</el-button
                        >
                        <el-button
                            v-if="edit_auth"
                            type="primary"
                            plain
                            @click="$emit('show_edit')"
                            >修改</el-button
                        >
                        <el-button
                            v-if="del_auth"
                            type="danger"
                            plain
                            @click="BusinessPaymentDel('BusinessPaymentEditForm')"
                            >删除</el-button
                        >
                        <el-button
                            plain
                            @click="closeDialog('BusinessPaymentEditForm')"
                            >关闭</el-button
                        >
                    </div>
                    <div v-else>
                        <el-button
                            type="success"
                            plain
                            @click="submit_add_file()"
                            >确定</el-button
                        >
                        <el-button
                            plain
                            @click="exit_add_file()"
                            >取消</el-button
                        >
                    </div>
                </div>
                <div v-else-if="show_type===1">
                    <el-button
                        type="success"
                        plain
                        @click="submitInsert('BusinessPaymentEditForm')"
                        >确定</el-button
                    >
                    <el-button
                        type="warning"
                        plain
                        @click="resetForm('BusinessPaymentEditForm')"
                        >重置</el-button
                    >
                    <el-button
                        plain
                        @click="closeDialog('BusinessPaymentEditForm')"
                        >关闭</el-button
                    >
                </div>
                <div v-else>
                    <el-button
                        type="success"
                        plain
                        @click="submitEdit('BusinessPaymentEditForm')"
                        >确定修改</el-button
                    >
                    <el-button
                        plain
                        @click="closeDialog('BusinessPaymentEditForm')"
                        >取消操作</el-button
                    >
                </div>
                <common-approval-dialog
                    :id="id"
                    :ApprovalDialogVisible="ApprovalDialogVisible"
                    :data="ApprovalData"
                    :current_examine_staff_id="BusinessPayment.current_examine_staff_id"
                    :ApprovalAuth="ApprovalAuth"
                    :ApprovalRequest="BusinessPaymentApprovalRequest"
                    @exitApprovalDialog="exitApprovalDialog"
                    @refresh="$emit('frefresh')"
                >
                </common-approval-dialog>
                <business-payment-fjd-component
                    :fjdDialogVisible="fjdDialogVisible"
                    :fjd_details="fjd_details"
                    @exitDialog="fjdDialogVisible = false"
                ></business-payment-fjd-component>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import CommonUploadComponent from '@/components/common/CommonUploadComponent'
import CommonApprovalDialog from '@/components/common/CommonApprovalDialog'
import CommonTableComponent from '@/components/common/CommonTableComponent'
import BusinessPaymentFjdComponent from './BusinessPaymentFjdComponent'

import {
    business_payment_add_request,
    business_payment_del_request,
    business_payment_edit_request,
    business_payment_to_approval_request,
    business_payment_sel_approval_request,
    business_payment_approval_request,
    business_payment_withdraw_request,
    business_payment_pay_request,
    business_payment_fjd_index_request,
    business_payment_fjd_details_request,
    business_payment_fjd_del_request,
    business_payment_add_file_request
} from '@/network/finance/BusinessPayment.js'
import { business_payment_list_request } from '@/network/list.js'
import { fomatFloat,thousandBitSeparator } from '@/assets/js/common.js'

export default {
    name: 'BusinessPaymentEditComponent',
    data() {
        return {
            login_loading: false,
            ApprovalDialogVisible: false,
            fjdDialogVisible: false,
            fjd_details: {
                fileData: []
            },
            BusinessPayment: {
                with_zhwl_id: '',
                department_id: '',
                urgent_state: 0,
                note: '',
                other: [],
                zhwl_arr: {}
            },
            rules: {
                department_id: [
                    {
                        required: true,
                        message: '请选择申请部门',
                        trigger: 'blur',
                    },
                ],
                urgent_state: [
                    {
                        required: true,
                        message: '请选择紧急状态',
                        trigger: 'blur',
                    },
                ]
            },
            ApprovalData: [],
            BusinessPaymentApprovalRequest:business_payment_approval_request,
            all_payment_zhwl: [],
            all_fjd_zhwl: [],
            fcol_arr: [
                {
                    prop: 'zhwl_pay_time',
                    label: '付款日期',
                    minWidth: '90px',
                    editdata(row) {
                        return row['zhwl_pay_time'].slice(0,10)
                    }
                },
                {
                    prop: 'zhwl_yw_id',
                    label: '付款编号',
                    minWidth: '150px',
                },
                {
                    prop: 'zhwl_pay_entertain_name',
                    label: '收款单位',
                    minWidth: '200px',
                    show_overflow_tooltip: true
                },
                {
                    prop: 'zhwl_currency',
                    label: '币种',
                    minWidth: '60px'
                },
                {
                    prop: 'zhwl_money',
                    label: '金额',
                    minWidth: '100px',
                    align: 'right',
                    editdata(row) {
                        return thousandBitSeparator(fomatFloat(row['zhwl_money']))
                    }
                },
                {
                    prop: 'zhwl_collection_entertain_name',
                    label: '付款单位',
                    minWidth: '200px',
                    show_overflow_tooltip: true
                },
                {
                    prop: 'zhwl_operator_name',
                    label: '申请人',
                    minWidth: '70px'
                },
                {
                    prop: 'zhwl_apply_staff_name',
                    label: '发起人',
                    minWidth: '70px'
                }

            ],
            fjd_col_arr: [
                {
                    prop: 'b_time',
                    label: '业务时间',
                    minWidth: '100px',
                    editdata: (row) => {
                        return row['b_time'].slice(0,10)
                    }
                },
                {
                    prop: 'b_job',
                    label: 'job号',
                    minWidth: '100px'
                },
                {
                    prop: 'b_cmp',
                    label: '客户',
                    minWidth: '200px',
                    show_overflow_tooltip: true
                },
                {
                    prop: 'u_relation_contract',
                    label: '合同',
                    minWidth: '80px',
                    tag: (d) => {
                        if(d==='已签') {
                            return {
                                type: 'success',
                                filter_data: '已签'
                            }
                        } else if(d==='过期') {
                            return {
                                type: 'warning',
                                filter_data: '过期'
                            }
                        } else if(d==='不签') {
                            return {
                                type: 'danger',
                                filter_data: '不签'
                            }
                        } else if(d==='待签') {
                            return {
                                type: 'info',
                                filter_data: '待签'
                            }
                        }
                    }
                },
                {
                    prop: 'is_reduce',
                    label: '盈亏',
                    minWidth: '150px',
                    html: (row) => {
                        let h = '';
                        if(row['is_reduce'] === -1) {
                            h += '<span class="el-tag el-tag--danger el-tag--small el-tag--light">亏损</span>&nbsp;'
                            h += '<span style="color: #FF0000;">￥'+thousandBitSeparator(fomatFloat(row['b_profit']))+'</span>'
                        } else if(row['is_reduce'] === 0) {
                            h += '<span class="el-tag el-tag--info el-tag--small el-tag--light">持平</span>&nbsp;'
                        }else if(row['is_reduce'] === 1) {
                            h += '<span class="el-tag el-tag--success el-tag--small el-tag--light">盈利</span>&nbsp;'
                            h += '<span style="color: #00BB00;">￥'+thousandBitSeparator(fomatFloat(row['b_profit']))+'</span>'
                        }

                        return h
                    }
                },
                {
                    prop: 'b_depport',
                    label: '起运港',
                    minWidth: '100px'
                },
                {
                    prop: 'b_arrport',
                    label: '目的港',
                    minWidth: '100px'
                },
                {
                    prop: 'b_boxs',
                    label: '箱型箱量',
                    minWidth: '100px'
                },
                {
                    prop: 'p_needpay',
                    label: '应付金额',
                    minWidth: '110px',
                    align: 'right',
                    html: (row) => {
                        const a = row['p_needpay'].slice(0,1)
                        const b = row['p_needpay'].slice(1)
                        return `<span style="color: #FF0000;">` + a + thousandBitSeparator(fomatFloat(b)) + `</span>`
                    }
                },
                {
                    prop: 'al_rec',
                    label: '已收金额',
                    minWidth: '110px',
                    align: 'right',
                    html: (row) => {
                        const arr = row['al_rec'].split(',')
                        let res = ''
                        let a = ''
                        let b = ''
                        arr.forEach(v => {
                            a = v.slice(0,1)
                            b = v.slice(1)
                            if(res) {
                                res += `<br/><span style="color: #00BB00;">` + a + thousandBitSeparator(fomatFloat(b)) + `</span>`
                            } else {
                                res = `<span style="color: #00BB00;">` + a + thousandBitSeparator(fomatFloat(b)) + `</span>`
                            }
                        })
                        return res
                    }
                },
                {
                    prop: 'un_rec',
                    label: '未收金额',
                    minWidth: '110px',
                    align: 'right',
                    html: (row) => {
                        const arr = row['un_rec'].split(',')
                        let res = ''
                        let a = ''
                        let b = ''
                        arr.forEach(v => {
                            a = v.slice(0,1)
                            b = v.slice(1)
                            if(res) {
                                res += `<br/><span style="color: #8600FF;">` + a + thousandBitSeparator(fomatFloat(b)) + `</span>`
                            } else {
                                res = `<span style="color: #8600FF;">` + a + thousandBitSeparator(fomatFloat(b)) + `</span>`
                            }
                        })
                        return res
                    }
                },
                {
                    prop: 'b_oper',
                    label: '操作员',
                    minWidth: '80px'
                },
                {
                    prop: 'b_number',
                    label: '业务编号',
                    minWidth: '100px'
                },
            ],
            AddFileDialogVisible: false,
            //补充附件
            file_arr: []
        }
    },
    computed: {
        dialogFormTitle() {
            if(this.show_type === 0) {
                return this.BusinessPayment.name ? '业务付款单：' + this.BusinessPayment.name : '业务付款单详情'
            } else if (this.show_type === 1) {
                return '添加业务付款单'
            } else {
                return '修改业务付款单'
            }
        },
    },
    methods: {
        closeDialog(formName) {
            this.BusinessPayment.with_zhwl_id = ''
            this.BusinessPayment.urgent_state = 0
            this.BusinessPayment.note = ''
            this.BusinessPayment.other = []
            this.BusinessPayment.zhwl_arr = {}
            this.get_all_payment_zhwl()
            this.$emit('exitDialog')
        },
        submitInsert(formName) {
            this.$refs[formName].validate((valid) => {
                if (!valid) {
                    return
                }
                this.BusinessPaymentInsert(formName)
            })
        },
        BusinessPaymentInsert(formName) {
            this.login_loading = true
            let d = this.BusinessPayment
            d.department_id = d.department_id[d.department_id.length - 1]
            d.other = this.$refs.upload.fileList
            business_payment_add_request(d)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.$message.success('添加成功!')
                        this.$emit('exitDialog')
                        this.$emit('search')
                        this.$emit('details_row',s.result.id*1)
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        resetForm(formName) {
            this.BusinessPayment = {
                with_zhwl_id: '',
                department_id: '',
                urgent_state: 0,
                note: '',
                other: [],
                zhwl_arr: {}
            }
        },
        submitEdit(formName) {
            this.$refs[formName].validate((valid) => {
                if (!valid) {
                    return
                }
                this.BusinessPaymentEdit(formName)
            })
        },
        BusinessPaymentEdit(formName) {
            this.login_loading = true
            let d = {}
            d.id = this.id
            d.with_zhwl_id = this.BusinessPayment.with_zhwl_id
            d.department_id = this.BusinessPayment.department_id[this.BusinessPayment.department_id.length - 1]
            d.urgent_state = this.BusinessPayment.urgent_state
            d.note = this.BusinessPayment.note
            d.other = this.$refs.upload.fileList
            business_payment_edit_request(d)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.$message.success('修改成功!')
                        this.$emit('exitDialog')
                        this.$emit('search')
                        this.$emit('details_row',d.id)
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        BusinessPaymentDel(formName) {
            this.$confirm(
                '是否确定删除？（一经删除，将无法再进行恢复，请谨慎操作！）',
                '温馨提示',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            )
                .then(() => {
                    business_payment_del_request(this.id)
                        .then((s) => {
                            if (s.status === 0) {
                                this.$message.success('删除成功!')
                                this.$emit('search')
                            } else {
                                this.$message.error(s.msg)
                            }
                        })
                        .catch((err) => {
                            this.$message.error(err)
                        })
                        .finally(() => this.closeDialog(formName))
                })
                .catch((err) => {})
        },
        to_approval(formName) {
            this.$prompt('请填写申请原因', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                }).then(({ value }) => {
                    this.login_loading = true
                    business_payment_to_approval_request({id:this.id,note:value})
                        .then((s) => {
                            this.login_loading = false
                            if (s.status === 0) {
                                    this.$message.success('提交成功!')
                                    this.$emit('exitDialog')
                                    this.$emit('search')
                            } else {
                                this.$message.error(s.msg)
                            }
                        })
                        .catch((err) => {
                            this.login_loading = false
                            this.$message.error(err)
                        })
                        .finally(() => this.closeDialog(formName))
                }).catch(()=>{});
        },
        exitApprovalDialog() {
            this.ApprovalDialogVisible = false
        },
        sel_approval() {
            this.login_loading = true
            business_payment_sel_approval_request(this.id)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.ApprovalData = s.result
                        this.ApprovalDialogVisible = true
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        BusinessPaymentWithdraw() {
            this.$confirm(
                '是否确定撤回？（请谨慎操作）',
                '温馨提示',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            )
                .then(() => {
                    this.login_loading = true
                    business_payment_withdraw_request({id:this.id})
                        .then((s) => {
                            this.login_loading = false
                            if (s.status === 0) {
                                this.$message.success('已撤回!')
                                this.$emit('frefresh')
                                this.$emit('search')
                            } else {
                                this.$message.error(s.msg)
                            }
                        })
                        .catch((err) => {
                            this.login_loading = false
                            this.$message.error(err)
                        })
                })
                .catch((err) => {})
        },
        BusinessPaymentPay(formName) {
            this.$confirm(
                '是否确定已经汇款？（一经确认，将无法进行撤销，请谨慎操作！）',
                '温馨提示',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            )
                .then(() => {
                    business_payment_pay_request(this.id)
                        .then((s) => {
                            if (s.status === 0) {
                                this.$message.success('已确认汇款!')
                                this.$emit('search')
                            } else {
                                this.$message.error(s.msg)
                            }
                        })
                        .catch((err) => {
                            this.$message.error(err)
                        })
                        .finally(() => this.closeDialog(formName))
                })
                .catch((err) => {})
        },
        get_all_payment_zhwl() {
            this.login_loading = true
            business_payment_list_request(this.id)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.all_payment_zhwl = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        sel_row(row) {
            this.BusinessPayment.with_zhwl_id = row.id
            this.BusinessPayment.zhwl_arr = row

            this.login_loading = true
            business_payment_fjd_index_request(row.id)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.all_fjd_zhwl = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        del_row(fjd_id) {
            this.$confirm(
                '是否确定删除？（请谨慎操作）',
                '温馨提示',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            )
                .then(() => {
                    this.login_loading = true
                    business_payment_fjd_del_request(fjd_id)
                        .then((s) => {
                            this.login_loading = false
                            if (s.status === 0) {
                                this.$message.success('已删除!')
                                this.get_all_payment_zhwl()
                            } else {
                                this.$message.error(s.msg)
                            }
                        })
                        .catch((err) => {
                            this.login_loading = false
                            this.$message.error(err)
                        })
                })
                .catch((err) => {})
        },
        details_fjd(row) {
            this.login_loading = true
            
            const params = {
                fjd_id: row.b_id,
                zhwl_id: this.BusinessPayment.zhwl_arr.id
            }
            business_payment_fjd_details_request(params)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.fjd_details = s.result
                        this.fjdDialogVisible = true
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        add_file() {
            this.AddFileDialogVisible = true
        },
        exit_add_file() {
            this.AddFileDialogVisible = false
            this.file_arr = []
        },
        submit_add_file() {
            let data = {}
            this.file_arr = this.$refs.file_upload.fileList
            data['id'] = this.id
            data['file'] = this.file_arr
            business_payment_add_file_request(data)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.$message.success('补充附件成功!')
                        this.$emit('frefresh')
                        this.AddFileDialogVisible = false
                        this.file_arr = []
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        }
    },
    filters: {
        diy_money(v) {
            return thousandBitSeparator(fomatFloat(v))
        }
    },
    props: {
        dialogFormVisible: Boolean,
        id: {
            type: Number,
            default() {
                return 0
            },
        },
        business_payment_details_data: {
            type: Object,
            default() {
                return {}
            },
        },
        enterprise_department: {
            type: Array,
            default() {
                return []
            },
        },
        show_type: {
            type:Number,
            default() {
                return 0
            },
        },
        edit_auth: {
            type:Boolean,
            default() {
                return false
            }
        },
        del_auth: {
            type:Boolean,
            default() {
                return false
            }
        },
        add_file_auth: {
            type:Boolean,
            default() {
                return false
            }
        },
        to_approval_auth: {
            type:Boolean,
            default() {
                return false
            }
        },
        sel_approval_auth: {
            type:Boolean,
            default() {
                return false
            }
        },
        ApprovalAuth: {
            type:Boolean,
            default() {
                return false
            }
        },
        withdraw_auth: {
            type:Boolean,
            default() {
                return false
            }
        },
        pay_auth: {
            type:Boolean,
            default() {
                return false
            },
        },
    },
    created() {
        this.get_all_payment_zhwl()
    },
    destoryed() {},
    mounted() {},
    updated() {},
    components: {
        CommonUploadComponent,
        CommonApprovalDialog,
        CommonTableComponent,
        BusinessPaymentFjdComponent
    },
    watch: {
        business_payment_details_data(newVal) {
            this.all_fjd_zhwl = newVal.fjd_arr
            delete newVal.fjd_arr
            this.BusinessPayment = newVal
        },
    },
}
</script>

<style lang='less'>
.bottomc {
    margin-bottom: 10px;
}
.urgent_statec {
    width: 100%;
}
</style>