import request from '../request'

//印章使用申请列表
export const currency_index_request = p => {
    return request({
        method:'GET',
        url:'currency/index',
        params: p
    })
}

//新增印章使用申请
export const currency_add_request = d => {
    return request({
        method:'POST',
        url:'currency/add',
        data: d
    })
}

//删除印章使用申请
export const currency_del_request = id => {
    return request({
        method:'DELETE',
        url:'currency/del',
        data: {
            id: id
        }
    })
}

//修改印章使用申请
export const currency_edit_request = data => {
    return request({
        method:'PUT',
        url:'currency/edit',
        data
    })
}

//印章使用申请详情
export const currency_details_request = id => {
    return request({
        method:'GET',
        url:'currency/details',
        params: {
            id
        }
    })
}

// 提交印章使用申请
export const currency_to_approval_request = d => {
    return request({
        method:'PUT',
        url:'currency/to_approval',
        data: {
            id:d.id,
            note:d.note
        }
    })
}

// 查看印章使用申请审批记录
export const currency_sel_approval_request = id => {
    return request({
        method:'GET',
        url:'currency/sel_approval',
        params: {
            id
        }
    })
}

// 审批印章使用申请
export const currency_approval_request = d => {
    return request({
        method:'PUT',
        url:'currency/approval',
        data: {
            id: d.id,
            opinion: d.opinion,
            note: d.note,
            other: d.other,
            go_staff_id: d.go_staff_id
        }
    })
}

// 撤回印章使用申请
export const currency_withdraw_request = d => {
    return request({
        method:'PUT',
        url:'currency/withdraw',
        data: {
            id: d.id
        }
    })
}

// 打印印章使用申请
export const currency_print_request = id => {
    return request({
        method:'PUT',
        url:'currency/print',
        data: {
            id
        }
    })
}

// 补充印章使用申请附件
export const currency_add_file_request = data => {
    return request({
        method:'PUT',
        url:'currency/add_file',
        data
    })
}