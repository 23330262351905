<template>
    <div>
        <el-dialog
            :visible.sync="show_print"
            :append-to-body="true"
            width="60%"
            :center="true"
            top="10vh"
            :close-on-click-modal="false"
            :before-close="() => $emit('exit_print')"
        >
            <div ref="PrintCooperativeEnterprise">
                <p style="text-align: center;">
                    <b>
                        <span style="font-family: Microsoft YaHei;font-size:18px;">{{data | title_name}}</span>
                    </b>
                </p>
                <table style="font-family: Microsoft YaHei;font-size:15px;width: 100%;border: 1px solid black;">
                    <tr style="border: 1px solid black;height: 30px;">
                        <td style="border: 1px solid black;text-align: center;font-weight:bold;">企业名称</td>
                        <td style="border: 1px solid black;text-align: center;" colspan=3>{{data.enterprise_name}}</td>
                    </tr>
                    <tr style="border: 1px solid black;height: 30px;">
                        <td style="border: 1px solid black;text-align: center;font-weight:bold;">税务登记号</td>
                        <td style="border: 1px solid black;text-align: center;" colspan=3>{{data.taxation}}</td>
                    </tr>
                    <tr style="border: 1px solid black;height: 30px;">
                        <td style="width: 15%;border: 1px solid black;text-align: center;font-weight:bold;">企业类型</td>
                        <td style="width: 35%;border: 1px solid black;text-align: center;">{{data.type_name}}</td>
                        <td style="width: 15%;border: 1px solid black;text-align: center;font-weight:bold;">合作状态</td>
                        <td style="width: 35%;border: 1px solid black;text-align: center;">{{['禁用','临时','长期'][data.status]}}</td>
                    </tr>
                    <tr style="border: 1px solid black;height: 30px;">
                        <td style="width: 15%;border: 1px solid black;text-align: center;font-weight:bold;">联系人</td>
                        <td style="width: 35%;border: 1px solid black;text-align: center;">{{data.contacts}}</td>
                        <td style="width: 15%;border: 1px solid black;text-align: center;font-weight:bold;">联系电话</td>
                        <td style="width: 35%;border: 1px solid black;text-align: center;">{{data.phone}}</td>
                    </tr>
                    <tr style="border: 1px solid black;height: 70px;">
                        <td style="border: 1px solid black;text-align: center;font-weight:bold;">经营范围</td>
                        <td style="border: 1px solid black;text-align: left;white-space: pre-line;" colspan=3>{{data.nature_of_business}}</td>
                    </tr>
                    <tr style="border: 1px solid black;height: 70px;">
                        <td style="border: 1px solid black;text-align: center;font-weight:bold;">企业地址</td>
                        <td style="border: 1px solid black;text-align: left;white-space: pre-line;" colspan=3>{{data.address}}</td>
                    </tr>
                    <tr style="border: 1px solid black;height: 70px;">
                        <td style="border: 1px solid black;text-align: center;font-weight:bold;">申请原因</td>
                        <td style="border: 1px solid black;text-align: left;white-space: pre-line;" colspan=3>{{data.content}}</td>
                    </tr>
                    <tr style="border: 1px solid black;height: 30px;">
                        <td style="width: 15%;border: 1px solid black;text-align: center;font-weight:bold;">督办人</td>
                        <td style="width: 35%;border: 1px solid black;text-align: center;">{{data.supervisor_name}}</td>
                        <td style="width: 15%;border: 1px solid black;text-align: center;font-weight:bold;">是否签合同</td>
                        <td style="width: 35%;border: 1px solid black;text-align: center;">{{['否','是'][data.is_cooperation]}}</td>
                    </tr>
                    <tr style="border: 1px solid black;height: 30px;" v-if="data.is_cooperation === 1">
                        <td style="border: 1px solid black;text-align: center;font-weight:bold;">签合同日期</td>
                        <td style="border: 1px solid black;text-align: center;" colspan=3>{{data.contract_date+'日之内'}}</td>
                    </tr>
                    <tr style="border: 1px solid black;height: 30px;" v-else-if="data.is_cooperation === 0">
                        <td style="border: 1px solid black;text-align: center;font-weight:bold;">不签原因</td>
                        <td style="border: 1px solid black;text-align: center;" colspan=3>{{data.contract_date}}</td>
                    </tr>
                    <tr style="border: 1px solid black;height: 30px;">
                        <td style="border: 1px solid black;text-align: center;font-weight:bold;">所属我方企业</td>
                        <td style="border: 1px solid black;text-align: center;" colspan=3>{{data.with_zhwl_enterprise}}</td>
                    </tr>
                    <tr style="border: 1px solid black;height: 30px;">
                        <td style="border: 1px solid black;text-align: center;font-weight:bold;">对方业务类型</td>
                        <td style="border: 1px solid black;text-align: center;" colspan=3>{{data.business_type_name}}</td>
                    </tr>
                    <tr style="border: 1px solid black;height: 30px;" v-if="data.type > 0">
                        <td style="border: 1px solid black;text-align: center;font-weight:bold;">服务项目</td>
                        <td style="border: 1px solid black;text-align: center;" colspan=3>{{data.provide_services_name}}</td>
                    </tr>
                    <tr style="border: 1px solid black;height: 70px;">
                        <td style="border: 1px solid black;text-align: center;font-weight:bold;">业务描述</td>
                        <td style="border: 1px solid black;text-align: left;white-space: pre-line;" colspan=3>{{data.business_mark}}</td>
                    </tr>
                    <tr style="border: 1px solid black;height: 70px;">
                        <td style="border: 1px solid black;text-align: center;font-weight:bold;">添加原因</td>
                        <td style="border: 1px solid black;text-align: left;white-space: pre-line;" colspan=3>{{data.add_cause_name}}</td>
                    </tr>
                    <tr style="border: 1px solid black;height: 70px;" v-if="data.add_cause_name === '其它'">
                        <td style="border: 1px solid black;text-align: center;font-weight:bold;">原因说明</td>
                        <td style="border: 1px solid black;text-align: left;white-space: pre-line;" colspan=3>{{data.add_cause_note}}</td>
                    </tr>
                    <tr style="border: 1px solid black;height: 3px;">
                        <td style="width: 15%;border: 1px solid black;text-align: center;font-weight:bold;">账期类型</td>
                        <td style="width: 35%;border: 1px solid black;text-align: center;">{{data.account_period_type_name}}</td>
                        <td style="width: 15%;border: 1px solid black;text-align: center;font-weight:bold;">账期天数</td>
                        <td style="width: 35%;border: 1px solid black;text-align: center;">{{data.account_period_day}}</td>
                    </tr>
                    <tr style="border: 1px solid black;height: 90px;" v-if="data.with_bank_card_rmb">
                        <td style="border: 1px solid black;text-align: center;font-weight:bold;">账户信息(RMB)</td>
                        <td style="border: 1px solid black;text-align: left;white-space: pre-line;" colspan=3>
                            <div>{{'【开户行】 '+data.with_bank_card_rmb_account_bank}}</div>
                            <div>{{'【户主】 '+data.with_bank_card_rmb_account_name}}</div>
                            <div>{{'【卡号】 '+data.with_bank_card_rmb_account_card}}</div>
                        </td>
                    </tr>
                    <tr style="border: 1px solid black;height: 90px;" v-if="data.with_bank_card_usd">
                        <td style="border: 1px solid black;text-align: center;font-weight:bold;">账户信息(USD)</td>
                        <td style="border: 1px solid black;text-align: left;white-space: pre-line;" colspan=3>
                            <div>{{'【开户行】 '+data.with_bank_card_usd_account_bank}}</div>
                            <div>{{'【户主】 '+data.with_bank_card_usd_account_name}}</div>
                            <div>{{'【卡号】 '+data.with_bank_card_usd_account_card}}</div>
                        </td>
                    </tr>
                    <tr style="border: 1px solid black;height: 30px;">
                        <td style="border: 1px solid black;text-align: center;font-weight:bold;">审批人</td>
                        <td style="border: 1px solid black;text-align: center;" colspan=3>{{examine}}</td>
                    </tr>
                </table>
                <div v-for="(iteam,index) in data.print" :key="iteam.time" style="font-family: Microsoft YaHei;font-size:12px;">
                    {{'【历史】此合作企业申请为第'+(index+1)+'次打印，打印人：'+iteam.staff+'，打印时间：'+iteam.time}}
                </div>
                <div style="font-family: Microsoft YaHei;font-size:12px;">
                    {{'【当前】此合作企业申请为第'+(data.print.length+1)+'次打印，打印人：'+$store.state.user.name+'，打印时间：' + NowTime}}
                </div>
                <br/>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button
                    plain
                    type="primary"
                    @click="printBtn('PrintCooperativeEnterprise')"
                    >打印</el-button
                >
                <el-button
                    plain
                    @click="() => $emit('exit_print')"
                    >关闭</el-button
                >
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { now } from '@/assets/js/common.js'
import { cooperative_enterprise_print_request } from '@/network/business/CooperativeEnterprise.js'

export default {
    name:'CooperativeEnterprisePrintComponent',
    data(){
        return {
            examine: '',
            NowTime: ''
        }
    },
    computed:{},
    methods:{
        printBtn(formRef) {
            this.login_loading = true
            cooperative_enterprise_print_request(this.data.id)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.$print(this.$refs[formRef])
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
    },
    filters:{
        title_name(v) {
            let a = '合作企业申请：'+v.name+' '

            if(v.state === 0) {
                a += '【待提交】'
            } else if(v.state === 1) {
                a += '【审批中】'
            } else if(v.state === 2) {
                a += '【已通过】'
            } else if(v.state === 3) {
                a += '【已拒绝】'
            }

            return a
        }
    },
    props:{
        show_print: {
            type: Boolean,
            default() {
                return false
            }
        },
        data: {
            type: Object,
            default() {
                return {}
            }
        },
        ApprovalData: {
            type: Array,
            default() {
                return []
            }
        }
    },
    created(){},
    destoryed(){},
    mounted(){},
    updated(){
        this.NowTime = now()
    },
    components:{},
    watch:{
        ApprovalData(newVal) {
            this.examine = ''
            newVal.forEach(v => {
                if(v.start_staff_id) {
                    this.examine = v.start_staff_name+'(发起)'
                }
                if(v.staff_id === -1) {
                    this.examine += '→'+v.staff_name+'(撤回)'
                }
                if((v.staff_id>0) && (!v.time)) {
                    this.examine += '→'+v.staff_name+'(待审批)'
                }
                if(v.opinion) {
                    this.examine += '→'+v.staff_name+'('+v.opinion+')'
                }
                if(v.staff_id === 0) {
                    this.examine += '→流程结束'
                }
            });
        }
    }
}
</script>

<style>
</style>