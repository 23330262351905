<template>
    <div>
        <el-dialog
            :visible.sync="dialogFormVisible"
            :append-to-body="true"
            width="80%"
            :center="true"
            top="2vh"
            :close-on-click-modal="false"
            :before-close="() => closeDialog()"
            v-loading.fullscreen.lock="login_loading"
        >
            <template #title>
                <span style="font-weight: bold;">订单详情</span>
                <el-tag v-if="CopOrder.state===0" type="info" size="mini">待提交</el-tag>
                <el-tag v-if="CopOrder.state===1" size="mini">审批中</el-tag>
                <el-tag v-if="CopOrder.state===2" type="success" size="mini">已通过</el-tag>
                <el-tag v-if="CopOrder.state===3" type="danger" size="mini">已驳回</el-tag>
                <!-- <el-button
                    v-if="CopOrder.state>0"
                    plain
                    circle
                    icon="el-icon-printer"
                    @click="clk_print()"
                    ></el-button
                > -->
            </template>
            <div>
                <fieldset class="fieldsetc">
                    <legend class="legendc">基础信息</legend>
                    <el-descriptions
                        class="margin-top"
                        :column="3"
                        :labelStyle="{'width':'120px','text-align':'center'}"
                        :contentStyle="{'text-align': 'center'}"
                        border
                    >
                        <el-descriptions-item>
                            <template slot="label">
                                <span>系统订单号</span>
                            </template>
                            <span>{{CopOrder.name}}</span>
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label">
                                <span>中远订单号</span>
                            </template>
                            <span>{{CopOrder.with_cop_order}}</span>
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label">
                                <span>核验状态</span>
                            </template>
                            <el-tag type="success" v-if="CopOrder.cop_check === 0">无异常</el-tag>
                            <el-tag type="warning" v-else-if="CopOrder.cop_check === 1">数量异常</el-tag>
                            <el-tag type="danger" v-else-if="CopOrder.cop_check === 2">价格异常</el-tag>
                            <el-tag type="info" v-else>未核验</el-tag>
                        </el-descriptions-item>
                        <el-descriptions-item :span="3">
                            <template slot="label">
                                <span>标题</span>
                            </template>
                            <span>{{CopOrder.title}}</span>
                        </el-descriptions-item>
                        <el-descriptions-item :span="3" :contentStyle="{'text-align':'left','white-space':'pre-line'}">
                            <template slot="label">
                                <span>内容</span>
                            </template>
                            <span>{{CopOrder.content}}</span>
                        </el-descriptions-item>
                    </el-descriptions>
                    <el-descriptions
                        class="margin-top"
                        :column="6"
                        :labelStyle="{'width':'120px','text-align':'center'}"
                        :contentStyle="{'text-align': 'center'}"
                        border
                        direction="vertical"
                    >
                        <el-descriptions-item>
                            <template slot="label">
                                <span>出发地</span>
                            </template>
                            <span>{{CopOrder.start_city}}</span>
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label">
                                <span>目的地</span>
                            </template>
                            <span>{{CopOrder.end_city}}</span>
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label">
                                <span>开始日期</span>
                            </template>
                            <span>{{CopOrder.start_time}}</span>
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label">
                                <span>结束日期</span>
                            </template>
                            <span>{{CopOrder.end_time}}</span>
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label">
                                <span>预交货期</span>
                            </template>
                            <span>{{CopOrder.estimatedTransitTimeInDays + '天'}}</span>
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label">
                                <span>船名航次</span>
                            </template>
                            <span v-html="vesselName"></span>
                        </el-descriptions-item>
                    </el-descriptions>
                </fieldset>
                <fieldset class="fieldsetc">
                    <legend class="legendc">集装箱信息</legend>
                    <el-descriptions
                        class="margin-top"
                        :column="5"
                        :labelStyle="{'width':'120px','text-align':'center'}"
                        :contentStyle="{'text-align': 'center'}"
                        border
                        direction="vertical"
                    >
                        <el-descriptions-item v-for="item in CopOrder.containerInfo" :key="item.containerType">
                            <template slot="label">
                                <span>{{item.containerType + '数量'}}</span>
                            </template>
                            <span>{{item.quantity}}</span>
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label">
                                <span>单证数量</span>
                            </template>
                            <span>{{CopOrder.blQuantity}}</span>
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label">
                                <span>是否保价</span>
                            </template>
                            <span v-if="CopOrder.includeInsurance === 1" style="color: #00A600;">是</span>
                            <span v-else style="color: #CE0000;">否</span>
                        </el-descriptions-item>
                    </el-descriptions>
                </fieldset>
                <fieldset class="fieldsetc">
                    <legend class="legendc">结算信息</legend>
                    <el-descriptions
                        class="margin-top"
                        :column="5"
                        :labelStyle="{'width':'120px','text-align':'center'}"
                        :contentStyle="{'text-align': 'center','font-size': '14px','font-weight': 'bolder'}"
                        border
                        direction="vertical"
                    >
                        <el-descriptions-item>
                            <template slot="label">
                                <span>海运+装港驳船费</span>
                            </template>
                            <div v-if="count_money_usd('海运+装港驳船费')">{{'USD '+count_money_usd('海运+装港驳船费')}}</div>
                            <div v-if="count_money_cny('海运+装港驳船费')">{{'CNY '+count_money_cny('海运+装港驳船费')}}</div>
                            <div v-if="(count_money_usd('海运+装港驳船费')===0) && (count_money_cny('海运+装港驳船费')===0)">-</div>
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label">
                                <span>单证费</span>
                            </template>
                            <div v-if="count_money_usd('单证费')">{{'USD '+count_money_usd('单证费')}}</div>
                            <div v-if="count_money_cny('单证费')">{{'CNY '+count_money_cny('单证费')}}</div>
                            <div v-if="(count_money_usd('单证费')===0) && (count_money_cny('单证费')===0)">-</div>
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label">
                                <span>起运地附加费</span>
                            </template>
                            <div v-if="count_money_usd('起运地附加费')">{{'USD '+count_money_usd('起运地附加费')}}</div>
                            <div v-if="count_money_cny('起运地附加费')">{{'CNY '+count_money_cny('起运地附加费')}}</div>
                            <div v-if="(count_money_usd('起运地附加费')===0) && (count_money_cny('起运地附加费')===0)">-</div>
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label">
                                <span>海运附加费</span>
                            </template>
                            <div v-if="count_money_usd('海运附加费')">{{'USD '+count_money_usd('海运附加费')}}</div>
                            <div v-if="count_money_cny('海运附加费')">{{'CNY '+count_money_cny('海运附加费')}}</div>
                            <div v-if="(count_money_usd('海运附加费')===0) && (count_money_cny('海运附加费')===0)">-</div>
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label">
                                <span>合计</span>
                            </template>
                            <span v-html="sum_money"></span>
                        </el-descriptions-item>
                    </el-descriptions>
                    <p>费用明细：</p>
                    <el-table
                        :header-cell-style="{fontSize:'14px'}" 
                        height="300px"
                        stripe
                        highlight-current-row
                        size="small"
                        :data="CopOrder.priceInfo"
                        border
                        style="width: 99%"
                    >
                        <el-table-column
                            prop="name"
                            label="费用名称"
                            min-width="130px"
                            align="center"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="type"
                            label="费用类型"
                            min-width="130px"
                            align="center"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="company"
                            label="单位"
                            min-width="60px"
                            align="center"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="currency"
                            label="币种"
                            min-width="60px"
                            align="center"
                        >
                        </el-table-column>
                        <el-table-column
                            label="单价"
                            min-width="80px"
                            align="center"
                        >
                            <template slot-scope="scope">
                                <span>{{scope.row['price'] | diy_money}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="num"
                            label="数量"
                            min-width="60px"
                            align="center"
                        >
                        </el-table-column>
                        <el-table-column
                            label="小计"
                            min-width="100px"
                            align="center"
                        >
                            <template slot-scope="scope">
                                <span>{{scope.row['money'] | diy_money}}</span>
                            </template>
                        </el-table-column>
                    </el-table>
                </fieldset>
                <fieldset class="fieldsetc">
                    <legend class="legendc">发货人信息</legend>
                    <el-descriptions
                        class="margin-top"
                        :column="2"
                        :labelStyle="{'width':'120px','text-align':'center'}"
                        :contentStyle="{'text-align': 'center'}"
                        border
                    >
                        <el-descriptions-item :span="2">
                            <template slot="label">
                                <span>名称</span>
                            </template>
                            <span>{{CopOrder.shipperInfo ? CopOrder.shipperInfo.name : ''}}</span>
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label">
                                <span>电话</span>
                            </template>
                            <span>{{CopOrder.shipperInfo ? CopOrder.shipperInfo.phone : ''}}</span>
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label">
                                <span>邮箱</span>
                            </template>
                            <span>{{CopOrder.shipperInfo ? CopOrder.shipperInfo.email : ''}}</span>
                        </el-descriptions-item>
                        <el-descriptions-item :contentStyle="{'text-align':'left','white-space':'pre-line'}">
                            <template slot="label">
                                <span>国家/地区</span>
                            </template>
                            <span>{{CopOrder.shipperInfo ? CopOrder.shipperInfo.addressLine1 : ''}}</span>
                        </el-descriptions-item>
                        <el-descriptions-item :contentStyle="{'text-align':'left','white-space':'pre-line'}">
                            <template slot="label">
                                <span>街道</span>
                            </template>
                            <span>{{CopOrder.shipperInfo ? CopOrder.shipperInfo.addressLine2 : ''}}</span>
                        </el-descriptions-item>
                        <el-descriptions-item :span="2" :contentStyle="{'text-align':'left','white-space':'pre-line'}">
                            <template slot="label">
                                <span>详细地址</span>
                            </template>
                            <span>{{CopOrder.shipperInfo ? CopOrder.shipperInfo.addressText : ''}}</span>
                        </el-descriptions-item>
                    </el-descriptions>
                </fieldset>
                <fieldset class="fieldsetc">
                    <legend class="legendc">收货人信息</legend>
                    <el-descriptions
                        class="margin-top"
                        :column="2"
                        :labelStyle="{'width':'120px','text-align':'center'}"
                        :contentStyle="{'text-align': 'center'}"
                        border
                    >
                        <el-descriptions-item :span="2">
                            <template slot="label">
                                <span>名称</span>
                            </template>
                            <span>{{CopOrder.consigneeInfo ? CopOrder.consigneeInfo.name : ''}}</span>
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label">
                                <span>电话</span>
                            </template>
                            <span>{{CopOrder.consigneeInfo ? CopOrder.consigneeInfo.phone : ''}}</span>
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label">
                                <span>邮箱</span>
                            </template>
                            <span>{{CopOrder.consigneeInfo ? CopOrder.consigneeInfo.email : ''}}</span>
                        </el-descriptions-item>
                        <el-descriptions-item :contentStyle="{'text-align':'left','white-space':'pre-line'}">
                            <template slot="label">
                                <span>国家/地区</span>
                            </template>
                            <span>{{CopOrder.consigneeInfo ? CopOrder.consigneeInfo.addressLine1 : ''}}</span>
                        </el-descriptions-item>
                        <el-descriptions-item :contentStyle="{'text-align':'left','white-space':'pre-line'}">
                            <template slot="label">
                                <span>街道</span>
                            </template>
                            <span>{{CopOrder.consigneeInfo ? CopOrder.consigneeInfo.addressLine2 : ''}}</span>
                        </el-descriptions-item>
                        <el-descriptions-item :span="2" :contentStyle="{'text-align':'left','white-space':'pre-line'}">
                            <template slot="label">
                                <span>详细地址</span>
                            </template>
                            <span>{{CopOrder.consigneeInfo ? CopOrder.consigneeInfo.addressText : ''}}</span>
                        </el-descriptions-item>
                    </el-descriptions>
                </fieldset>
                <fieldset class="fieldsetc">
                    <legend class="legendc">通知人信息</legend>
                    <el-descriptions
                        class="margin-top"
                        :column="2"
                        :labelStyle="{'width':'120px','text-align':'center'}"
                        :contentStyle="{'text-align': 'center'}"
                        border
                    >
                        <el-descriptions-item :span="2">
                            <template slot="label">
                                <span>名称</span>
                            </template>
                            <span>{{CopOrder.notify_partyInfo ? CopOrder.notify_partyInfo.name : ''}}</span>
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label">
                                <span>电话</span>
                            </template>
                            <span>{{CopOrder.notify_partyInfo ? CopOrder.notify_partyInfo.phone : ''}}</span>
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label">
                                <span>邮箱</span>
                            </template>
                            <span>{{CopOrder.notify_partyInfo ? CopOrder.notify_partyInfo.email : ''}}</span>
                        </el-descriptions-item>
                        <el-descriptions-item :contentStyle="{'text-align':'left','white-space':'pre-line'}">
                            <template slot="label">
                                <span>国家/地区</span>
                            </template>
                            <span>{{CopOrder.notify_partyInfo ? CopOrder.notify_partyInfo.addressLine1 : ''}}</span>
                        </el-descriptions-item>
                        <el-descriptions-item :contentStyle="{'text-align':'left','white-space':'pre-line'}">
                            <template slot="label">
                                <span>街道</span>
                            </template>
                            <span>{{CopOrder.notify_partyInfo ? CopOrder.notify_partyInfo.addressLine2 : ''}}</span>
                        </el-descriptions-item>
                        <el-descriptions-item :span="2" :contentStyle="{'text-align':'left','white-space':'pre-line'}">
                            <template slot="label">
                                <span>详细地址</span>
                            </template>
                            <span>{{CopOrder.notify_partyInfo ? CopOrder.notify_partyInfo.addressText : ''}}</span>
                        </el-descriptions-item>
                    </el-descriptions>
                </fieldset>
                <fieldset class="fieldsetc">
                    <legend class="legendc">联系人信息</legend>
                    <el-descriptions
                        class="margin-top"
                        :column="2"
                        :labelStyle="{'width':'120px','text-align':'center'}"
                        :contentStyle="{'text-align': 'center'}"
                        border
                    >
                        <el-descriptions-item :span="2">
                            <template slot="label">
                                <span>名称</span>
                            </template>
                            <span>{{CopOrder.emergency_contactInfo ? CopOrder.emergency_contactInfo.name : ''}}</span>
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label">
                                <span>电话</span>
                            </template>
                            <span>{{CopOrder.emergency_contactInfo ? CopOrder.emergency_contactInfo.phone : ''}}</span>
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label">
                                <span>邮箱</span>
                            </template>
                            <span>{{CopOrder.emergency_contactInfo ? CopOrder.emergency_contactInfo.email : ''}}</span>
                        </el-descriptions-item>
                        <el-descriptions-item :span="2" :contentStyle="{'text-align':'left','white-space':'pre-line'}">
                            <template slot="label">
                                <span>详细地址</span>
                            </template>
                            <span>{{CopOrder.emergency_contactInfo ? CopOrder.emergency_contactInfo.address : ''}}</span>
                        </el-descriptions-item>
                    </el-descriptions>
                </fieldset>
                <fieldset class="fieldsetc">
                    <legend class="legendc">货物信息</legend>
                    <el-descriptions
                        class="margin-top"
                        :column="2"
                        :labelStyle="{'width':'120px','text-align':'center'}"
                        :contentStyle="{'text-align': 'center'}"
                        border
                    >
                        <el-descriptions-item :span="2">
                            <template slot="label">
                                <span>货物名称</span>
                            </template>
                            <span>{{CopOrder.cargoInfo? CopOrder.cargoInfo.desc : ''}}</span>
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label">
                                <span>包装标志</span>
                            </template>
                            <span>{{CopOrder.cargoInfo? CopOrder.cargoInfo.packageTypeName : ''}}</span>
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label">
                                <span>货物数量</span>
                            </template>
                            <span>{{CopOrder.cargoInfo? CopOrder.cargoInfo.quantity+' 件' : ''}}</span>
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label">
                                <span>货物重量</span>
                            </template>
                            <span>{{CopOrder.cargoInfo? CopOrder.cargoInfo.weight+' KG' : ''}}</span>
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label">
                                <span>货物数量</span>
                            </template>
                            <span>{{CopOrder.cargoInfo? CopOrder.cargoInfo.volume+' m³' : ''}}</span>
                        </el-descriptions-item>
                        <el-descriptions-item :span="2" :contentStyle="{'text-align':'left','white-space':'pre-line'}">
                            <template slot="label">
                                <span>货物备注</span>
                            </template>
                            <span>{{CopOrder.cargoInfo? CopOrder.cargoInfo.remarks : ''}}</span>
                        </el-descriptions-item>
                    </el-descriptions>
                </fieldset>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button
                    v-if="to_approval_auth"
                    type="success"
                    plain
                    @click="to_approval('CopOrderEditForm')"
                    >提交</el-button
                >
                <el-button
                    v-if="sel_approval_auth"
                    type="warning"
                    plain
                    @click="sel_approval()"
                    >查看审批</el-button
                >
                <el-button
                    v-if="withdraw_auth"
                    type="danger"
                    plain
                    @click="CopOrderWithdraw('CopOrderEditForm')"
                    >审批撤回</el-button
                >
                <el-button
                    v-if="del_auth"
                    type="danger"
                    plain
                    @click="CopOrderDel('CopOrderEditForm')"
                    >删除</el-button
                >
                <el-button
                    plain
                    @click="closeDialog()"
                    >关闭</el-button
                >
                <common-approval-dialog
                    :id="id"
                    :ApprovalDialogVisible="ApprovalDialogVisible"
                    :data="ApprovalData"
                    :current_examine_staff_id="CopOrder.current_examine_staff_id"
                    :ApprovalAuth="ApprovalAuth"
                    :ApprovalRequest="CopOrderApprovalRequest"
                    @exitApprovalDialog="exitApprovalDialog"
                    @refresh="$emit('frefresh')"
                >
                </common-approval-dialog>
                <!-- <cop-order-print-component
                    :show_print="show_print"
                    :data="CopOrder"
                    :ApprovalData="ApprovalData"
                    @exit_print="show_print = false"
                >
                </cop-order-print-component> -->
            </div>
        </el-dialog>
    </div>
</template>

<script>
import CommonApprovalDialog from '@/components/common/CommonApprovalDialog'
// import CopOrderPrintComponent from './CopOrderPrintComponent'

import {
    cop_order_del_request,
    cop_order_to_approval_request,
    cop_order_sel_approval_request,
    cop_order_approval_request,
    cop_order_withdraw_request
} from '@/network/cop/CopOrder.js'

import { fomatFloat,thousandBitSeparator } from '@/assets/js/common.js'

export default {
    name: 'CopOrderEditComponent',
    data() {
        return {
            login_loading: false,
            ApprovalDialogVisible: false,
            ApprovalData: [],
            CopOrderApprovalRequest:cop_order_approval_request,
            show_print: false
        }
    },
    computed: {
        sum_money() {
            let cny = 0
            let usd = 0
            let m = 0
            if(this.CopOrder.priceInfo) {
                cny = this.CopOrder.priceInfo.reduce((s,cur) => {
                    m = 0
                    if(cur.currency === 'CNY') {
                        m = cur.money
                    }
                    return s + m * 1
                },0)

                usd = this.CopOrder.priceInfo.reduce((s,cur) => {
                    m = 0
                    if(cur.currency === 'USD') {
                        m = cur.money
                    }
                    return s + m * 1
                },0)
            }
            
            let html = ''
            html += '<div style="color: #2828FF;">USD ' + thousandBitSeparator(fomatFloat(usd)) + '</div>'
            html += '<div style="color: #EA0000;">CNY ' + thousandBitSeparator(fomatFloat(cny)) + '</div>'
            return html
        },
        vesselName() {
            let html = ''
            if(this.CopOrder.vesselName){
                const arr = this.CopOrder.vesselName.split('|')
                html += '<div>' + arr[0] + '</div>'
                html += '<div>' + arr[1] + '</div>'
                html += '<div>' + arr[2] + '</div>'
            }
            return html
        }
    },
    methods: {
        closeDialog() {
            this.$emit('exitDialog')
        },
        CopOrderDel(formName) {
            this.$confirm(
                '是否确定删除？（一经删除，将无法再进行恢复，请谨慎操作！）',
                '温馨提示',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            )
                .then(() => {
                    cop_order_del_request(this.id)
                        .then((s) => {
                            if (s.status === 0) {
                                this.$message.success('删除成功!')
                                this.$emit('search')
                            } else {
                                this.$message.error(s.msg)
                            }
                        })
                        .catch((err) => {
                            this.$message.error(err)
                        })
                        .finally(() => this.closeDialog())
                })
                .catch((err) => {})
        },
        to_approval(formName) {
            this.$prompt('请填写申请原因', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                }).then(({ value }) => {
                    this.login_loading = true
                    cop_order_to_approval_request({id:this.id,note:value})
                        .then((s) => {
                            this.login_loading = false
                            if (s.status === 0) {
                                    this.$message.success('提交成功!')
                                    this.$emit('search')
                            } else {
                                this.$message.error(s.msg)
                            }
                        })
                        .catch((err) => {
                            this.login_loading = false
                            this.$message.error(err)
                        })
                        .finally(() => this.closeDialog())
                }).catch(()=>{});
        },
        exitApprovalDialog() {
            this.ApprovalDialogVisible = false
        },
        sel_approval() {
            this.login_loading = true
            cop_order_sel_approval_request(this.id)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.ApprovalData = s.result
                        this.ApprovalDialogVisible = true
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        CopOrderWithdraw() {
            this.$confirm(
                '是否确定撤回？（请谨慎操作）',
                '温馨提示',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            )
                .then(() => {
                    this.login_loading = true
                    cop_order_withdraw_request({id:this.id})
                        .then((s) => {
                            this.login_loading = false
                            if (s.status === 0) {
                                this.$message.success('已撤回!')
                                this.$emit('frefresh')
                                this.$emit('search')
                            } else {
                                this.$message.error(s.msg)
                            }
                        })
                        .catch((err) => {
                            this.login_loading = false
                            this.$message.error(err)
                        })
                })
                .catch((err) => {})
        },
        clk_print() {
            this.login_loading = true
            cop_order_sel_approval_request(this.id)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.ApprovalData = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
            this.show_print = true
        },
        count_money_usd(str) {
            let money = 0
            let m = 0
            if(this.CopOrder.priceInfo) {
                money = this.CopOrder.priceInfo.reduce((s,cur) => {
                    m = 0
                    if((cur.type === str) && (cur.currency === 'USD')) {
                        m = cur.money
                    }
                    return s + m * 1
                },0)

                return money === 0 ? 0 : thousandBitSeparator(fomatFloat(money))
            }

            return 0
        },
        count_money_cny(str) {
            let money = 0
            let m = 0
            if(this.CopOrder.priceInfo) {
                money = this.CopOrder.priceInfo.reduce((s,cur) => {
                    m = 0
                    if((cur.type === str) && (cur.currency === 'CNY')) {
                        m = cur.money
                    }
                    return s + m * 1
                },0)

                return money === 0 ? 0 : thousandBitSeparator(fomatFloat(money))
            }

            return 0
        }
    },
    filters: {
        diy_money(v) {
            return thousandBitSeparator(fomatFloat(v))
        }
    },
    props: {
        dialogFormVisible: Boolean,
        id: {
            type: Number,
            default() {
                return 0
            },
        },
        CopOrder: {
            type: Object,
            default() {
                return {}
            },
        },
        del_auth: {
            type:Boolean,
            default() {
                return false
            }
        },
        to_approval_auth: {
            type:Boolean,
            default() {
                return false
            }
        },
        sel_approval_auth: {
            type:Boolean,
            default() {
                return false
            }
        },
        ApprovalAuth: {
            type:Boolean,
            default() {
                return false
            }
        },
        withdraw_auth: {
            type:Boolean,
            default() {
                return false
            }
        }
    },
    created() {},
    destoryed() {},
    mounted() {},
    updated() {},
    components: {
        CommonApprovalDialog,
        // CopOrderPrintComponent
    },
    watch: {},
}
</script>

<style>
.input_number {
    width: 100%;
}
</style>