import request from '../request'

//业务付款申请列表
export const business_payment_index_request = p => {
    return request({
        method:'GET',
        url:'business_payment/index',
        params: p
    })
}

//发起业务付款申请
export const business_payment_add_request = d => {
    return request({
        method:'POST',
        url:'business_payment/add',
        data: d
    })
}

//删除业务付款申请
export const business_payment_del_request = id => {
    return request({
        method:'DELETE',
        url:'business_payment/del',
        data: {
            id: id
        }
    })
}

//修改业务付款申请
export const business_payment_edit_request = data => {
    return request({
        method:'PUT',
        url:'business_payment/edit',
        data
    })
}

//获取业务付款申请详情
export const business_payment_details_request = id => {
    return request({
        method:'GET',
        url:'business_payment/details',
        params: {
            id
        }
    })
}

// 提交业务付款申请
export const business_payment_to_approval_request = d => {
    return request({
        method:'PUT',
        url:'business_payment/to_approval',
        data: {
            id:d.id,
            note:d.note
        }
    })
}

// 查看业务付款申请审批记录
export const business_payment_sel_approval_request = id => {
    return request({
        method:'GET',
        url:'business_payment/sel_approval',
        params: {
            id
        }
    })
}

// 审批业务付款申请
export const business_payment_approval_request = d => {
    return request({
        method:'PUT',
        url:'business_payment/approval',
        data: {
            id: d.id,
            opinion: d.opinion,
            note: d.note,
            other: d.other,
            go_staff_id: d.go_staff_id
        }
    })
}

// 业务付款汇款
export const business_payment_pay_request = id => {
    return request({
        method:'PUT',
        url:'business_payment/pay',
        data: {
            id: id
        }
    })
}

// 业务付款批量汇款
export const business_payment_select_pay_request = id => {
    return request({
        method:'PUT',
        url:'business_payment/select_pay',
        data: {
            id: id
        }
    })
}

// 业务付款批量加急
export const business_payment_select_urgent_request = id => {
    return request({
        method:'PUT',
        url:'business_payment/select_urgent',
        data: {
            id: id
        }
    })
}

// 撤回业务付款申请
export const business_payment_withdraw_request = d => {
    return request({
        method:'PUT',
        url:'business_payment/withdraw',
        data: {
            id: d.id
        }
    })
}

//费用结算单列表
export const business_payment_fjd_index_request = zhwl_id => {
    return request({
        method:'GET',
        url:'business_payment/fjd_index',
        params: {
            zhwl_id
        }
    })
}

//费用结算单详情
export const business_payment_fjd_details_request = p => {
    return request({
        method:'GET',
        url:'business_payment/fjd_details',
        params: {
            fjd_id: p.fjd_id,
            zhwl_id: p.zhwl_id,
        }
    })
}

//删除费用结算单
export const business_payment_fjd_del_request = fjd_id => {
    return request({
        method:'DELETE',
        url:'business_payment/zhwl_del',
        data: {
            fjd_id
        }
    })
}

// 补充业务付款申请附件
export const business_payment_add_file_request = data => {
    return request({
        method:'PUT',
        url:'business_payment/add_file',
        data
    })
}