import request from '../request'

//合同列表
export const contract_index_request = p => {
    return request({
        method:'GET',
        url:'contract/index',
        params: p
    })
}

//新增合同
export const contract_add_request = d => {
    return request({
        method:'POST',
        url:'contract/add',
        data: d
    })
}

//删除合同
export const contract_del_request = id => {
    return request({
        method:'DELETE',
        url:'contract/del',
        data: {
            id: id
        }
    })
}

//修改合同
export const contract_edit_request = data => {
    return request({
        method:'PUT',
        url:'contract/edit',
        data
    })
}

//合同详情
export const contract_details_request = id => {
    return request({
        method:'GET',
        url:'contract/details',
        params: {
            id
        }
    })
}

// 提交合同
export const contract_to_approval_request = d => {
    return request({
        method:'PUT',
        url:'contract/to_approval',
        data: {
            id:d.id,
            note:d.note
        }
    })
}

// 查看合同审批记录
export const contract_sel_approval_request = id => {
    return request({
        method:'GET',
        url:'contract/sel_approval',
        params: {
            id
        }
    })
}

// 审批合同
export const contract_approval_request = d => {
    return request({
        method:'PUT',
        url:'contract/approval',
        data: {
            id: d.id,
            opinion: d.opinion,
            note: d.note,
            other: d.other,
            go_staff_id: d.go_staff_id
        }
    })
}

// 撤回合同
export const contract_withdraw_request = d => {
    return request({
        method:'PUT',
        url:'contract/withdraw',
        data: {
            id: d.id
        }
    })
}

// 打印合同
export const contract_print_request = id => {
    return request({
        method:'PUT',
        url:'contract/print',
        data: {
            id
        }
    })
}

// 新增合同备案
export const contract_record_add_request = data => {
    return request({
        method:'PUT',
        url:'contract/record_add',
        data
    })
}

// 修改合同备案
export const contract_record_edit_request = data => {
    return request({
        method:'PUT',
        url:'contract/record_edit',
        data
    })
}

// 删除合同备案
export const contract_record_del_request = id  => {
    return request({
        method:'PUT',
        url:'contract/record_del',
        data: {
            id
        }
    })
}

// 合同备案详情
export const contract_record_details_request = id => {
    return request({
        method:'GET',
        url:'contract/record_details',
        params: {
            id
        }
    })
}

// 合同备案历史关联
export const contract_record_history_request = id => {
    return request({
        method:'GET',
        url:'contract/record_history',
        params: {
            id
        }
    })
}

// 补充合同附件
export const contract_add_file_request = data => {
    return request({
        method:'PUT',
        url:'contract/add_file',
        data
    })
}

// 合同作废
export const contract_cancel_request = data => {
    return request({
        method:'PUT',
        url:'contract/cancel',
        data
    })
}

//导出合同
export const out_excel_request = p => {
    return request({
        method:'GET',
        url:'contract/out_excel',
        params: p
    })
}

// 合同续签状态标记
export const contract_remind_request = data => {
    return request({
        method:'PUT',
        url:'contract/remind',
        data
    })
}