<template>
    <div>
        <el-dialog
            :visible.sync="dialogFormVisible"
            :append-to-body="true"
            width="60%"
            :center="true"
            top="2vh"
            :close-on-click-modal="false"
            :before-close="() => closeDialog()"
            v-loading.fullscreen.lock="login_loading"
        >
            <template #title>
                <span style="font-weight: bold;">{{dialogFormTitle}}</span>
                <el-tag v-if="form.state===0" type="info" size="mini">待提交</el-tag>
                <el-tag v-if="form.state===1" size="mini">审批中</el-tag>
                <el-tag v-if="form.state===2" type="success" size="mini">已通过</el-tag>
                <el-tag v-if="form.state===3" type="danger" size="mini">已驳回</el-tag>
                <el-button
                    v-if="(form.state>0) && ($_has(print_auth))"
                    plain
                    circle
                    icon="el-icon-printer"
                    @click="clk_print()"
                    ></el-button
                >
            </template>
            <div>
                <el-form
                    :model="form"
                    :rules="rules"
                    ref="EditForm"
                    label-width="120px"
                    size="small"
                >
                    <div v-if="show_type">
                        <fieldset class="fieldsetc">
                            <legend class="legendc">基础信息</legend>
                            <el-form-item label="我方企业" prop="with_zhwl_enterprise">
                                <el-select
                                    v-model="form.with_zhwl_enterprise"
                                    filterable
                                    clearable
                                    placeholder="选择我方企业"
                                    class="with_zhwl_enterprisec"
                                >
                                    <el-option
                                        v-for="enterprise in AllEnterprise"
                                        :key="enterprise.id"
                                        :label="enterprise.name"
                                        :value="enterprise.name"
                                    >
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="对方企业" prop="enterprise_name">
                                <el-input v-model="form.enterprise_name" clearable ></el-input>
                            </el-form-item>
                            <el-form-item label="税务登记号" prop="taxation">
                                <el-input v-model="form.taxation" clearable ></el-input>
                            </el-form-item>
                            <el-row :gutter="20">
                                <el-col :span="12">
                                    <el-form-item label="企业类型" prop="type">
                                        <el-select
                                            v-model="form.type"
                                            filterable
                                            clearable
                                            placeholder="选择类型"
                                            class="typec"
                                            @change="is_provide_services_change"
                                        >
                                            <el-option
                                                v-for="(v,i) in AllType"
                                                :key="v"
                                                :label="v"
                                                :value="i"
                                            >
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="合作状态" prop="status">
                                        <el-select
                                            v-model="form.status"
                                            placeholder="选择状态"
                                            class="statusc"
                                            clearable
                                        >
                                            <el-option
                                                label="临时"
                                                :value=1
                                            ></el-option>
                                            <el-option
                                                label="长期"
                                                :value=2
                                            ></el-option>
                                        </el-select>
                                        <span v-if="form.status===1" style='color: #FF0000;font-size: 13px;'>※ 选择为【临时】时，则在使用一次(智汇系统锁单后)自动禁用</span>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-form-item label="业务板块" v-if="form.type === 0">
                                <el-checkbox-group v-model="form.business_section" size="small" :max="1">
                                    <el-checkbox v-for="(v,i) in AllBusinessSection" :key="i" :label="v" border>{{ v }}</el-checkbox>
                                </el-checkbox-group>
                            </el-form-item>
                            <el-form-item label="供应商类别" v-if="form.type > 0">
                                <span style='color: #FF0000;font-size: 13px;'>※ 此项为公司统计供应商分类时的重要依据，请按真实情况进行勾选</span>
                                <el-checkbox-group v-model="form.classify" size="small" :max="1">
                                    <el-checkbox v-for="(v,i) in AllClassify" :key="i" :label="v" border>{{ v }}</el-checkbox>
                                </el-checkbox-group>
                            </el-form-item>
                            <el-form-item label="服务项目" v-if="form.type > 0">
                                <el-checkbox-group v-model="form.provide_services" size="small">
                                    <el-checkbox v-for="(v,i) in AllProvideServices" :key="i" :label="v" border>{{ v }}</el-checkbox>
                                </el-checkbox-group>
                            </el-form-item>
                            <el-form-item label="服务区域" v-if="form.type > 0">
                                <el-input v-model="form.region" clearable placeholder="限制10字以内"></el-input>
                            </el-form-item>
                            <el-row :gutter="20">
                                <el-col :span="12">
                                    <el-form-item label="联系人" prop="contacts">
                                        <el-input v-model="form.contacts" clearable ></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="联系电话" prop="phone">
                                        <el-input v-model="form.phone" clearable ></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-form-item label="经营范围" prop="nature_of_business">
                                <el-input type="textarea" :rows="3" v-model="form.nature_of_business"></el-input>
                            </el-form-item>
                            <el-form-item label="企业地址" prop="address">
                                <el-input type="textarea" :rows="3" v-model="form.address"></el-input>
                            </el-form-item>
                            <el-form-item label="申请原因" prop="content">
                                <el-input type="textarea" :rows="3" v-model="form.content"></el-input>
                            </el-form-item>
                            <el-row :gutter="20" v-if="form.type === 0">
                                <el-col :span="12">
                                    <el-form-item label="销售" prop="xiaoshou">
                                        <el-select
                                            v-model="form.xiaoshou"
                                            filterable
                                            clearable
                                            placeholder="选择销售员"
                                            class="xiaoshouc"
                                        >
                                            <el-option
                                                v-for="staff in AllStaff"
                                                :key="staff.id"
                                                :label="staff.name"
                                                :value="staff.id"
                                            >
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="客服专员" prop="kefu">
                                        <el-select
                                            v-model="form.kefu"
                                            filterable
                                            clearable
                                            placeholder="选择客服专员"
                                            class="kefuc"
                                        >
                                            <el-option
                                                v-for="staff in AllStaff"
                                                :key="staff.id"
                                                :label="staff.name"
                                                :value="staff.id"
                                            >
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row :gutter="20">
                                <el-col :span="12">
                                    <el-form-item label="督办人" prop="supervisor">
                                        <el-select
                                            v-model="form.supervisor"
                                            filterable
                                            clearable
                                            placeholder="选择督办人"
                                            class="supervisorc"
                                        >
                                            <el-option
                                                v-for="staff in AllStaff"
                                                :key="staff.id"
                                                :label="staff.name"
                                                :value="staff.id"
                                            >
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="是否签合同" prop="is_cooperation">
                                        <el-select
                                            v-model="form.is_cooperation"
                                            filterable
                                            clearable
                                            placeholder="请选择"
                                            class="is_cooperationc"
                                            @change="is_cooperationc_change"
                                        >
                                            <el-option
                                                v-for="(v,i) in ['否','是']"
                                                :key="v"
                                                :label="v"
                                                :value="i"
                                            >
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-form-item v-if="form.is_cooperation === 1" label="签合同日期" prop="contract_date">
                                <el-select
                                    v-model="form.contract_date"
                                    placeholder="选择日期"
                                    class="contract_datec"
                                    clearable
                                >
                                    <el-option
                                        v-for="(v,i) in [15,30,60]"
                                        :key="i"
                                        :label="v+'日之内'"
                                        :value="v"
                                    >
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item v-else-if="form.is_cooperation === 0" label="不签原因" prop="contract_date">
                                <el-input
                                    v-model="form.contract_date"
                                    clearable
                                    placeholder="填写原因"
                                ></el-input>
                            </el-form-item>
                            <common-upload-component
                                v-show="form.is_cooperation === 0"
                                ref="file_entrust"
                                :ffileList="form.entrust"
                                upload_url="cooperative_enterprise/entrust_upload"
                                title="委托书"
                                note="<span style='color: #FF0000;font-size: 13px;'>※ 根据公司要求，不签合同的企业必须上传委托书</span>"
                            ></common-upload-component>
                        </fieldset>
                        <fieldset class="fieldsetc">
                            <legend class="legendc">对方业务信息</legend>
                            <el-form-item label="业务类型" prop="business_type">
                                <el-select
                                    v-model="form.business_type"
                                    filterable
                                    clearable
                                    placeholder="选择业务类型"
                                    multiple
                                    :multiple-limit="10"
                                    class="business_typec"
                                >
                                    <el-option
                                        v-for="(v,i) in AllBusinessType"
                                        :key="v"
                                        :label="v"
                                        :value="i"
                                    >
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="添加原因" prop="add_cause" v-if="form.type===1">
                                <el-select
                                    v-model="form.add_cause"
                                    filterable
                                    clearable
                                    placeholder="选择添加原因"
                                    class="add_causec"
                                >
                                    <el-option
                                        v-for="(v,i) in ['价格优势','服务优势','客户指定','新线路合作','其它']"
                                        :key="v"
                                        :label="v"
                                        :value="i"
                                    >
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="原因说明" prop="add_cause_note" v-if="form.add_cause===4">
                                <el-input v-model="form.add_cause_note" clearable></el-input>
                            </el-form-item>
                            <el-form-item label="业务描述" prop="business_mark">
                                <el-input type="textarea" :rows="3" v-model="form.business_mark" placeholder="该企业的主营业务有哪些"></el-input>
                            </el-form-item>
                        </fieldset>
                        <fieldset class="fieldsetc">
                            <legend class="legendc">结算信息</legend>
                            <el-row :gutter="20">
                                <el-col :span="12">
                                    <el-form-item label="账期类型" prop="account_period_type">
                                        <el-select
                                            v-model="form.account_period_type"
                                            filterable
                                            clearable
                                            placeholder="选择账期类型"
                                            class="account_period_typec"
                                            @change="account_period_type_change"
                                        >
                                            <el-option
                                                v-for="(v,i) in AllAccountPeriodType"
                                                :key="v"
                                                :label="v"
                                                :value="i"
                                            >
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="账期天数" prop="account_period_day" v-if="form.account_period_type !==''">
                                        <div v-if="form.account_period_type===6">
                                            <el-input-number
                                                v-model="form.account_period_day"
                                                :min="1"
                                                :max="999"
                                                :step="1"
                                                :precision="0"
                                                :step-strictly="true"
                                                size="small"
                                                class="account_period_dayc"
                                            ></el-input-number>
                                        </div>
                                        <div v-else>
                                            <span>{{form.account_period_day}}</span>
                                        </div>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row :gutter="20">
                                <el-col :span="12">
                                    <el-form-item label="银行卡(RMB)" prop="with_bank_card_rmb">
                                        <el-select
                                            v-model="form.with_bank_card_rmb"
                                            filterable
                                            clearable
                                            placeholder="选择银行卡"
                                            class="with_bank_card_rmbc"
                                            @change="with_bank_card_rmb_change"
                                        >
                                            <el-option
                                                v-for="item in AllBankCard"
                                                :key="item.id"
                                                :label="'【'+item.user_name+'】'+item.card+' — '+item.bank"
                                                :value="item.id"
                                            >
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="户主" prop="with_bank_card_rmb_account_name" v-if="form.with_bank_card_rmb !==''">
                                        <span>{{form.with_bank_card_rmb_account_name}}</span>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row :gutter="20" v-if="form.with_bank_card_rmb !==''">
                                <el-col :span="12">
                                    <el-form-item label="开户行" prop="with_bank_card_rmb_account_bank">
                                        <span>{{form.with_bank_card_rmb_account_bank}}</span>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="卡号" prop="with_bank_card_rmb_account_card">
                                        <span>{{form.with_bank_card_rmb_account_card}}</span>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row :gutter="20">
                                <el-col :span="12">
                                    <el-form-item label="银行卡(USD)" prop="with_bank_card_usd">
                                        <el-select
                                            v-model="form.with_bank_card_usd"
                                            filterable
                                            clearable
                                            placeholder="选择银行卡"
                                            class="with_bank_card_usdc"
                                            @change="with_bank_card_usd_change"
                                        >
                                            <el-option
                                                v-for="item in AllBankCard"
                                                :key="item.id"
                                                :label="'【'+item.user_name+'】'+item.card+' — '+item.bank"
                                                :value="item.id"
                                            >
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="户主" prop="with_bank_card_usd_account_name" v-if="form.with_bank_card_usd !==''">
                                        <span>{{form.with_bank_card_usd_account_name}}</span>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row :gutter="20" v-if="form.with_bank_card_usd !==''">
                                <el-col :span="12">
                                    <el-form-item label="开户行" prop="with_bank_card_usd_account_bank">
                                        <span>{{form.with_bank_card_usd_account_bank}}</span>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="卡号" prop="with_bank_card_usd_account_card">
                                        <span>{{form.with_bank_card_usd_account_card}}</span>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-form-item>
                                <el-button plain size="small" @click="bankCardDialogFormVisible = true">列表没有？点此添加新银行卡</el-button>
                            </el-form-item>
                        </fieldset>
                        <common-upload-component
                            ref="upload"
                            :ffileList="form.other"
                            upload_url="cooperative_enterprise/upload"
                            note="<span style='color: #FF0000;font-size: 13px;'>※ 根据商务部要求，最少必须上传营业执照，供应商还需要上传对应行业资质许可证</span>"
                        ></common-upload-component>
                    </div>
                    <div v-else>
                        <fieldset class="fieldsetc">
                            <legend class="legendc">基础信息</legend>
                            <el-descriptions
                                class="margin-top"
                                :column="2"
                                :labelStyle="{'width':'120px','text-align':'center'}"
                                :contentStyle="{'text-align': 'center'}"
                                border
                            >
                                <el-descriptions-item :span="2">
                                    <template slot="label">
                                        <span>我方企业</span>
                                    </template>
                                    <span>{{form.with_zhwl_enterprise}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item :span="2">
                                    <template slot="label">
                                        <span>对方企业</span>
                                    </template>
                                    <span>{{form.enterprise_name}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item :span="2">
                                    <template slot="label">
                                        <span>税务登记号</span>
                                    </template>
                                    <span>{{form.taxation}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>企业类型</span>
                                    </template>
                                    <span>{{form.type_name}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>合作状态</span>
                                    </template>
                                    <el-tag v-if="form.status === 0" type="danger">禁用</el-tag>
                                    <el-tag v-else-if="form.status === 1" type="warning">临时</el-tag>
                                    <el-tag v-else-if="form.status === 2" type="primary">长期</el-tag>
                                </el-descriptions-item>
                                <el-descriptions-item :span="2" v-if="form.type === 0">
                                    <template slot="label">
                                        <span>业务板块</span>
                                    </template>
                                    <span>{{form.business_section_name}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item :span="2" v-if="form.type > 0">
                                    <template slot="label">
                                        <span>供应商类别</span>
                                    </template>
                                    <span>{{form.classify_name}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item :span="2" v-if="form.type > 0">
                                    <template slot="label">
                                        <span>服务项目</span>
                                    </template>
                                    <span>{{form.provide_services_name}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item :span="2" v-if="form.type > 0">
                                    <template slot="label">
                                        <span>服务区域</span>
                                    </template>
                                    <span>{{form.region}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>联系人</span>
                                    </template>
                                    <span>{{form.contacts}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>联系电话</span>
                                    </template>
                                    <span>{{form.phone}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item :span="2" :contentStyle="{'text-align':'left','white-space':'pre-line'}">
                                    <template slot="label">
                                        <span>经营范围</span>
                                    </template>
                                    <span>{{form.nature_of_business}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item :span="2" :contentStyle="{'text-align':'left','white-space':'pre-line'}">
                                    <template slot="label">
                                        <span>企业地址</span>
                                    </template>
                                    <span>{{form.address}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item :span="2" :contentStyle="{'text-align':'left','white-space':'pre-line'}">
                                    <template slot="label">
                                        <span>申请原因</span>
                                    </template>
                                    <span>{{form.content}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item  v-if="form.type === 0">
                                    <template slot="label">
                                        <span>销售员</span>
                                    </template>
                                    <span>{{form.xiaoshou_name}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item  v-if="form.type === 0">
                                    <template slot="label">
                                        <span>客服专员</span>
                                    </template>
                                    <span>{{form.kefu_name}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>督办人</span>
                                    </template>
                                    <span>{{form.supervisor_name}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>是否签合同</span>
                                    </template>
                                    <el-tag v-if="form.is_cooperation === 1" type="success">是</el-tag>
                                    <el-tag v-else-if="form.is_cooperation === 0" type="danger">否</el-tag>
                                </el-descriptions-item>
                                <el-descriptions-item :span="2" v-if="form.is_cooperation === 1">
                                    <template slot="label">
                                        <span>签合同日期</span>
                                    </template>
                                    <span>{{form.contract_date + '日之内'}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item :span="2" v-if="form.is_cooperation === 0">
                                    <template slot="label">
                                        <span>不签原因</span>
                                    </template>
                                    <span>{{form.contract_date}}</span>
                                </el-descriptions-item>
                            </el-descriptions>
                            <common-upload-component
                                v-show="(form.is_cooperation === 0) && (form.entrust.length)"
                                ref="file_entrust"
                                :ffileList="form.entrust"
                                upload_url="cooperative_enterprise/entrust_upload"
                                title="委托书"
                                :disabled="true"
                            ></common-upload-component>
                        </fieldset>
                        <fieldset class="fieldsetc">
                            <legend class="legendc">对方业务信息</legend>
                            <el-descriptions
                                class="margin-top"
                                :column="2"
                                :labelStyle="{'width':'120px','text-align':'center'}"
                                :contentStyle="{'text-align': 'center'}"
                                border
                            >
                                <el-descriptions-item :span="2">
                                    <template slot="label">
                                        <span>业务类型</span>
                                    </template>
                                    <span>{{form.business_type_name}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item :span="2">
                                    <template slot="label">
                                        <span>添加原因</span>
                                    </template>
                                    <span>{{form.add_cause_name}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item :span="2" v-if="form.add_cause === 4">
                                    <template slot="label">
                                        <span>原因说明</span>
                                    </template>
                                    <span>{{form.add_cause_note}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item :span="2" :contentStyle="{'text-align':'left','white-space':'pre-line'}">
                                    <template slot="label">
                                        <span>业务描述</span>
                                    </template>
                                    <span>{{form.business_mark}}</span>
                                </el-descriptions-item>
                            </el-descriptions>
                        </fieldset>
                        <fieldset class="fieldsetc">
                            <legend class="legendc">结算信息</legend>
                            <el-descriptions
                                class="margin-top"
                                :column="2"
                                :labelStyle="{'width':'120px','text-align':'center'}"
                                :contentStyle="{'text-align': 'center'}"
                                border
                            >
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>账期类型</span>
                                    </template>
                                    <span>{{form.account_period_type_name}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>账期天数</span>
                                    </template>
                                    <span>{{form.account_period_day}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item :span="2" v-if="form.with_bank_card_rmb">
                                    <template slot="label">
                                        <span>银行卡号(RMB)</span>
                                    </template>
                                    <span>{{form.with_bank_card_rmb_account_card}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item v-if="form.with_bank_card_rmb">
                                    <template slot="label">
                                        <span>开户行</span>
                                    </template>
                                    <span>{{form.with_bank_card_rmb_account_bank}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item v-if="form.with_bank_card_rmb">
                                    <template slot="label">
                                        <span>户主</span>
                                    </template>
                                    <span>{{form.with_bank_card_rmb_account_name}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item :span="2" v-if="form.with_bank_card_usd">
                                    <template slot="label">
                                        <span>银行卡号(USD)</span>
                                    </template>
                                    <span>{{form.with_bank_card_usd_account_card}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item v-if="form.with_bank_card_usd">
                                    <template slot="label">
                                        <span>开户行</span>
                                    </template>
                                    <span>{{form.with_bank_card_usd_account_bank}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item v-if="form.with_bank_card_usd">
                                    <template slot="label">
                                        <span>户主</span>
                                    </template>
                                    <span>{{form.with_bank_card_usd_account_name}}</span>
                                </el-descriptions-item>
                            </el-descriptions>
                        </fieldset>
                        <common-upload-component
                            v-show="form.other.length"
                            ref="upload"
                            :ffileList="form.other"
                            upload_url="cooperative_enterprise/upload"
                            :disabled="true"
                        ></common-upload-component>
                        <common-upload-component
                            v-show="AddFileDialogVisible"
                            ref="file_upload"
                            :ffileList="file_arr"
                            upload_url="cooperative_enterprise/upload"
                            title="补充附件"
                        ></common-upload-component>
                    </div>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer">
                <div v-if="show_type===0">
                    <div v-if="AddFileDialogVisible === false">
                        <el-button v-if="$_has(add_auth) && (form.state >0 )" type="primary" plain @click="copy_me">复制</el-button>
                        <el-button v-if="add_file_auth" type="success" plain @click="add_file()">补充附件</el-button>
                        <el-button v-if="to_approval_auth" type="success" plain @click="to_approval()">提交</el-button>
                        <el-button v-if="sel_approval_auth" type="warning" plain @click="sel_approval()">查看审批</el-button>
                        <el-button v-if="withdraw_auth" type="danger" plain @click="CooperativeEnterpriseWithdraw()">审批撤回</el-button>
                        <el-button v-if="edit_auth" type="primary" plain @click="$emit('show_edit')">修改</el-button>
                        <el-button v-if="del_auth" type="danger" plain @click="CooperativeEnterpriseDel()">删除</el-button>
                        <el-button plain @click="closeDialog()">关闭</el-button>
                    </div>
                    <div v-else>
                        <el-button type="success" plain @click="submit_add_file()" >确定</el-button>
                        <el-button plain @click="exit_add_file()">取消</el-button>
                    </div>
                </div>
                <div v-else-if="show_type===1">
                    <el-button type="success" plain @click="submitInsert('EditForm')">确定</el-button>
                    <el-button type="warning" plain @click="resetForm()">重置</el-button>
                    <el-button plain @click="closeDialog()">关闭</el-button>
                </div>
                <div v-else>
                    <el-button type="success" plain @click="submitEdit('EditForm')">确定修改</el-button>
                    <el-button plain @click="closeDialog()">取消操作</el-button>
                </div>
                <common-approval-dialog
                    :id="id"
                    :ApprovalDialogVisible="ApprovalDialogVisible"
                    :data="ApprovalData"
                    :current_examine_staff_id="form.current_examine_staff_id"
                    :ApprovalAuth="ApprovalAuth"
                    :ApprovalRequest="CooperativeEnterpriseApprovalRequest"
                    @exitApprovalDialog="exitApprovalDialog"
                    @refresh="$emit('frefresh')"
                >
                </common-approval-dialog>
                <cooperative-enterprise-print-component
                    :show_print="show_print"
                    :data="form"
                    :ApprovalData="ApprovalData"
                    @exit_print="show_print = false"
                >
                </cooperative-enterprise-print-component>
            </div>
        </el-dialog>
        <!-- 银行卡添加界面 -->
        <bank-card-edit-component
            edit_auth="修改银行卡"
            del_auth="删除银行卡"
            :show_type=1
            :dialogFormVisible="bankCardDialogFormVisible"
            @exitBankCardDialog="bankCardDialogFormVisible = false"
            @search="$emit('refresh_bank_card_list')"
            @details_row="bankCardDialogFormVisible = false"
        ></bank-card-edit-component>
    </div>
</template>

<script>
import CommonUploadComponent from '@/components/common/CommonUploadComponent'
import CommonApprovalDialog from '@/components/common/CommonApprovalDialog'
import CooperativeEnterprisePrintComponent from './CooperativeEnterprisePrintComponent'
import BankCardEditComponent from '@/components/finance/BackCard/BankCardEditComponent'

import {
    cooperative_enterprise_add_request,
    cooperative_enterprise_del_request,
    cooperative_enterprise_edit_request,
    cooperative_enterprise_to_approval_request,
    cooperative_enterprise_sel_approval_request,
    cooperative_enterprise_approval_request,
    cooperative_enterprise_withdraw_request,
    cooperative_enterprise_add_file_request
} from '@/network/business/CooperativeEnterprise.js'

export default {
    name: 'CooperativeEnterpriseEditComponent',
    data() {
        return {
            login_loading: false,
            ApprovalDialogVisible: false,
            form: {
                enterprise_name: '',
                type: '',
                status: '',
                taxation: '',
                contacts: '',
                phone: '',
                nature_of_business: '',
                address: '',
                content: '',
                business_type: '',
                xiaoshou: '',
                kefu: '',
                supervisor: '',
                is_cooperation: '',
                contract_date: '',
                with_zhwl_enterprise: '',
                business_section: [],
                classify: [],
                provide_services: [],
                region: '',
                business_mark: '',
                add_cause: '',
                add_cause_note: '',
                account_period_type: '',
                account_period_day: '',
                with_bank_card_rmb: '',
                with_bank_card_rmb_account_name: '',
                with_bank_card_rmb_account_bank: '',
                with_bank_card_rmb_account_card: '',
                with_bank_card_usd: '',
                with_bank_card_usd_account_name: '',
                with_bank_card_usd_account_bank: '',
                with_bank_card_usd_account_card: '',
                other: [],
                entrust: [],
                print: []
            },
            rules: {
                enterprise_name: [
                    {
                        required: true,
                        message: '请填写企业名称',
                        trigger: 'blur',
                    },
                ],
                type: [
                    {
                        required: true,
                        message: '请选择企业类型',
                        trigger: 'blur',
                    },
                ],
                status: [
                    {
                        required: true,
                        message: '请选择合作状态',
                        trigger: 'blur',
                    },
                ],
                taxation: [
                    {
                        required: true,
                        message: '请填写税务登记号',
                        trigger: 'blur',
                    },
                ],
                contacts: [
                    {
                        required: true,
                        message: '请填写企业联系人',
                        trigger: 'blur',
                    },
                ],
                phone: [
                    {
                        required: true,
                        message: '请填写企业联系电话',
                        trigger: 'blur',
                    },
                ],
                nature_of_business: [
                    {
                        required: true,
                        message: '请填写企业经营范围',
                        trigger: 'blur',
                    },
                ],
                address: [
                    {
                        required: true,
                        message: '请填写企业地址',
                        trigger: 'blur',
                    },
                ],
                content: [
                    {
                        required: true,
                        message: '请填写申请原因',
                        trigger: 'blur',
                    },
                ],
                business_type: [
                    {
                        required: true,
                        message: '请选择业务类型',
                        trigger: 'blur',
                    },
                ],
                supervisor: [
                    {
                        required: true,
                        message: '请选择督办人',
                        trigger: 'blur',
                    },
                ],
                is_cooperation: [
                    {
                        required: true,
                        message: '请选择是否签订合同',
                        trigger: 'blur',
                    },
                ],
                contract_date: [
                    {
                        required: true,
                        message: '请填写不签合同原因',
                        trigger: 'blur',
                    },
                ],
                with_zhwl_enterprise: [
                    {
                        required: true,
                        message: '请选择所属我方企业',
                        trigger: 'blur',
                    },
                ],
                account_period_type: [
                    {
                        required: true,
                        message: '请选择账期类型',
                        trigger: 'blur',
                    },
                ],
                account_period_day: [
                    {
                        required: true,
                        message: '请填写账期天数',
                        trigger: 'blur',
                    },
                ]
            },
            ApprovalData: [],
            CooperativeEnterpriseApprovalRequest:cooperative_enterprise_approval_request,
            show_print: false,
            AddFileDialogVisible: false,
            //补充附件
            file_arr: [],
            bankCardDialogFormVisible: false,
            new_bank_card_id: 0
        }
    },
    computed: {
        dialogFormTitle() {
            if(this.show_type === 0) {
                return this.form.name ? '合作企业申请：' + this.form.name : '合作企业申请详情'
            } else if (this.show_type === 1) {
                return '添加合作企业申请'
            } else {
                return '修改合作企业申请'
            }
        },
    },
    methods: {
        closeDialog() {
            this.resetForm()
            this.$emit('exitDialog')
        },
        submitInsert(formName) {
            this.$refs[formName].validate((valid) => {
                if (!valid) {
                    return
                }

                if((this.form.type === 1) && (!this.form.with_bank_card_rmb) && (!this.form.with_bank_card_usd)) {
                    this.$message.error('企业类型为“供应商”时，必须填写银行卡信息')
                    return
                }

                if((this.form.type === 1) && (this.form.classify.length === 0)) {
                    this.$message.error('企业类型为“供应商”时，必须填写供应商类别')
                    return
                }

                if((this.form.type === 1) && (this.form.provide_services.length === 0)) {
                    this.$message.error('企业类型为“供应商”时，必须填写服务项目')
                    return
                }

                if((this.form.type === 1) && (this.form.region === '')) {
                    this.$message.error('企业类型为“供应商”时，必须填写服务区域')
                    return
                }

                if((this.form.type === 1) && (this.form.add_cause === '')) {
                    this.$message.error('企业类型为“供应商”时，必须填写添加原因')
                    return
                }

                if((this.form.add_cause === 4) && (this.form.add_cause_note === '')) {
                    this.$message.error('添加原因为空时，必须填写原因说明')
                    return
                }

                if((this.form.type === 0) && ((!this.form.xiaoshou) || (!this.form.kefu))) {
                    this.$message.error('企业类型为“客户”时，必须填写销售员和客服专员')
                    return
                }

                if((this.form.type === 0) && (this.form.business_section.length === 0) && (this.form.with_zhwl_enterprise !== '荆门市陆港宇圣通物流有限公司')) {
                    this.$message.error('企业类型为“客户”时，必须填写业务板块')
                    return
                }

                this.CooperativeEnterpriseInsert(formName)
            })
        },
        CooperativeEnterpriseInsert(formName) {
            this.form.other = this.$refs.upload.fileList
            this.form.entrust = this.$refs.file_entrust.fileList

            if((this.form.is_cooperation === 0) && (this.form.entrust.length === 0)) {
                this.$message.error('根据公司要求，不签合同的企业必须上传委托书')
                return
            }

            this.login_loading = true
            cooperative_enterprise_add_request(this.form)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.$message.success('添加成功!')
                        this.$emit('exitDialog')
                        this.$emit('search')
                        this.$emit('details_row',s.result.id*1)
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        resetForm() {
            this.form = {
                enterprise_name: '',
                type: '',
                status: '',
                taxation: '',
                contacts: '',
                phone: '',
                nature_of_business: '',
                address: '',
                content: '',
                business_type: '',
                xiaoshou: '',
                kefu: '',
                supervisor: '',
                is_cooperation: '',
                contract_date: '',
                with_zhwl_enterprise: '',
                business_section: [],
                classify: [],
                provide_services: [],
                region: '',
                business_mark: '',
                add_cause: '',
                add_cause_note: '',
                account_period_type: '',
                account_period_day: '',
                with_bank_card_rmb: '',
                with_bank_card_rmb_account_name: '',
                with_bank_card_rmb_account_bank: '',
                with_bank_card_rmb_account_card: '',
                with_bank_card_usd: '',
                with_bank_card_usd_account_name: '',
                with_bank_card_usd_account_bank: '',
                with_bank_card_usd_account_card: '',
                other: [],
                entrust: [],
                print: []
            }
        },
        submitEdit(formName) {
            this.$refs[formName].validate((valid) => {
                if (!valid) {
                    return
                }

                if((this.form.type === 1) && (!this.form.with_bank_card_rmb) && (!this.form.with_bank_card_usd)) {
                    this.$message.error('企业类型为“供应商”时，必须填写银行卡信息')
                    return
                }

                if((this.form.type === 1) && (this.form.classify.length === 0)) {
                    this.$message.error('企业类型为“供应商”时，必须填写供应商类别')
                    return
                }

                if((this.form.type === 1) && (this.form.provide_services.length === 0)) {
                    this.$message.error('企业类型为“供应商”时，必须填写服务项目')
                    return
                }

                if((this.form.type === 1) && (this.form.region === '')) {
                    this.$message.error('企业类型为“供应商”时，必须填写服务区域')
                    return
                }

                if((this.form.type === 1) && (this.form.add_cause === '')) {
                    this.$message.error('企业类型为“供应商”时，必须填写添加原因')
                    return
                }

                if((this.form.add_cause === 4) && (this.form.add_cause_note === '')) {
                    this.$message.error('添加原因为空时，必须填写原因说明')
                    return
                }

                if((this.form.type === 0) && ((!this.form.xiaoshou) || (!this.form.kefu))) {
                    this.$message.error('企业类型为“客户”时，必须填写销售员和客服专员')
                    return
                }

                if((this.form.type === 0) && (this.form.business_section.length === 0) && (this.form.with_zhwl_enterprise !== '荆门市陆港宇圣通物流有限公司')) {
                    this.$message.error('企业类型为“客户”时，必须填写业务板块')
                    return
                }
                

                this.CooperativeEnterpriseEdit(formName)
            })
        },
        CooperativeEnterpriseEdit(formName) {
            this.form.id = this.id
            this.form.other = this.$refs.upload.fileList
            this.form.entrust = this.$refs.file_entrust.fileList

            if((this.form.is_cooperation === 0) && (this.form.entrust.length === 0)) {
                this.$message.error('根据公司要求，不签合同的企业必须上传委托书')
                return
            }

            this.login_loading = true
            cooperative_enterprise_edit_request(this.form)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.$message.success('修改成功!')
                        this.$emit('exitDialog')
                        this.$emit('search')
                        this.$emit('details_row',this.form.id)
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        CooperativeEnterpriseDel(formName) {
            this.$confirm(
                '是否确定删除？（一经删除，将无法再进行恢复，请谨慎操作！）',
                '温馨提示',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            )
                .then(() => {
                    cooperative_enterprise_del_request(this.id)
                        .then((s) => {
                            if (s.status === 0) {
                                this.$message.success('删除成功!')
                                this.$emit('search')
                            } else {
                                this.$message.error(s.msg)
                            }
                        })
                        .catch((err) => {
                            this.$message.error(err)
                        })
                        .finally(() => this.closeDialog())
                })
                .catch((err) => {})
        },
        to_approval() {
            this.$prompt('请填写申请原因', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                }).then(({ value }) => {
                    this.login_loading = true
                    cooperative_enterprise_to_approval_request({id:this.id,note:value})
                        .then((s) => {
                            this.login_loading = false
                            if (s.status === 0) {
                                    this.$message.success('提交成功!')
                                    this.$emit('search')
                            } else {
                                this.$message.error(s.msg)
                            }
                        })
                        .catch((err) => {
                            this.login_loading = false
                            this.$message.error(err)
                        })
                        .finally(() => this.closeDialog())
                }).catch(()=>{});
        },
        exitApprovalDialog() {
            this.ApprovalDialogVisible = false
        },
        sel_approval() {
            this.login_loading = true
            cooperative_enterprise_sel_approval_request(this.id)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.ApprovalData = s.result
                        this.ApprovalDialogVisible = true
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        CooperativeEnterpriseWithdraw() {
            this.$confirm(
                '是否确定撤回？（请谨慎操作）',
                '温馨提示',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            )
                .then(() => {
                    this.login_loading = true
                    cooperative_enterprise_withdraw_request({id:this.id})
                        .then((s) => {
                            this.login_loading = false
                            if (s.status === 0) {
                                this.$message.success('已撤回!')
                                this.$emit('frefresh')
                                this.$emit('search')
                            } else {
                                this.$message.error(s.msg)
                            }
                        })
                        .catch((err) => {
                            this.login_loading = false
                            this.$message.error(err)
                        })
                })
                .catch((err) => {})
        },
        clk_print() {
            this.login_loading = true
            cooperative_enterprise_sel_approval_request(this.id)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.ApprovalData = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
            this.show_print = true
        },
        account_period_type_change(e) {
            if((e===0) || (e===1)) {
                this.form.account_period_day = 0
            } else if(e===2) {
                this.form.account_period_day = 30
            } else if(e===3) {
                this.form.account_period_day = 90
            } else if(e===4) {
                this.form.account_period_day = 180
            } else if(e===5) {
                this.form.account_period_day = 365
            }
        },
        with_bank_card_rmb_change(e) {
            let account_name = ''
            let account_bank = ''
            let account_card = ''
            this.AllBankCard.forEach(v => {
                if(v.id === e) {
                    account_name = v.user_name
                    account_bank = v.bank
                    account_card = v.card
                }
            });

            this.form.with_bank_card_rmb_account_name = account_name
            this.form.with_bank_card_rmb_account_bank = account_bank
            this.form.with_bank_card_rmb_account_card = account_card
        },
        with_bank_card_usd_change(e) {
            let account_name = ''
            let account_bank = ''
            let account_card = ''
            this.AllBankCard.forEach(v => {
                if(v.id === e) {
                    account_name = v.user_name
                    account_bank = v.bank
                    account_card = v.card
                }
            });

            this.form.with_bank_card_usd_account_name = account_name
            this.form.with_bank_card_usd_account_bank = account_bank
            this.form.with_bank_card_usd_account_card = account_card
        },
        add_file() {
            this.AddFileDialogVisible = true
        },
        exit_add_file() {
            this.AddFileDialogVisible = false
            this.file_arr = []
        },
        submit_add_file() {
            let data = {}
            this.file_arr = this.$refs.file_upload.fileList
            data['id'] = this.id
            data['file'] = this.file_arr
            cooperative_enterprise_add_file_request(data)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.$message.success('补充附件成功!')
                        this.$emit('frefresh')
                        this.AddFileDialogVisible = false
                        this.file_arr = []
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        is_cooperationc_change() {
            this.form.contract_date = ''
            this.form.entrust = []
        },
        is_provide_services_change() {
            this.form.business_section = []
            this.form.classify = []
            this.form.provide_services = []
            this.form.region = ''
            this.form.xiaoshou = ''
            this.form.kefu = ''
            this.form.add_cause = ''
            this.form.add_cause_note = ''
        },
        copy_me() {
            const data  = {
                enterprise_name: this.form.enterprise_name,
                type: this.form.type,
                status: this.form.status,
                taxation: this.form.taxation,
                contacts: this.form.contacts,
                phone: this.form.phone,
                nature_of_business: this.form.nature_of_business,
                address: this.form.address,
                content: this.form.content,
                business_type: this.form.business_type,
                xiaoshou: this.form.xiaoshou,
                kefu: this.form.kefu,
                supervisor: this.form.supervisor,
                is_cooperation: this.form.is_cooperation,
                contract_date: this.form.contract_date,
                with_zhwl_enterprise: '',
                business_section: this.business_section,
                classify: this.form.classify,
                provide_services: this.form.provide_services,
                region: this.form.region,
                business_mark: this.form.business_mark,
                add_cause: this.form.add_cause,
                add_cause_note: this.form.add_cause_note,
                account_period_type: this.form.account_period_type,
                account_period_day: this.form.account_period_day,
                with_bank_card_rmb: this.form.with_bank_card_rmb,
                with_bank_card_rmb_account_name: this.form.with_bank_card_rmb_account_name,
                with_bank_card_rmb_account_bank: this.form.with_bank_card_rmb_account_bank,
                with_bank_card_rmb_account_card: this.form.with_bank_card_rmb_account_card,
                with_bank_card_usd: this.form.with_bank_card_usd,
                with_bank_card_usd_account_name: this.form.with_bank_card_usd_account_name,
                with_bank_card_usd_account_bank: this.form.with_bank_card_usd_account_bank,
                with_bank_card_usd_account_card: this.form.with_bank_card_usd_account_card,
                other: this.form.other,
                entrust: this.form.entrust,
                print: this.form.print
            }

            this.form = data
            this.$emit('addTeam')
        }
    },
    filters: {},
    props: {
        dialogFormVisible: Boolean,
        id: {
            type: Number,
            default() {
                return 0
            },
        },
        cooperative_enterprise_details_data: {
            type: Object,
            default() {
                return {}
            },
        },
        show_type: {
            type:Number,
            default() {
                return 0
            },
        },
        edit_auth: {
            type:Boolean,
            default() {
                return false
            }
        },
        del_auth: {
            type:Boolean,
            default() {
                return false
            }
        },
        add_file_auth: {
            type:Boolean,
            default() {
                return false
            }
        },
        to_approval_auth: {
            type:Boolean,
            default() {
                return false
            }
        },
        sel_approval_auth: {
            type:Boolean,
            default() {
                return false
            }
        },
        ApprovalAuth: {
            type:Boolean,
            default() {
                return false
            }
        },
        withdraw_auth: {
            type:Boolean,
            default() {
                return false
            }
        },
        print_auth: {
            type:String,
            default() {
                return ''
            }
        },
        AllType: {
            type:Array,
            default() {
                return []
            }
        },
        AllAccountPeriodType: {
            type:Array,
            default() {
                return []
            }
        },
        AllBusinessType: {
            type:Array,
            default() {
                return []
            }
        },
        AllStaff: {
            type:Array,
            default() {
                return []
            }
        },
        AllBankCard: {
            type:Array,
            default() {
                return []
            }
        },
        AllEnterprise: {
            type:Array,
            default() {
                return []
            }
        },
        AllProvideServices: {
            type:Array,
            default() {
                return []
            }
        },
        AllBusinessSection: {
            type:Array,
            default() {
                return []
            }
        },
        AllClassify: {
            type:Array,
            default() {
                return []
            }
        },
        add_auth: String
    },
    created() {},
    destoryed() {},
    mounted() {},
    updated() {},
    components: {
        CommonUploadComponent,
        CommonApprovalDialog,
        CooperativeEnterprisePrintComponent,
        BankCardEditComponent
    },
    watch: {
        cooperative_enterprise_details_data(newVal) {
            this.form = newVal
            this.form.id = this.id
            this.form.with_bank_card_rmb_account_name = newVal.with_bank_card_rmb_arr.user_name
            this.form.with_bank_card_rmb_account_bank = newVal.with_bank_card_rmb_arr.bank
            this.form.with_bank_card_rmb_account_card = newVal.with_bank_card_rmb_arr.card
            if(newVal.with_bank_card_usd) {
                this.form.with_bank_card_usd_account_name = newVal.with_bank_card_usd_arr.user_name
                this.form.with_bank_card_usd_account_bank = newVal.with_bank_card_usd_arr.bank
                this.form.with_bank_card_usd_account_card = newVal.with_bank_card_usd_arr.card
            }
        },
    },
}
</script>

<style scoped>
.typec,.business_typec,.statusc,.account_period_typec,.contract_datec,.is_cooperationc,.business_typec,.account_period_dayc,.with_bank_card_rmbc,.with_bank_card_usdc,.add_causec,.add_cause_notec,.supervisorc,.with_zhwl_enterprisec,.xiaoshouc,.kefuc {
    width: 100%;
}
.el-checkbox.is-bordered + .el-checkbox.is-bordered {
    margin-left: 0px;
}
</style>