import request from '../request'

//入职登记表列表
export const staff_entry_index_request = p => {
    return request({
        method:'GET',
        url:'staff_entry/index',
        params: p
    })
}

//发起入职登记表申请
export const staff_entry_add_request = d => {
    return request({
        method:'POST',
        url:'staff_entry/add',
        data: d
    })
}

//删除入职登记表申请
export const staff_entry_del_request = id => {
    return request({
        method:'DELETE',
        url:'staff_entry/del',
        data: {
            id: id
        }
    })
}

//修改入职登记表申请
export const staff_entry_edit_request = data => {
    return request({
        method:'PUT',
        url:'staff_entry/edit',
        data
    })
}

//获取入职登记表申请详情
export const staff_entry_details_request = id => {
    return request({
        method:'GET',
        url:'staff_entry/details',
        params: {
            id
        }
    })
}

// 提交入职登记表申请
export const staff_entry_to_approval_request = d => {
    return request({
        method:'PUT',
        url:'staff_entry/to_approval',
        data: {
            id:d.id,
            note:d.note
        }
    })
}

// 查看入职登记表申请审批记录
export const staff_entry_sel_approval_request = id => {
    return request({
        method:'GET',
        url:'staff_entry/sel_approval',
        params: {
            id
        }
    })
}

// 审批入职登记表申请
export const staff_entry_approval_request = d => {
    return request({
        method:'PUT',
        url:'staff_entry/approval',
        data: {
            id: d.id,
            opinion: d.opinion,
            note: d.note,
            other: d.other,
            go_staff_id: d.go_staff_id
        }
    })
}

// 撤回入职登记表申请
export const staff_entry_withdraw_request = d => {
    return request({
        method:'PUT',
        url:'staff_entry/withdraw',
        data: {
            id: d.id
        }
    })
}

// 打印入职登记表申请
export const staff_entry_print_request = id => {
    return request({
        method:'PUT',
        url:'staff_entry/print',
        data: {
            id
        }
    })
}

// 统计入职登记表申请
export const staff_entry_count_request = p => {
    return request({
        method:'GET',
        url:'staff_entry/count',
        params: p
    })
}

// 补充入职登记表申请附件
export const staff_entry_add_file_request = data => {
    return request({
        method:'PUT',
        url:'staff_entry/add_file',
        data
    })
}