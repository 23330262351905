<template>
    <el-card class="box-card">
        <!-- 表单只有一个文本框的时候，按回车会自动submit表单，需要禁用sunmit -->
        <el-form
            ref="searchform"
            :inline="true"
            :model="form"
            @submit.native.prevent
            size="medium"
        >
            <el-form-item label="申请类型" prop="examine_type">
                <el-select
                    v-model="form.examine_type"
                    placeholder="选择类型"
                    class="examine_typec"
                    filterable
                    clearable
                >
                    <el-option
                        v-for="examine_type in all_examine_type"
                        :key="examine_type.name"
                        :label="examine_type.name"
                        :value="examine_type.id"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="单号" prop="name">
                <el-input
                    v-model="form.name"
                    placeholder="单号搜索"
                    class="namec"
                    @keyup.enter.native="search()"
                    clearable
                ></el-input>
            </el-form-item>
            <el-form-item label="金额" prop="money">
                <el-input
                    v-model="form.money"
                    placeholder="金额搜索"
                    class="moneyc"
                    @keyup.enter.native="search()"
                    clearable
                ></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" plain @click="search">搜索</el-button>
                <el-button type="warning" plain @click="resetbtn('searchform')"
                    >重置</el-button
                >
            </el-form-item>
        </el-form>
    </el-card>
</template>

<script>
export default {
    name: 'MyApplySearchBarComponent',
    data() {
        return {
            form: {
                examine_type: '',
                name: '',
                money: '',
                page: 1,
            }
        }
    },
    computed: {
        all_examine_type() {
            return this.$store.state.examine_type
        }
    },
    methods: {
        search() {
            this.$emit('search', this.form)
        },
        resetbtn(f) {
            this.$refs[f].resetFields()
        }
    },
    filters: {},
    props: {},
    created() {},
    destoryed() {},
    mounted() {},
    updated() {},
    components: {},
    watch: {},
}
</script>