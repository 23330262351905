<template>
    <div>
        <el-dialog 
            :visible.sync="RemindDialogVisible"
            :append-to-body="true"
            width="50%"
            :center="true"
            top="10vh"
            :close-on-click-modal="false"
            :before-close="() => closeRemindDialog()"
            v-loading.fullscreen.lock="login_loading"
        >
            <div>
                <el-form :model="form" :rules="rules" ref="RemindForm" label-width="100px" size="small">
                    <fieldset class="fieldsetc bottomc">
                        <legend class="legendc">续签状态标记</legend>
                        <el-form-item label="选择状态" prop="remind_state">
                            <el-radio-group v-model="form.remind_state" size="medium" :fill="opinion_color" @change="remind_state_change">
                                <el-radio-button label="已续签"></el-radio-button>
                                <el-radio-button label="不续签"></el-radio-button>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="不签原因" v-if="form.remind_state === '不续签'">
                            <el-input v-model="form.remind_note" clearable></el-input>
                        </el-form-item>
                    </fieldset>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" plain @click="submitRemindDialog()">确定</el-button>
                <el-button plain @click="closeRemindDialog()">关闭</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { contract_remind_request } from '@/network/finance/Contract.js'

export default {
    name:'',
    data(){
        return {
            login_loading: false,
            form: {
                remind_state: '',
                remind_note: ''
            },
            rules: {
                remind_state: [
                    {
                        required: true,
                        message: '请选择状态',
                        trigger: 'blur',
                    }
                ]
            }
        }
    },
    computed:{
        opinion_color() {
            if(this.form.remind_state === '已续签') {
                return '#00DB00'
            }
            if(this.form.remind_state === '不续签') {
                return '#FF0000'
            }
        }
    },
    methods:{
        closeRemindDialog() {
            this.form = {
                remind_state: '',
                remind_note: ''
            }
            this.$emit('exitRemindDialog')
        },
        submitRemindDialog() {
            if(this.form.remind_state === '') {
                this.$message.error('请选择状态')
                return
            }

            if((this.form.remind_state === '不续签') && (this.form.remind_note === '')) {
                this.$message.error('请填写不签原因')
                return
            }

            this.login_loading = true
            this.form.id = this.id
            contract_remind_request(this.form)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.$message.success('标记成功!')
                        this.$emit('details_row',this.form.id)
                        this.closeRemindDialog()
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        remind_state_change() {
            this.form.remind_note = ''
        }
    },
    filters:{},
    props:{
        id: {
            type: Number,
            default() {
                return 0
            }
        },
        RemindDialogVisible: {
            type: Boolean,
            default() {
                return false
            }
        }
    },
    created(){},
    destoryed(){},
    mounted(){},
    updated(){},
    components:{},
    watch:{}
}
</script>

<style>
</style>