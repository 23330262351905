<template>
    <div>
        <el-dialog
            :title="dialogFormTitle"
            :visible.sync="dialogFormVisible"
            :append-to-body="true"
            width="30%"
            :center="true"
            top="20vh"
            :close-on-click-modal="false"
            :before-close="() => closeBankCardDialog()"
            v-loading.fullscreen.lock="login_loading"
        >
            <div>
                <el-form
                    :model="BankCard"
                    :rules="rules"
                    ref="BankCardEditForm"
                    label-width="100px"
                    size="small"
                >
                    <div v-if="show_type">
                        <el-form-item label="户主" prop="user_name">
                            <el-input
                                v-model="BankCard.user_name"
                                clearable
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="卡号" prop="card">
                            <el-input
                                v-model="BankCard.card"
                                clearable
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="开户行" prop="bank">
                            <el-input
                                v-model="BankCard.bank"
                                clearable
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="类型" prop="bank">
                            <el-radio-group v-model="BankCard.type" size="medium" :fill="type_color">
                                <el-radio-button :label="0">私有</el-radio-button>
                                <el-radio-button :label="1">公共</el-radio-button>
                            </el-radio-group>
                        </el-form-item>
                        
                    </div>
                    <div v-else>
                        <el-descriptions
                            class="margin-top"
                            :column="1"
                            :labelStyle="{'width':'120px','text-align':'center'}"
                            :contentStyle="{'text-align': 'center'}"
                            border
                        >
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>户主</span>
                                </template>
                                <span>{{BankCard.user_name}}</span>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>银行卡号</span>
                                </template>
                                <span>{{BankCard.card}}</span>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>开户行</span>
                                </template>
                                <span>{{BankCard.bank}}</span>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>类型</span>
                                </template>
                                <span>{{BankCard.type | type_name}}</span>
                            </el-descriptions-item>
                        </el-descriptions>
                    </div>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer">
                <div v-if="show_type===0">
                    <el-button
                        v-if="$_has(edit_auth)"
                        type="primary"
                        plain
                        @click="$emit('show_edit')"
                        >修改</el-button
                    >
                    <el-button
                        v-if="$_has(del_auth)"
                        type="danger"
                        plain
                        @click="BankCardDel('BankCardEditForm')"
                        >删除</el-button
                    >
                    <el-button
                        plain
                        @click="closeBankCardDialog()"
                        >关闭</el-button
                    >
                </div>
                <div v-else-if="show_type===1">
                    <el-button
                        type="success"
                        plain
                        @click="submitInsert('BankCardEditForm')"
                        >确定</el-button
                    >
                    <el-button
                        type="warning"
                        plain
                        @click="resetForm()"
                        >重置</el-button
                    >
                    <el-button
                        plain
                        @click="closeBankCardDialog()"
                        >关闭</el-button
                    >
                </div>
                <div v-else>
                    <el-button
                        type="success"
                        plain
                        @click="submitEdit('BankCardEditForm')"
                        >确定修改</el-button
                    >
                    <el-button
                        plain
                        @click="closeBankCardDialog()"
                        >取消操作</el-button
                    >
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { bank_card_add_request,bank_card_del_request,bank_card_edit_request } from '@/network/finance/BankCard.js'

export default {
    name: 'BankCardEditComponent',
    data() {
        return {
            login_loading: false,
            BankCard: {
                user_name: '',
                card: '',
                bank: '',
                type: ''
            },
            rules: {
                user_name: [
                    {
                        required: true,
                        message: '户主必填',
                        trigger: 'blur',
                    }
                ],
                card: [
                    {
                        required: true,
                        message: '卡号必填',
                        trigger: 'blur',
                    }
                ],
                bank: [
                    {
                        required: true,
                        message: '开户行必填',
                        trigger: 'blur',
                    }
                ]
            }
        }
    },
    computed: {
        dialogFormTitle() {
            if(this.show_type === 0) {
                return '银行卡详情'
            } else if (this.show_type === 1) {
                return '添加银行卡'
            } else {
                return '修改银行卡'
            }
        },
        type_color() {
            if(this.BankCard.type === 0) {
                return '#67c23a'
            }
            if(this.BankCard.type === 1) {
                return '#409eff;'
            }
            
            return '#FFFFFF'
        }
    },
    methods: {
        closeBankCardDialog() {
            this.resetForm()
            this.$emit('exitBankCardDialog')
        },
        submitInsert(formName) {
            this.$refs[formName].validate((valid) => {
                if (!valid) {
                    return
                }
                this.BankCardInsert(formName)
            })
        },
        BankCardInsert(formName) {
            this.login_loading = true
            bank_card_add_request(this.BankCard)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.$message.success('添加成功!')
                        this.resetForm()
                        this.$emit('exitBankCardDialog')
                        this.$emit('search')
                        this.$emit('details_row',s.result.id*1)
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        resetForm() {
            this.BankCard = {
                user_name: '',
                card: '',
                bank: '',
                type: ''
            }
        },
        submitEdit(formName) {
            this.$refs[formName].validate((valid) => {
                if (!valid) {
                    return
                }
                this.BankCardEdit(formName)
            })
        },
        BankCardEdit(formName) {
            this.login_loading = true
            this.BankCard.id = this.id
            bank_card_edit_request(this.BankCard)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.$message.success('修改成功!')
                        this.$emit('exitBankCardDialog')
                        this.$emit('search')
                        this.$emit('details_row',this.BankCard.id)
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        BankCardDel(formName) {
            this.$confirm(
                '是否确定删除？（一经删除，将无法再进行恢复，请谨慎操作！）',
                '温馨提示',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            )
                .then(() => {
                    bank_card_del_request(this.id)
                        .then((s) => {
                            if (s.status === 0) {
                                this.$message.success('删除成功!')
                                this.$emit('search')
                            } else {
                                this.$message.error(s.msg)
                            }
                        })
                        .catch((err) => {
                            this.$message.error(err)
                        })
                        .finally(() => this.closeBankCardDialog())
                })
                .catch((err) => {})
        },
    },
    filters: {
        type_name(v) {
            return v===0 ? '私有' : (v===1 ? '公共' : '')
        }
    },
    props: {
        dialogFormVisible: Boolean,
        id: {
            type: Number,
            default() {
                return 0
            },
        },
        Allstaff: {
            type: Array,
            default() {
                return []
            },
        },
        bank_card_details_data: {
            type: Object,
            default() {
                return {}
            },
        },
        show_type: {
            type:Number,
            default() {
                return 0
            },
        },
        edit_auth: String,
        del_auth: String
    },
    created() {},
    destoryed() {},
    mounted() {},
    updated() {},
    components: {},
    watch: {
        bank_card_details_data(newVal) {
            this.BankCard = newVal
        },
    },
}
</script>

<style lang='less'>
.staff_idc {
    width: 100%;
}
</style>