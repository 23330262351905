<template>
    <div>
        <el-dialog 
            :visible.sync="dialogFormVisible"
            :append-to-body="true"
            width="60%"
            :center="true"
            top="2vh"
            :close-on-click-modal="false"
            :before-close="() => closeContractDialog('ContractEditForm')"
            v-loading.fullscreen.lock="login_loading"
        >
            <template #title>
                <span style="font-weight: bold;">{{dialogFormTitle}}</span>
                <span>&nbsp;</span>
                <el-tag v-if="contract.state===0" type="info" size="mini">待提交</el-tag>
                <el-tag v-if="contract.state===1" size="mini">审批中</el-tag>
                <el-tag v-if="contract.state===2" type="success" size="mini">已通过</el-tag>
                <el-tag v-if="contract.state===3" type="danger" size="mini">已驳回</el-tag>
                <el-button
                    v-if="(contract.state===2) && ($_has(print_auth))"
                    plain
                    circle
                    icon="el-icon-printer"
                    @click="clk_print()"
                ></el-button>
                <div style="font-size: 13px;font-weight: bold;">
                    <span style="color:#2828FF;">续签状态：</span>
                    <el-tag effect="plain" size="mini" type="info" v-if="!contract.remind">【待定】</el-tag>
                    <el-tag effect="plain" size="mini" type="success" v-else-if="contract.remind === '已续签'">【已续签】</el-tag>
                    <el-tag effect="plain" size="mini" type="danger" v-else>{{ '【不续签】' + contract.remind }}</el-tag>
                </div>
            </template>
            <div>
                <el-form :model="contract" :rules="rules" ref="ContractEditForm" label-width="100px" size="small">
                    <div v-if="show_type">
                        <fieldset class="fieldsetc">
                            <legend class="legendc">基础信息</legend>
                            <el-form-item label="合同标题" prop="title">
                                <el-input v-model="contract.title" clearable placeholder="请严格按照纸质合同中的合同标题填写"></el-input>
                            </el-form-item>
                            <el-row :gutter="10">
                                <el-col :span="12">
                                    <el-form-item label="我方企业" prop="enterprise_id" >
                                        <el-select
                                            v-model="contract.enterprise_id"
                                            placeholder="选择我方企业"
                                            class="enterprise_idc"
                                            @change="my_enterprise_change"
                                            filterable
                                            clearable
                                        >
                                            <el-option
                                                v-for="enterprise in AllEnterprise"
                                                :key="enterprise.id"
                                                :label="enterprise.name"
                                                :value="enterprise.id"
                                            ></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="负责人" prop="staff_id">
                                        <el-select
                                            v-model="contract.staff_id"
                                            filterable
                                            clearable
                                            placeholder="选择员工"
                                            class="staff_idc"
                                        >
                                            <el-option
                                                v-for="item in AllStaff"
                                                :key="item.id"
                                                :label="item.name"
                                                :value="item.id"
                                            >
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row :gutter="10">
                                <el-col :span="12">
                                    <el-form-item label="主体数量" prop="subject_num" >
                                        <el-select
                                            v-model="contract.subject_num"
                                            placeholder="选择主体数量"
                                            class="subject_numc"
                                            filterable
                                            clearable
                                        >
                                            <el-option v-for="(v,i) in AllSubjectNum" :key="i" :label="v" :value="i"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="紧急状态" prop="urgent_state" >
                                        <el-select
                                            v-model="contract.urgent_state"
                                            placeholder="选择紧急状态"
                                            class="urgent_statec"
                                            filterable
                                            clearable
                                        >
                                            <el-option v-for="(v,i) in AllUrgentState" :key="i" :label="v" :value="i"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row :gutter="10">
                                <el-col :span="12">
                                    <el-form-item label="合同类型" prop="contract_type" >
                                        <el-select
                                            v-model="contract.contract_type"
                                            placeholder="选择合同类型"
                                            class="contract_typec"
                                            filterable
                                            clearable
                                        >
                                            <el-option v-for="(v,i) in AllContractType" :key="i" :label="v" :value="i"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="业务类型" prop="business_type">
                                        <el-select
                                            v-model="contract.business_type"
                                            placeholder="选择业务类型"
                                            class="business_typec"
                                            filterable
                                            clearable
                                        >
                                            <el-option v-for="(v,i) in AllBusinessType" :key="i" :label="v" :value="i"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-form-item label="到期选项" prop="expire_option">
                                <el-radio-group v-model="contract.expire_option">
                                    <el-radio
                                        v-for="(v, i) in ['到期终止','顺延一年','自动顺延']"
                                        :label="i"
                                        :key="i"
                                        >{{ v }}</el-radio
                                    >
                                </el-radio-group>
                            </el-form-item>
                        </fieldset>
                        <fieldset class="fieldsetc">
                            <legend class="legendc">企业信息</legend>
                            <el-form-item label="甲方企业" prop="company_a_id" >
                                <el-select
                                    v-model="contract.company_a_id"
                                    placeholder="选择甲方企业"
                                    class="enterprise_idc"
                                    @change="company_a_change"
                                    filterable
                                    clearable
                                >
                                    <el-option
                                        v-for="client in AllClient"
                                        :key="client.id"
                                        :label="client.name"
                                        :value="client.id"
                                    ></el-option>
                                </el-select>
                            </el-form-item>
                            <el-row :gutter="10">
                                <el-col :span="12">
                                    <el-form-item label="甲方联系人" prop="company_a_person">
                                        <el-input v-model="contract.company_a_person"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="甲方电话" prop="company_a_phone">
                                        <el-input v-model="contract.company_a_phone"></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-form-item label="乙方企业" prop="company_b_id" >
                                <el-select
                                    v-model="contract.company_b_id"
                                    placeholder="选择乙方企业"
                                    class="enterprise_idc"
                                    @change="company_b_change"
                                    filterable
                                    clearable
                                >
                                    <el-option
                                        v-for="client in AllClient"
                                        :key="client.id"
                                        :label="client.name"
                                        :value="client.id"
                                    ></el-option>
                                </el-select>
                            </el-form-item>
                            <el-row :gutter="10">
                                <el-col :span="12">
                                    <el-form-item label="乙方联系人" prop="company_b_person">
                                        <el-input v-model="contract.company_b_person"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="乙方电话" prop="company_b_phone">
                                        <el-input v-model="contract.company_b_phone"></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <div v-if="contract.subject_num>0">
                                <el-form-item label="丙方企业" prop="company_c_id" >
                                    <el-select
                                        v-model="contract.company_c_id"
                                        placeholder="选择丙方企业"
                                        class="enterprise_idc"
                                        @change="company_c_change"
                                        filterable
                                        clearable
                                    >
                                        <el-option
                                            v-for="client in AllClient"
                                            :key="client.id"
                                            :label="client.name"
                                            :value="client.id"
                                        ></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-row :gutter="10">
                                    <el-col :span="12">
                                        <el-form-item label="丙方联系人" prop="company_c_person">
                                            <el-input v-model="contract.company_c_person"></el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="12">
                                        <el-form-item label="丙方电话" prop="company_c_phone">
                                            <el-input v-model="contract.company_c_phone"></el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                            </div>
                            <div v-if="contract.subject_num===2">
                                <el-form-item label="丁方企业" prop="company_d_id" >
                                    <el-select
                                        v-model="contract.company_d_id"
                                        placeholder="选择丁方企业"
                                        class="enterprise_idc"
                                        @change="company_d_change"
                                        filterable
                                        clearable
                                    >
                                        <el-option
                                            v-for="client in AllClient"
                                            :key="client.id"
                                            :label="client.name"
                                            :value="client.id"
                                        ></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-row :gutter="10">
                                    <el-col :span="12">
                                        <el-form-item label="丁方联系人" prop="company_d_person">
                                            <el-input v-model="contract.company_d_person"></el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="12">
                                        <el-form-item label="丁方电话" prop="company_d_phone">
                                            <el-input v-model="contract.company_d_phone"></el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                            </div>
                        </fieldset>
                        <fieldset class="fieldsetc">
                            <legend class="legendc">结算信息</legend>
                            <el-row :gutter="10">
                                <el-col :span="12">
                                    <el-form-item label="开始日期" prop="start_date">
                                        <el-date-picker
                                            v-model="contract.start_date"
                                            align="right"
                                            type="date"
                                            placeholder="选择日期"
                                            value-format="yyyy-MM-dd"
                                            class="contract_datec"
                                        >
                                        </el-date-picker>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="结束日期" prop="end_date">
                                        <el-date-picker
                                            v-model="contract.end_date"
                                            align="right"
                                            type="date"
                                            placeholder="选择日期"
                                            value-format="yyyy-MM-dd"
                                            class="contract_datec"
                                        >
                                        </el-date-picker>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row :gutter="10">
                                <el-col :span="12">
                                    <el-form-item label="发票类型" prop="invoice_type">
                                        <el-select
                                            v-model="contract.invoice_type"
                                            placeholder="选择发票类型"
                                            multiple
                                            :multiple-limit=3
                                            class="invoice_typec"
                                            filterable
                                            clearable
                                        >
                                            <el-option v-for="(v,i) in AllInvoiceType" :key="i" :label="v" :value="i"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="结算币种" prop="settlement_currency">
                                        <el-select
                                            v-model="contract.settlement_currency"
                                            placeholder="选择币种"
                                            multiple
                                            :multiple-limit=3
                                            class="settlement_currencyc"
                                            filterable
                                            clearable
                                        >
                                            <el-option v-for="(item,i) in settlement_type" :key="i" :label="item" :value="i"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-form-item label="结算方式" prop="settlement_mode">
                                <el-select
                                    v-model="contract.settlement_mode"
                                    class="settlement_modec"
                                    filterable
                                    clearable
                                >
                                    <el-option v-for="(item,i) in AllSettlementMode" :key="i" :label="item" :value="i"></el-option>
                                </el-select>
                                <el-input-number
                                    v-model="contract.settlement_day_num"
                                    :min="0"
                                    :max="999"
                                    :step="1"
                                    :precision="0"
                                    :step-strictly="true"
                                    size="small"
                                    class="settlement_day_numc"
                                ></el-input-number>
                                <el-select
                                    v-model="contract.settlement_day_type"
                                    class="settlement_day_typec"
                                    filterable
                                    clearable
                                >
                                    <el-option v-for="(item,i) in AllSettlementDayType" :key="i" :label="item" :value="i"></el-option>
                                </el-select>
                                <span style='color: #FF0000;font-size: 13px;'>※ 此项将作为集团财务收付款重要依据，请谨慎填写</span>
                            </el-form-item>
                            <el-row :gutter="10">
                                <el-col :span="12">
                                    <el-form-item label="结算周期" prop="settlement_cycle">
                                        <el-select
                                            v-model="contract.settlement_cycle"
                                            filterable
                                            clearable
                                            placeholder="选择结算周期"
                                            class="settlement_cyclec"
                                        >
                                            <el-option v-for="(item,i) in AllSettlementCycle" :key="i" :label="item" :value="i" ></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="支付方式" prop="pay_way">
                                        <el-select
                                            v-model="contract.pay_way"
                                            placeholder="选择支付方式"
                                            multiple
                                            :multiple-limit="3"
                                            class="pay_wayc"
                                        >
                                            <el-option
                                                v-for="(item,i) in pay_type"
                                                :key="i"
                                                :label="item"
                                                :value="i"
                                            >
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-form-item label="赔付风险">
                                <el-input type="textarea" :rows="3" v-model="contract.risk"></el-input>
                            </el-form-item>
                            <el-form-item label="合同备注">
                                <el-input type="textarea" :rows="3" v-model="contract.contract_note"></el-input>
                            </el-form-item>
                        </fieldset>
                        <common-upload-component
                            ref="contract_upload"
                            :ffileList="contract.contract_other"
                            upload_url="contract/contract_upload"
                        ></common-upload-component>
                    </div>
                    <div v-else>
                        <fieldset class="fieldsetc">
                            <legend class="legendc">基础信息</legend>
                            <el-descriptions
                                class="margin-top" 
                                :column="2"
                                :labelStyle="{'width':'120px','text-align':'center'}"
                                :contentStyle="{'text-align': 'center'}"
                                border
                            >
                                <el-descriptions-item :span="2">
                                    <template slot="label">
                                        <span>合同标题</span>
                                    </template>
                                    <span>{{contract.title}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>我方企业</span>
                                    </template>
                                    <span>{{contract.enterprise_name}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>负责人</span>
                                    </template>
                                    <span>{{contract.staff_name}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>主体数量</span>
                                    </template>
                                    <span>{{AllSubjectNum[contract.subject_num]}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>紧急状态</span>
                                    </template>
                                    <el-tag v-if="contract.urgent_state===0" type="info" effect="plain">一般</el-tag>
                                    <el-tag v-if="contract.urgent_state===1" type="warning" effect="plain">紧急</el-tag>
                                    <el-tag v-if="contract.urgent_state===2" type="danger" effect="plain">特急</el-tag>
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>合同类型</span>
                                    </template>
                                    <span>{{AllContractType[contract.contract_type]}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>业务类型</span>
                                    </template>
                                    <span>{{AllBusinessType[contract.business_type]}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>到期选项</span>
                                    </template>
                                    <span>{{contract.expire_option_name}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>履行状态</span>
                                    </template>
                                    <el-tag v-if="contract.perform_state===0" type="info" effect="plain">待定</el-tag>
                                    <el-tag v-else-if="contract.perform_state===1" type="success" effect="plain">正常</el-tag>
                                    <el-tag v-else-if="contract.perform_state===2" type="warning" effect="plain">过期</el-tag>
                                    <el-tag v-else-if="contract.perform_state===3" type="danger" effect="plain">否决</el-tag>
                                    <el-tag v-else-if="contract.perform_state===4" type="danger" effect="plain">作废</el-tag>
                                </el-descriptions-item>
                            </el-descriptions>
                        </fieldset>
                        <fieldset class="fieldsetc bottomc">
                            <legend class="legendc">企业信息</legend>
                            <el-descriptions
                                class="margin-top"
                                :column="2"
                                :labelStyle="{'width':'120px','text-align':'center'}"
                                :contentStyle="{'text-align': 'center'}"
                                border
                            >
                                <el-descriptions-item :span="2">
                                    <template slot="label">
                                        <span>甲方企业</span>
                                    </template>
                                    <span>{{contract.company_a_name}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>甲方联系人</span>
                                    </template>
                                    <span>{{contract.company_a_person}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>甲方电话</span>
                                    </template>
                                    <span>{{contract.company_a_phone}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item :span="2">
                                    <template slot="label">
                                        <span>乙方企业</span>
                                    </template>
                                    <span>{{contract.company_b_name}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>乙方联系人</span>
                                    </template>
                                    <span>{{contract.company_b_person}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>乙方电话</span>
                                    </template>
                                    <span>{{contract.company_b_phone}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item :span="2" v-if="contract.subject_num>0">
                                    <template slot="label">
                                        <span>丙方企业</span>
                                    </template>
                                    <span>{{contract.company_c_name}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item  v-if="contract.subject_num>0">
                                    <template slot="label">
                                        <span>丙方联系人</span>
                                    </template>
                                    <span>{{contract.company_c_person}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item  v-if="contract.subject_num>0">
                                    <template slot="label">
                                        <span>丙方电话</span>
                                    </template>
                                    <span>{{contract.company_c_phone}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item :span="2"  v-if="contract.subject_num===2">
                                    <template slot="label">
                                        <span>丁方企业</span>
                                    </template>
                                    <span>{{contract.company_d_name}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item  v-if="contract.subject_num===2">
                                    <template slot="label">
                                        <span>丁方联系人</span>
                                    </template>
                                    <span>{{contract.company_d_person}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item  v-if="contract.subject_num===2">
                                    <template slot="label">
                                        <span>丁方电话</span>
                                    </template>
                                    <span>{{contract.company_d_phone}}</span>
                                </el-descriptions-item>
                            </el-descriptions>
                        </fieldset>
                        <fieldset class="fieldsetc bottomc">
                            <legend class="legendc">结算信息</legend>
                            <el-descriptions
                                class="margin-top"
                                :column="2"
                                :labelStyle="{'width':'120px','text-align':'center'}"
                                :contentStyle="{'text-align': 'center'}"
                                border
                            >
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>合同开始日期</span>
                                    </template>
                                    <span>{{contract.start_date}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>合同结束日期</span>
                                    </template>
                                    <span>{{contract.end_date}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>发票类型</span>
                                    </template>
                                    <span>{{contract.invoice_type_name}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>结算币种</span>
                                    </template>
                                    <span>{{contract.settlement_currency_name}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item :span="2">
                                    <template slot="label">
                                        <span>结算方式</span>
                                    </template>
                                    <span>{{settlement_mode_name}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>结算周期</span>
                                    </template>
                                    <span>{{AllSettlementCycle[contract.settlement_cycle]}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>支付方式</span>
                                    </template>
                                    <span>{{contract.pay_way_name}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item :span="2" :contentStyle="{'text-align':'left','white-space':'pre-line'}">
                                    <template slot="label">
                                        <span>赔付风险</span>
                                    </template>
                                    <span>{{contract.risk}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item :span="2" :contentStyle="{'text-align':'left','white-space':'pre-line'}">
                                    <template slot="label">
                                        <span>合同备注</span>
                                    </template>
                                    <span>{{contract.contract_note}}</span>
                                </el-descriptions-item>
                            </el-descriptions>
                        </fieldset>
                        <common-upload-component
                            v-show="contract.contract_other.length"
                            ref="contract_upload"
                            :ffileList="contract.contract_other"
                            upload_url="contract/contract_upload"
                            :disabled="true"
                        ></common-upload-component>
                        <common-upload-component
                            v-show="AddFileDialogVisible"
                            ref="contract_file_upload"
                            :ffileList="file_arr"
                            upload_url="contract/contract_upload"
                            title="补充附件"
                        ></common-upload-component>
                    </div>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer">
                <div v-if="show_type===0">
                    <div v-if="AddFileDialogVisible === false">
                        <el-button v-if="$_has(add_auth) && (contract.state === 2)" type="primary" plain @click="Renewal">续签</el-button>
                        <el-button v-if="add_file_auth" type="success" plain @click="add_file()">补充附件</el-button>
                        <el-button v-if="to_approval_auth" type="success" plain @click="to_approval('ContractEditForm')">提交</el-button>
                        <el-button v-if="sel_approval_auth" type="warning" plain @click="sel_approval()">查看审批</el-button>
                        <el-button v-if="withdraw_auth" type="danger" plain @click="contractWithdraw('PurchaseEditForm')">审批撤回</el-button>
                        <el-button v-if="edit_auth" type="primary" plain @click="$emit('show_edit')">修改</el-button>
                        <el-button v-if="del_auth" type="danger" plain @click="contractDel('ContractEditForm')">删除</el-button>
                        <el-button v-if="record_add_auth" type="success" plain @click="record()">备案</el-button>
                        <el-button v-if="record_details_auth" type="primary" plain @click="record_sel()">查看备案</el-button>
                        <el-button v-if="cancel_auth" type="danger" plain @click="cancel_contract()">作废</el-button>
                        <el-button v-if="$_has('合同续签状态标记') && (contract.state === 2) && (!contract.remind)" type="primary" plain @click="Remind">续签状态标记</el-button>
                        <el-button plain @click="closeContractDialog('ContractEditForm')">关闭</el-button>
                    </div>
                    <div v-else>
                        <el-button type="success" plain @click="submit_add_file()">确定</el-button>
                        <el-button plain @click="exit_add_file()">取消</el-button>
                    </div>
                </div>
                <div v-else-if="show_type===1">
                    <el-button type="success" plain @click="submitInsert('ContractEditForm')">确定</el-button>
                    <el-button type="warning" plain @click="resetForm('ContractEditForm')">重置</el-button>
                    <el-button plain @click="closeContractDialog('ContractEditForm')">关闭</el-button>
                </div>
                <div v-else>
                    <el-button type="success" plain @click="submitEdit('ContractEditForm')">确定修改</el-button>
                    <el-button plain @click="closeContractDialog('ContractEditForm')">取消操作</el-button>
                </div>
                <common-approval-dialog
                    :id="id"
                    :ApprovalDialogVisible="ApprovalDialogVisible"
                    :data="ApprovalData"
                    :current_examine_staff_id="contract.current_examine_staff_id"
                    :ApprovalAuth="ApprovalAuth"
                    :ApprovalRequest="ContractApprovalRequest"
                    @exitApprovalDialog="exitApprovalDialog"
                    @refresh="$emit('frefresh')"
                >
                </common-approval-dialog>
                <contract-recird-component
                    :id="id"
                    :RecordDialogVisible="RecordDialogVisible"
                    :RecordDialogType="RecordDialogType"
                    :record_edit_auth="record_edit_auth"
                    :record_del_auth="record_del_auth"
                    :contract_record_details_data="contract_record_details_data"
                    :contract_record_history_data="contract_record_history_data"
                    @edit_recird="edit_recird"
                    @exitRecirdDialog="exitRecirdDialog"
                    @refresh_details_index="refresh_details_index"
                ></contract-recird-component>
                <contract-print-component
                    :show_print="show_print"
                    :data="contract"
                    :ApprovalData="ApprovalData"
                    :AllUrgentState="AllUrgentState"
                    :AllBusinessType="AllBusinessType"
                    :AllContractType="AllContractType"
                    :AllSubjectNum="AllSubjectNum"
                    :AllSettlementMode="AllSettlementMode"
                    :AllSettlementDayType="AllSettlementDayType"
                    :AllSettlementCycle="AllSettlementCycle"
                    @exit_print="show_print = false"
                >
                </contract-print-component>
                <contract-remind-component
                    :id="id"
                    :RemindDialogVisible="RemindDialogVisible"
                    @exitRemindDialog="exitRemindDialog"
                    @details_row="jieshou_details_row"
                ></contract-remind-component>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import CommonUploadComponent from '@/components/common/CommonUploadComponent'
import CommonApprovalDialog from '@/components/common/CommonApprovalDialog'
import ContractPrintComponent from './ContractPrintComponent'
import ContractRecirdComponent from './ContractRecirdComponent'
import ContractRemindComponent from './ContractRemindComponent'

import {
    contract_add_request,
    contract_del_request,
    contract_edit_request,
    contract_to_approval_request,
    contract_sel_approval_request,
    contract_approval_request,
    contract_withdraw_request,
    contract_record_details_request,
    contract_record_history_request,
    contract_add_file_request,
    contract_cancel_request
} from '@/network/finance/Contract.js'

import { cooperative_enterprise_get_file_request } from '@/network/business/CooperativeEnterprise.js'
import { staff_list_request,client_list_request } from '@/network/list.js'

export default {
    name: 'ContractEditComponent',
    data() {
        return {
            login_loading: false,
            ApprovalDialogVisible: false,
            RecordDialogVisible: false,
            RecordDialogType: 0,
            contract: {
                urgent_state: '',
                title: '',
                enterprise_id: '',
                contract_type:'',
                business_type:'',
                subject_num: '',
                company_a_id:'',
                company_a_person:'',
                company_a_phone:'',
                company_b_id:'',
                company_b_person:'',
                company_b_phone:'',
                company_c_id:'',
                company_c_person:'',
                company_c_phone:'',
                company_d_id:'',
                company_d_person:'',
                company_d_phone:'',
                start_date:'',
                end_date:'',
                invoice_type:'',
                settlement_currency:'',
                settlement_mode:'',
                settlement_day_num:'',
                settlement_day_type:'',
                settlement_cycle:'',
                pay_way:'',
                staff_id:this.$store.state.user.id,
                risk:'',
                contract_note:'',
                contract_other:[],             
                print: []
            },
            rules: {
                urgent_state: [
                    {
                        required: true,
                        message: '请选择紧急状态',
                        trigger: 'blur',
                    },
                ],
                title: [
                    {
                        required: true,
                        message: '请输入合同标题',
                        trigger: 'blur',
                    },
                ],
                enterprise_id: [
                    {
                        required: true,
                        message: '缺少我方企业',
                        trigger: 'blur',
                    },
                ],
                subject_num: [
                    {
                        required: true,
                        message: '请选择主体数量',
                        trigger: 'blur',
                    },
                ],
                contract_type: [
                    {
                        required: true,
                        message: '请选择合同类型',
                        trigger: 'blur',
                    },
                ],
                business_type: [
                    {
                        required: true,
                        message: '请选择业务类型',
                        trigger: 'blur',
                    },
                ],
                staff_id: [
                    {
                        required: true,
                        message: '负责人',
                        trigger: 'blur',
                    },
                ],                
                company_a_id: [
                    {
                        required: true,
                        message: '请选择甲方企业',
                        trigger: 'blur',
                    },
                ],
                company_a_person: [
                    {
                        required: true,
                        message: '请输入甲方联系人',
                        trigger: 'blur',
                    },
                ],
                company_a_phone: [
                    {
                        required: true,
                        message: '请输入甲电话',
                        trigger: 'blur',
                    },
                ],
                company_b_id: [
                    {
                        required: true,
                        message: '请选择乙方企业',
                        trigger: 'blur',
                    },
                ],
                company_b_person: [
                    {
                        required: true,
                        message: '请输入乙方联系人',
                        trigger: 'blur',
                    },
                ],
                company_b_phone: [
                    {
                        required: true,
                        message: '请输入乙电话',
                        trigger: 'blur',
                    },
                ],
                start_date: [
                    {
                        required: true,
                        message: '请选择合同开始时间',
                        trigger: 'blur',
                    },
                ],
                end_date: [
                    {
                        required: true,
                        message: '请选择合同结束时间',
                        trigger: 'blur',
                    },
                ],
                invoice_type: [
                    {
                        required: true,
                        message: '请选择发票类型',
                        trigger: 'blur',
                    },
                ],
                settlement_currency: [
                    {
                        required: true,
                        message: '请选择结算币种',
                        trigger: 'blur',
                    },
                ],
                settlement_mode: [
                    {
                        required: true,
                        message: '请填写结算方式',
                        trigger: 'blur',
                    },
                ],
                pay_way: [
                    {
                        required: true,
                        message: '请选择支付方式',
                        trigger: 'blur',
                    },
                ]
            },
            pay_type: ['现金支付','银行承兑','商业承兑','转账汇款'],
            settlement_type:['人民币','美元','欧元','日元'],
            AllInvoiceType: ['普通增值税发票','专用增值税发票','商业发票','财务收据'],
            AllStaff: [],
            AllClient: [],
            ApprovalData: [],
            ContractApprovalRequest:contract_approval_request,
            show_print: false,
            contract_record_details_data: {},
            contract_record_history_data: [],
            AddFileDialogVisible: false,
            //补充附件
            file_arr: [],
            RemindDialogVisible: false
        }
    },
    computed: {
        dialogFormTitle() {
            if(this.show_type === 0) {
                return this.contract.name ? '合同：' + this.contract.name : '合同详情'
            } else if (this.show_type === 1) {
                return '添加合同'
            } else {
                return '修改合同'
            }
        },
        settlement_mode_name() {
            if((this.contract.settlement_mode !== '') && (this.contract.settlement_day_type !== '')) {
                if(this.contract.settlement_mode === 7) {
                    return '次月' + this.contract.settlement_day_num + '日之前'
                } else if(this.contract.settlement_mode === 10) {
                    return '本月' + this.contract.settlement_day_num + '日之前'
                } else {
                    return this.AllSettlementMode[this.contract.settlement_mode] + this.contract.settlement_day_num+'个'+this.AllSettlementDayType[this.contract.settlement_day_type]+'内'
                }
            } else {
                return ''
            }
        }
    },
    methods: {
        closeContractDialog(formName) {
            this.contract = {
                urgent_state: '',
                expire_option: '',
                title: '',
                enterprise_id: '',
                contract_type:'',
                business_type:'',
                subject_num: '',
                company_a_id:'',
                company_a_person:'',
                company_a_phone:'',
                company_b_id:'',
                company_b_person:'',
                company_b_phone:'',
                company_c_id:'',
                company_c_person:'',
                company_c_phone:'',
                company_d_id:'',
                company_d_person:'',
                company_d_phone:'',
                start_date:'',
                end_date:'',
                invoice_type:'',
                settlement_currency:'',
                settlement_mode:'',
                settlement_day_num:'',
                settlement_day_type:'',
                settlement_cycle:'',
                pay_way:'',
                staff_id:this.$store.state.user.id,
                risk:'',
                contract_note:'',
                contract_other:[],             
                print: []
            }
            this.AllClient = []
            this.$emit('exitContractDialog')
        },
        submitInsert(formName) {
            this.$refs[formName].validate((valid) => {
                if (!valid) {
                    return
                }
                this.contractInsert(formName)
            })
        },
        contractInsert(formName) {
            this.login_loading = true
            let d = this.contract
            d.contract_other = this.$refs.contract_upload.fileList
            contract_add_request(d)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.$message.success('添加成功!')
                        this.$emit('exitContractDialog')
                        this.$emit('search')
                        this.$emit('details_row',s.result.id*1)
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        resetForm(formName) {
            this.contract = {
                urgent_state: '',
                expire_option: '',
                title: '',
                enterprise_id: '',
                contract_type:'',
                business_type:'',
                subject_num: '',
                company_a_id:'',
                company_a_person:'',
                company_a_phone:'',
                company_b_id:'',
                company_b_person:'',
                company_b_phone:'',
                company_c_id:'',
                company_c_person:'',
                company_c_phone:'',
                company_d_id:'',
                company_d_person:'',
                company_d_phone:'',
                start_date:'',
                end_date:'',
                invoice_type:'',
                settlement_currency:'',
                settlement_mode:'',
                settlement_day_num:'',
                settlement_day_type:'',
                settlement_cycle:'',
                pay_way:'',
                staff_id:this.$store.state.user.id,
                risk:'',
                contract_note:'',
                contract_other:[],            
                print: []
            }
        },
        submitEdit(formName) {
            this.$refs[formName].validate((valid) => {
                if (!valid) {
                    return
                }
                this.contractEdit(formName)
            })
        },
        contractEdit(formName) {
            this.login_loading = true
            this.contract.id = this.id
            this.contract.contract_other = this.$refs.contract_upload.fileList
            contract_edit_request(this.contract)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.$message.success('修改成功!')
                        this.$emit('exitContractDialog')
                        this.$emit('search')
                        this.$emit('details_row',this.contract.id)
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        contractDel(formName) {
            this.$confirm(
                '是否确定删除？（一经删除，将无法再进行恢复，请谨慎操作！）',
                '温馨提示',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            )
                .then(() => {
                    contract_del_request(this.id)
                        .then((s) => {
                            if (s.status === 0) {
                                this.$message.success('删除成功!')
                                this.$emit('search')
                            } else {
                                this.$message.error(s.msg)
                            }
                        })
                        .catch((err) => {
                            this.$message.error(err)
                        })
                        .finally(() => this.closeContractDialog(formName))
                })
                .catch((err) => {})
        },
        get_staff_list() {
            staff_list_request({})
                .then((s) => {
                    this.AllStaff = s.result
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        to_approval(formName) {
            this.$prompt('请填写申请原因', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                }).then(({ value }) => {
                    this.login_loading = true
                    contract_to_approval_request({id:this.id,note:value})
                        .then((s) => {
                            this.login_loading = false
                            if (s.status === 0) {
                                    this.$message.success('提交成功!')
                                    this.$emit('search')
                            } else {
                                this.$message.error(s.msg)
                            }
                        })
                        .catch((err) => {
                            this.login_loading = false
                            this.$message.error(err)
                        })
                        .finally(() => this.closeContractDialog(formName))
                }).catch(()=>{});
        },
        exitApprovalDialog() {
            this.ApprovalDialogVisible = false
        },
        sel_approval() {
            this.login_loading = true
            contract_sel_approval_request(this.id)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.ApprovalData = s.result
                        this.ApprovalDialogVisible = true
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        contractWithdraw() {
            this.$confirm(
                '是否确定撤回？（请谨慎操作）',
                '温馨提示',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            )
                .then(() => {
                    this.login_loading = true
                    contract_withdraw_request({id:this.id})
                        .then((s) => {
                            this.login_loading = false
                            if (s.status === 0) {
                                this.$message.success('已撤回!')
                                this.$emit('frefresh')
                                this.$emit('search')
                            } else {
                                this.$message.error(s.msg)
                            }
                        })
                        .catch((err) => {
                            this.login_loading = false
                            this.$message.error(err)
                        })
                })
                .catch((err) => {})
        },
        clk_print() {
            this.login_loading = true
            contract_sel_approval_request(this.id)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.ApprovalData = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
            this.show_print = true
        },
        record() {
            this.RecordDialogType = 1
            this.RecordDialogVisible = true
            contract_record_history_request(this.id)
                .then((s) => {                    
                    if (s.status === 0) {
                        this.contract_record_history_data = s.result                        
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        record_sel() {
            this.RecordDialogType = 0
            this.RecordDialogVisible = true
            contract_record_details_request(this.id)
                .then((s) => {                    
                    if (s.status === 0) {
                        this.contract_record_details_data = s.result                        
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        exitRecirdDialog() {
            this.RecordDialogType = 0
            this.RecordDialogVisible = false
        },
        edit_recird() {
            this.RecordDialogType = 2
        },
        refresh_details_index() {
            this.RecordDialogType = 0
            // this.RecordDialogVisible = true
            this.$emit('frefresh')
            this.$emit('search')
        },
        company_a_change(client_id) {
            let person = ''
            let phone = ''
            this.AllClient.forEach(e => {
                if(e.id === client_id) {
                    person = e.contacts
                    phone = e.phone
                    return
                }
            });

            this.contract.company_a_person = person
            this.contract.company_a_phone = phone

            //获取合作企业附件
            cooperative_enterprise_get_file_request(client_id)
                .then((s) => {                    
                    if (s.status === 0) {
                        //存在合同附件
                        if(s.result.length > 0) {
                            s.result.forEach(v => {
                                this.contract.contract_other.push(v)
                            })
                        }
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        company_b_change(client_id) {
            let person = ''
            let phone = ''
            this.AllClient.forEach(e => {
                if(e.id === client_id) {
                    person = e.contacts
                    phone = e.phone
                    return
                }
            });

            this.contract.company_b_person = person
            this.contract.company_b_phone = phone
        },
        company_c_change(client_id) {
            let person = ''
            let phone = ''
            this.AllClient.forEach(e => {
                if(e.id === client_id) {
                    person = e.contacts
                    phone = e.phone
                    return
                }
            });

            this.contract.company_c_person = person
            this.contract.company_c_phone = phone
        },
        company_d_change(client_id) {
            let person = ''
            let phone = ''
            this.AllClient.forEach(e => {
                if(e.id === client_id) {
                    person = e.contacts
                    phone = e.phone
                    return
                }
            });

            this.contract.company_d_person = person
            this.contract.company_d_phone = phone
        },
        my_enterprise_change(e) {
            const with_zhwl_enterprise_name = this.AllEnterprise.filter(v=>v.id===e)[0].name
            client_list_request({with_zhwl_enterprise: with_zhwl_enterprise_name})
                .then((s) => {
                    this.AllClient = s.result
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        add_file() {
            this.AddFileDialogVisible = true
        },
        exit_add_file() {
            this.AddFileDialogVisible = false
            this.file_arr = []
        },
        submit_add_file() {
            let data = {}
            this.file_arr = this.$refs.contract_file_upload.fileList
            data['id'] = this.id
            data['file'] = this.file_arr
            contract_add_file_request(data)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.$message.success('补充附件成功!')
                        this.$emit('frefresh')
                        this.AddFileDialogVisible = false
                        this.file_arr = []
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        cancel_contract() {
            this.$confirm(
                '是否确定作废？（一旦作废，将无法再进行恢复，请谨慎操作！）',
                '温馨提示',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            )
                .then(() => {
                    contract_cancel_request({id: this.id})
                        .then((s) => {
                            if (s.status === 0) {
                                this.$message.success('已作废!')
                                this.$emit('search')
                            } else {
                                this.$message.error(s.msg)
                            }
                        })
                        .catch((err) => {
                            this.$message.error(err)
                        })
                        .finally(() => this.closeContractDialog())
                })
                .catch((err) => {})
        },
        Renewal() {
            const data = {
                urgent_state: this.contract.urgent_state,
                expire_option: this.contract.expire_option,
                title: '(续签)'+this.contract.title,
                enterprise_id: this.contract.enterprise_id,
                contract_type: this.contract.contract_type,
                business_type: this.contract.business_type,
                subject_num: this.contract.subject_num,
                company_a_id: this.contract.company_a_id,
                company_a_person: this.contract.company_a_person,
                company_a_phone: this.contract.company_a_phone,
                company_b_id: this.contract.company_b_id,
                company_b_person: this.contract.company_b_person,
                company_b_phone: this.contract.company_b_phone,
                company_c_id: this.contract.company_c_id,
                company_c_person: this.contract.company_c_person,
                company_c_phone: this.contract.company_c_phone,
                company_d_id: this.contract.company_d_id,
                company_d_person: this.contract.company_d_person,
                company_d_phone: this.contract.company_d_phone,
                start_date: '',
                end_date: '',
                invoice_type: this.contract.invoice_type,
                settlement_currency: this.contract.settlement_currency,
                settlement_mode: this.contract.settlement_mode,
                settlement_day_num: this.contract.settlement_day_num,
                settlement_day_type: this.contract.settlement_day_type,
                settlement_cycle: this.contract.settlement_cycle,
                pay_way: this.contract.pay_way,
                staff_id:this.$store.state.user.id,
                risk: this.contract.risk,
                contract_note: this.contract.contract_note,
                contract_other: this.contract.contract_other,             
                print: this.contract.print
            }

            this.my_enterprise_change(this.contract.enterprise_id)

            this.contract = data
            this.$emit('addTeam')
        },
        exitRemindDialog() {
            this.RemindDialogVisible = false
        },
        Remind() {
            this.RemindDialogVisible = true
        },
        jieshou_details_row(id) {
            this.$emit('frefresh')
        }
    },
    filters: {},
    props: {
        dialogFormVisible: Boolean,
        id: {
            type: Number,
            default() {
                return 0
            },
        },
        contract_details_data: {
            type: Object,
            default() {
                return {}
            },
        },
        contract_department: {
            type: Array,
            default() {
                return []
            },
        },
        show_type: {
            type:Number,
            default() {
                return 0
            },
        },
        edit_auth: {
            type:Boolean,
            default() {
                return false
            }
        },
        del_auth: {
            type:Boolean,
            default() {
                return false
            }
        },
        add_file_auth: {
            type:Boolean,
            default() {
                return false
            }
        },
        to_approval_auth: {
            type:Boolean,
            default() {
                return false
            }
        },
        sel_approval_auth: {
            type:Boolean,
            default() {
                return false
            }
        },
        ApprovalAuth: {
            type:Boolean,
            default() {
                return false
            }
        },
        withdraw_auth: {
            type:Boolean,
            default() {
                return false
            }
        },
        record_add_auth: {
            type:Boolean,
            default() {
                return false
            }
        },
        record_details_auth: {
            type:Boolean,
            default() {
                return false
            }
        },
        record_edit_auth: {
            type:Boolean,
            default() {
                return false
            }
        },
        record_del_auth: {
            type:Boolean,
            default() {
                return false
            }
        },
        cancel_auth: {
            type:Boolean,
            default() {
                return false
            }
        },
        print_auth: String,
        AllEnterprise: Array,
        AllUrgentState: Array,
        AllBusinessType: Array,
        AllContractType: Array,
        AllSubjectNum: Array,
        AllSettlementMode: Array,
        AllSettlementDayType: Array,
        AllSettlementCycle: Array,
        add_auth: String
    },
    created() {
        this.get_staff_list()
    },
    destoryed() {},
    mounted() {},
    updated() {},
    components: {
        CommonUploadComponent,
        CommonApprovalDialog,
        ContractPrintComponent,
        ContractRecirdComponent,
        ContractRemindComponent
    },
    watch: {
        contract_details_data(newVal) {
            this.contract = newVal
            this.contract.id = this.id

            let pay_name = ''
            const pay = this.pay_type

            let settlement_name = ''
            const settlement = this.settlement_type

            let invoice_name = ''
            const invoice = this.AllInvoiceType

            newVal.pay_way.forEach(e => {
                if(pay_name) {
                    pay_name += (' + '+pay[e])
                } else {
                    pay_name = pay[e]
                }
            });
            this.contract.pay_way_name = pay_name

            newVal.settlement_currency.forEach(e =>{
                if(settlement_name) {
                    settlement_name += (' + '+settlement[e])
                } else {
                    settlement_name = settlement[e]
                }                
            })
            this.contract.settlement_currency_name = settlement_name

            newVal.invoice_type.forEach(e =>{
                if(invoice_name) {
                    invoice_name += (' + '+invoice[e])
                } else {
                    invoice_name = invoice[e]
                }                
            })
            this.contract.invoice_type_name = invoice_name
        }
    }
}
</script>

<style lang='less'>
.bottomc {
    margin-bottom: 10px;
}
.el-date-editor.el-input {
    width: 100%;
}
.input_number {
    width: 100%;
}
.enterprise_idc,.urgent_statec,.subject_numc,.contract_typec,.business_typec,.invoice_typec,.settlement_currencyc,.pay_wayc,.staff_idc,.settlement_cyclec {
    width: 100%;
}
.settlement_modec {
    width: 50%;
}
.settlement_day_numc {
    width: 20%;
}
.settlement_day_typec {
    width: 30%;
}
</style>