<template>
    <div>
        <el-dialog
            :visible.sync="dialogFormVisible"
            :append-to-body="true"
            width="50%"
            :center="true"
            top="10vh"
            :close-on-click-modal="false"
            :before-close="() => closeDialog()"
            v-loading.fullscreen.lock="login_loading"
        >
            <template #title>
                <span style="font-weight: bold;">{{dialogFormTitle}}</span>
                <el-tag v-if="SupplementClockIn.state===0" type="info" size="mini">待提交</el-tag>
                <el-tag v-if="SupplementClockIn.state===1" size="mini">审批中</el-tag>
                <el-tag v-if="SupplementClockIn.state===2" type="success" size="mini">已通过</el-tag>
                <el-tag v-if="SupplementClockIn.state===3" type="danger" size="mini">已驳回</el-tag>
                <el-button
                    v-if="(SupplementClockIn.state>0) && ($_has(print_auth))"
                    plain
                    circle
                    icon="el-icon-printer"
                    @click="clk_print()"
                    ></el-button
                >
            </template>
            <div>
                <el-form
                    :model="SupplementClockIn"
                    :rules="rules"
                    ref="SupplementClockInEditForm"
                    label-width="100px"
                    size="small"
                >
                    <div v-if="show_type">
                        <fieldset class="fieldsetc">
                            <legend class="legendc">补签信息</legend>
                            <el-form-item label="补签原因" prop="content">
                            <el-input type="textarea" :rows="3" v-model="SupplementClockIn.content">
                            </el-input>                    
                            </el-form-item>
                            <el-form-item label="补签时间" prop="clock_in_time">
                                <el-date-picker
                                    v-model="SupplementClockIn.clock_in_time"
                                    align="right"
                                    type="datetime"
                                    placeholder="选择补签时间"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                    class="start_timec"
                                >
                                </el-date-picker>
                            </el-form-item>
                            <div style='color: #2828FF;font-size: 12px;font-weight: bold;text-align: center;'>【温馨提示】</div><br />
                            <div style='font-size: 12px;font-weight: bold;'>1、所有经部门负责人同意后的“晚到”、“早退”请按实际时间正常打卡，并向人事部说明情况后，不做“迟到”、“早退”处理。</div><br />
                            <div style='font-size: 12px;font-weight: bold;'>2、所有与实际进出公司时间不一致的补签申请，均按“迟到”或“早退”或“旷工”处理。</div><br />
                            <div style='font-size: 12px;font-weight: bold;'>3、补签请上传附件依据，如“电脑开/关机时间”、“与部门负责人请示的聊天记录”等。</div>
                        </fieldset>
                        <common-upload-component
                            ref="upload"
                            :ffileList="SupplementClockIn.other"
                            upload_url="supplement_clock_in/upload"
                        ></common-upload-component>
                    </div>
                    <div v-else>
                        <fieldset class="fieldsetc">
                            <legend class="legendc">补签信息</legend>
                            <el-descriptions
                                class="margin-top"
                                :column="1"
                                :labelStyle="{'width':'120px','text-align':'center'}"
                                :contentStyle="{'text-align': 'center'}"
                                border
                            >
                                <el-descriptions-item  :contentStyle="{'text-align':'left','white-space':'pre-line'}">
                                    <template slot="label">
                                        <span>补签原因</span>
                                    </template>
                                    <span>{{SupplementClockIn.content}}</span>
                                </el-descriptions-item>                         
                                <el-descriptions-item :contentStyle="{'text-align':'center','color':'#00BB00'}">
                                    <template slot="label">
                                        <span>补签时间</span>
                                    </template>
                                    <span>{{SupplementClockIn.clock_in_time}}</span>
                                </el-descriptions-item>                        
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>申请人</span>
                                    </template>
                                    <span>{{SupplementClockIn.create_staff_name}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>申请时间</span>
                                    </template>
                                    <span>{{SupplementClockIn.apply_date}}</span>
                                </el-descriptions-item>
                            </el-descriptions>
                        </fieldset>
                        <common-upload-component
                            v-show="SupplementClockIn.other.length"
                            ref="upload"
                            :ffileList="SupplementClockIn.other"
                            upload_url="supplement_clock_in/upload"
                            :disabled="true"
                        ></common-upload-component>
                        <common-upload-component
                            v-show="AddFileDialogVisible"
                            ref="file_upload"
                            :ffileList="file_arr"
                            upload_url="supplement_clock_in/upload"
                            title="补充附件"
                        ></common-upload-component>
                    </div>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer">
                <div v-if="show_type===0">
                    <div v-if="AddFileDialogVisible === false">
                        <el-button
                            v-if="add_file_auth"
                            type="success"
                            plain
                            @click="add_file()"
                            >补充附件</el-button
                        >
                        <el-button
                            v-if="to_approval_auth"
                            type="success"
                            plain
                            @click="to_approval()"
                            >提交</el-button
                        >
                        <el-button
                            v-if="sel_approval_auth"
                            type="warning"
                            plain
                            @click="sel_approval()"
                            >查看审批</el-button
                        >
                        <el-button
                            v-if="withdraw_auth"
                            type="danger"
                            plain
                            @click="SupplementClockInWithdraw()"
                            >审批撤回</el-button
                        >
                        <el-button
                            v-if="edit_auth"
                            type="primary"
                            plain
                            @click="$emit('show_edit')"
                            >修改</el-button
                        >
                        <el-button
                            v-if="del_auth"
                            type="danger"
                            plain
                            @click="SupplementClockInDel()"
                            >删除</el-button
                        >
                        <el-button
                            plain
                            @click="closeDialog()"
                            >关闭</el-button
                        >
                    </div>
                    <div v-else>
                        <el-button
                            type="success"
                            plain
                            @click="submit_add_file()"
                            >确定</el-button
                        >
                        <el-button
                            plain
                            @click="exit_add_file()"
                            >取消</el-button
                        >
                    </div>
                </div>
                <div v-else-if="show_type===1">
                    <el-button
                        type="success"
                        plain
                        @click="submitInsert('SupplementClockInEditForm')"
                        >确定</el-button
                    >
                    <el-button
                        type="warning"
                        plain
                        @click="resetForm('SupplementClockInEditForm')"
                        >重置</el-button
                    >
                    <el-button
                        plain
                        @click="closeDialog()"
                        >关闭</el-button
                    >
                </div>
                <div v-else>
                    <el-button
                        type="success"
                        plain
                        @click="submitEdit('SupplementClockInEditForm')"
                        >确定修改</el-button
                    >
                    <el-button
                        plain
                        @click="closeDialog()"
                        >取消操作</el-button
                    >
                </div>
                <common-approval-dialog
                    :id="id"
                    :ApprovalDialogVisible="ApprovalDialogVisible"
                    :data="ApprovalData"
                    :current_examine_staff_id="SupplementClockIn.current_examine_staff_id"
                    :ApprovalAuth="ApprovalAuth"
                    :ApprovalRequest="supplement_clock_inApprovalRequest"
                    @exitApprovalDialog="exitApprovalDialog"
                    @refresh="$emit('frefresh')"
                >
                </common-approval-dialog>
                <supplement-clock-in-print-component
                    :show_print="show_print"
                    :data="SupplementClockIn"
                    :ApprovalData="ApprovalData"
                    @exit_print="show_print = false"
                >
                </supplement-clock-in-print-component>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import CommonUploadComponent from '@/components/common/CommonUploadComponent'
import CommonApprovalDialog from '@/components/common/CommonApprovalDialog'
import SupplementClockInPrintComponent from './SupplementClockInPrintComponent'

import {
    supplement_clock_in_add_request,
    supplement_clock_in_del_request,
    supplement_clock_in_edit_request,
    supplement_clock_in_approval_request,
    supplement_clock_in_sel_approval_request,
    supplement_clock_in_to_approval_request,
    supplement_clock_in_withdraw_request,
    supplement_clock_in_add_file_request
} from '@/network/hrm/SupplementClockIn.js'

export default {
    name: 'SupplementClockInEditComponent',
    data() {
        return {
            login_loading: false,
            ApprovalDialogVisible: false,
            SupplementClockIn: {
                clock_in_time: '',
                content: '',
                other: [],
                print: [],
            },
            rules: {                
                clock_in_time: [
                    {
                        required: true,
                        message: '请填写补签时间',
                        trigger: 'blur',
                    },
                ],
                content: [
                    {
                        required: true,
                        message: '请填写补签原因',
                        trigger: 'blur',
                    },
                ],
            },
            ApprovalData: [],
            supplement_clock_inApprovalRequest:supplement_clock_in_approval_request,
            show_print: false,
            AddFileDialogVisible: false,
            //补充附件
            file_arr: []
        }
    },
    computed: {
        dialogFormTitle() {
            if(this.show_type === 0) {
                return this.SupplementClockIn.name ? '补签单：' + this.SupplementClockIn.name : '补签单详情'
            } else if (this.show_type === 1) {
                return '添加补签申请'
            } else {
                return '修改补签申请'
            }
        },
    },
    methods: {
        closeDialog() {
            this.SupplementClockIn= {
                clock_in_time: '',
                content: '',
                other: [],
                print: []
            }
            this.$emit('exitDialog')
        },
        submitInsert(formName) {
            this.$refs[formName].validate((valid) => {
                if (!valid) {
                    return
                }
                this.SupplementClockInInsert(formName)
            })
        },
        SupplementClockInInsert(formName) {
            this.login_loading = true
            this.SupplementClockIn.other = this.$refs.upload.fileList

            if(this.SupplementClockIn.other.length === 0) {
                    this.$message.error('补签必须上传附件依据，便于人事部于每月底进行统一审查')
                    this.login_loading = false
                    return
                }

            supplement_clock_in_add_request(this.SupplementClockIn)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.$message.success('添加成功!')
                        this.$emit('exitDialog')
                        this.$emit('search')
                        this.$emit('details_row',s.result.id*1)
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        resetForm(formName) {
            this.$refs[formName].resetFields()
        },
        submitEdit(formName) {
            this.$refs[formName].validate((valid) => {
                if (!valid) {
                    return
                }
                this.SupplementClockInEdit(formName)
            })
        },
        SupplementClockInEdit(formName) {
            this.login_loading = true
            this.SupplementClockIn.id = this.id
            this.SupplementClockIn.other = this.$refs.upload.fileList

            if(this.SupplementClockIn.other.length === 0) {
                    this.$message.error('补签必须上传附件依据，便于人事部于每月底进行统一审查')
                    this.login_loading = false
                    return
                }
                
            supplement_clock_in_edit_request(this.SupplementClockIn)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.$message.success('修改成功!')
                        this.$emit('exitDialog')
                        this.$emit('search')
                        this.$emit('details_row',this.SupplementClockIn.id)
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        SupplementClockInDel(formName) {
            this.$confirm(
                '是否确定删除？（一经删除，将无法再进行恢复，请谨慎操作！）',
                '温馨提示',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            )
                .then(() => {
                    supplement_clock_in_del_request(this.id)
                        .then((s) => {
                            if (s.status === 0) {
                                this.$message.success('删除成功!')
                                this.$emit('search')
                            } else {
                                this.$message.error(s.msg)
                            }
                        })
                        .catch((err) => {
                            this.$message.error(err)
                        })
                        .finally(() => this.closeDialog())
                })
                .catch((err) => {})
        },
        to_approval() {
            this.$prompt('请填写申请原因', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                }).then(({ value }) => {
                    this.login_loading = true
                   supplement_clock_in_to_approval_request({id:this.id,note:value})
                        .then((s) => {
                            this.login_loading = false
                            if (s.status === 0) {
                                    this.$message.success('提交成功!')
                                    this.$emit('search')
                            } else {
                                this.$message.error(s.msg)
                            }
                        })
                        .catch((err) => {
                            this.login_loading = false
                            this.$message.error(err)
                        })
                        .finally(() => this.closeDialog())
                }).catch(()=>{});
        },
        exitApprovalDialog() {
            this.ApprovalDialogVisible = false
        },
        sel_approval() {
            this.login_loading = true
            supplement_clock_in_sel_approval_request(this.id)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.ApprovalData = s.result
                        this.ApprovalDialogVisible = true
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        SupplementClockInWithdraw() {
            this.$confirm(
                '是否确定撤回？（请谨慎操作）',
                '温馨提示',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            )
                .then(() => {
                    this.login_loading = true
                    supplement_clock_in_withdraw_request({id:this.id})
                        .then((s) => {
                            this.login_loading = false
                            if (s.status === 0) {
                                this.$message.success('已撤回!')
                                this.$emit('frefresh')
                                this.$emit('search')
                            } else {
                                this.$message.error(s.msg)
                            }
                        })
                        .catch((err) => {
                            this.login_loading = false
                            this.$message.error(err)
                        })
                })
                .catch((err) => {})
        },
        clk_print() {
            this.login_loading = true
            supplement_clock_in_sel_approval_request(this.id)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.ApprovalData = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
            this.show_print = true
        },
        add_file() {
            this.AddFileDialogVisible = true
        },
        exit_add_file() {
            this.AddFileDialogVisible = false
            this.file_arr = []
        },
        submit_add_file() {
            let data = {}
            this.file_arr = this.$refs.file_upload.fileList
            data['id'] = this.id
            data['file'] = this.file_arr
            supplement_clock_in_add_file_request(data)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.$message.success('补充附件成功!')
                        this.$emit('frefresh')
                        this.AddFileDialogVisible = false
                        this.file_arr = []
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        }
    },
    filters: {

    },
    props: {
        dialogFormVisible: Boolean,
        id: {
            type: Number,
            default() {
                return 0
            },
        },
        supplement_clock_in_details_data: {
            type: Object,
            default() {
                return {}
            },
        },
        show_type: {
            type:Number,
            default() {
                return 0
            },
        },
        edit_auth: {
            type:Boolean,
            default() {
                return false
            }
        },
        del_auth: {
            type:Boolean,
            default() {
                return false
            }
        },
        add_file_auth: {
            type:Boolean,
            default() {
                return false
            }
        },
        to_approval_auth: {
            type:Boolean,
            default() {
                return false
            }
        },
        sel_approval_auth: {
            type:Boolean,
            default() {
                return false
            }
        },
        ApprovalAuth: {
            type:Boolean,
            default() {
                return false
            }
        },
        withdraw_auth: {
            type:Boolean,
            default() {
                return false
            }
        },
        print_auth: String
    },
    created() {
    },
    destoryed() {},
    mounted() {},
    updated() {},
    components: {
        CommonUploadComponent,
        CommonApprovalDialog,
        SupplementClockInPrintComponent
    },
    watch: {
            supplement_clock_in_details_data(newVal) {
            this.SupplementClockIn = newVal
            this.SupplementClockIn.id = this.id
        },
    },
}
</script>

<style> 
.el-date-editor.el-input {
    width: 100%;
}
.typec {
    width: 100%;
}
</style>