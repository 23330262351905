<template>
    <div>
        <el-dialog
            :visible.sync="dialogFormVisible"
            :append-to-body="true"
            width="50%"
            :center="true"
            top="10vh"
            :close-on-click-modal="false"
            :before-close="() => closeDialog()"
            v-loading.fullscreen.lock="login_loading"
        >
            <template #title>
                <span style="font-weight: bold;">{{dialogFormTitle}}</span>
                <el-tag v-if="vacation.state===0" type="info" size="mini">待提交</el-tag>
                <el-tag v-if="vacation.state===1" size="mini">审批中</el-tag>
                <el-tag v-if="vacation.state===2" type="success" size="mini">已通过</el-tag>
                <el-tag v-if="vacation.state===3" type="danger" size="mini">已驳回</el-tag>
                <el-button
                    v-if="(vacation.state>0) && ($_has(print_auth))"
                    plain
                    circle
                    icon="el-icon-printer"
                    @click="clk_print()"
                    ></el-button
                >
            </template>
            <div>
                <el-form
                    :model="vacation"
                    :rules="rules"
                    ref="VacationEditForm"
                    label-width="100px"
                    size="small"
                >
                    <div v-if="show_type">
                        <fieldset class="fieldsetc">
                            <legend class="legendc">休假信息</legend>
                            <el-row :gutter="10">
                                <el-col :span="12">
                                    <el-form-item  label="休假类型" prop="type">
                                        <el-select
                                            v-model="vacation.type"
                                            filterable
                                            clearable
                                            placeholder="选择休假类型"
                                            class="typec"
                                            @change="type_change"
                                        >
                                            <el-option
                                                v-for="item in AllType"
                                                :key="item.id"
                                                :label="item.name"
                                                :value="item.id"
                                            >
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="休假天数" prop="duration">
                                        <el-input-number
                                            v-model="vacation.duration"
                                            :min="0"
                                            :max="max_duration"
                                            :step="0.5"
                                            :precision="1"
                                            :step-strictly="true"
                                            size="small"
                                            class="input_number"
                                        ></el-input-number>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row :gutter="10">
                                <el-col :span="12">
                                    <el-form-item label="开始日期" prop="start_date">
                                        <el-date-picker
                                            v-model="vacation.start_date"
                                            type="date"
                                            value-format="yyyy-MM-dd"
                                            placeholder="选择日期">
                                        </el-date-picker>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="开始时间" prop="start_time">
                                        <el-select
                                            v-model="vacation.start_time"
                                            filterable
                                            clearable
                                            placeholder="选择开始时间"
                                            class="typec"
                                        >
                                            <el-option
                                                v-for="item in [{i:'08:30',v:'08:30'},{i:'13:30',v:'13:30'}]"
                                                :key="item.i"
                                                :label="item.v"
                                                :value="item.i"
                                            >
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row :gutter="10">
                                <el-col :span="12">
                                    <el-form-item label="结束日期" prop="end_date">
                                        <el-date-picker
                                            v-model="vacation.end_date"
                                            type="date"
                                            value-format="yyyy-MM-dd"
                                            placeholder="选择日期">
                                        </el-date-picker>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="结束时间" prop="end_time">
                                        <el-select
                                            v-model="vacation.end_time"
                                            filterable
                                            clearable
                                            placeholder="选择结束时间"
                                            class="typec"
                                        >
                                            <el-option
                                                v-for="item in [{i:'12:00',v:'12:00'},{i:'17:30',v:'17:30'}]"
                                                :key="item.i"
                                                :label="item.v"
                                                :value="item.i"
                                            >
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-form-item label="休假原因" prop="content">
                                <el-input type="textarea" :rows="3" v-model="vacation.content"></el-input>
                            </el-form-item>
                        </fieldset>
                        <common-upload-component
                            ref="upload"
                            :ffileList="vacation.other"
                            upload_url="vacation/upload"
                        ></common-upload-component>
                    </div>
                    <div v-else>
                        <fieldset class="fieldsetc">
                            <legend class="legendc">休假信息</legend>
                            <el-descriptions
                                class="margin-top"
                                :column="2"
                                :labelStyle="{'width':'120px','text-align':'center'}"
                                :contentStyle="{'text-align': 'center'}"
                                border
                            >
                                <el-descriptions-item :span="2">
                                    <template slot="label">
                                        <span>休假标题</span>
                                    </template>
                                    <span>{{vacation.title ? vacation.title : ''}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>休假类型</span>
                                    </template>
                                    <span>{{vacation.type_name}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>休假时长</span>
                                    </template>
                                    <span>{{vacation.duration}}天</span>
                                </el-descriptions-item>
                                <el-descriptions-item :contentStyle="{'text-align':'center','color':'#00BB00'}">
                                    <template slot="label">
                                        <span>开始日期</span>
                                    </template>
                                    <span>{{vacation.start_date}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item :contentStyle="{'text-align':'center','color':'#00BB00'}">
                                    <template slot="label">
                                        <span>开始时间</span>
                                    </template>
                                    <span>{{vacation.start_time}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item :contentStyle="{'text-align':'center','color':'#FF0000'}">
                                    <template slot="label">
                                        <span>结束日期</span>
                                    </template>
                                    <span>{{vacation.end_date}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item :contentStyle="{'text-align':'center','color':'#FF0000'}">
                                    <template slot="label">
                                        <span>结束时间</span>
                                    </template>
                                    <span>{{vacation.end_time}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item :span="2" :contentStyle="{'text-align':'left','white-space':'pre-line'}">
                                    <template slot="label">
                                        <span>休假原因</span>
                                    </template>
                                    <span>{{vacation.content}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>申请人</span>
                                    </template>
                                    <span>{{vacation.create_staff_name}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>申请时间</span>
                                    </template>
                                    <span>{{vacation.apply_date}}</span>
                                </el-descriptions-item>
                            </el-descriptions>
                        </fieldset>
                        <common-upload-component
                            v-show="vacation.other.length"
                            ref="upload"
                            :ffileList="vacation.other"
                            upload_url="vacation/upload"
                            :disabled="true"
                        ></common-upload-component>
                        <common-upload-component
                            v-show="AddFileDialogVisible"
                            ref="file_upload"
                            :ffileList="file_arr"
                            upload_url="vacation/upload"
                            title="补充附件"
                        ></common-upload-component>
                    </div>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer">
                <div v-if="show_type===0">
                    <div v-if="AddFileDialogVisible === false">
                        <el-button
                            v-if="add_file_auth"
                            type="success"
                            plain
                            @click="add_file()"
                            >补充附件</el-button
                        >
                        <el-button
                            v-if="to_approval_auth"
                            type="success"
                            plain
                            @click="to_approval()"
                            >提交</el-button
                        >
                        <el-button
                            v-if="sel_approval_auth"
                            type="warning"
                            plain
                            @click="sel_approval()"
                            >查看审批</el-button
                        >
                        <el-button
                            v-if="withdraw_auth"
                            type="danger"
                            plain
                            @click="vacationWithdraw()"
                            >审批撤回</el-button
                        >
                        <el-button
                            v-if="edit_auth"
                            type="primary"
                            plain
                            @click="$emit('show_edit')"
                            >修改</el-button
                        >
                        <el-button
                            v-if="del_auth"
                            type="danger"
                            plain
                            @click="vacationDel()"
                            >删除</el-button
                        >
                        <el-button
                            plain
                            @click="closeDialog()"
                            >关闭</el-button
                        >
                    </div>
                    <div v-else>
                        <el-button
                            type="success"
                            plain
                            @click="submit_add_file()"
                            >确定</el-button
                        >
                        <el-button
                            plain
                            @click="exit_add_file()"
                            >取消</el-button
                        >
                    </div>
                </div>
                <div v-else-if="show_type===1">
                    <el-button
                        type="success"
                        plain
                        @click="submitInsert('VacationEditForm')"
                        >确定</el-button
                    >
                    <el-button
                        type="warning"
                        plain
                        @click="resetForm('VacationEditForm')"
                        >重置</el-button
                    >
                    <el-button
                        plain
                        @click="closeDialog()"
                        >关闭</el-button
                    >
                </div>
                <div v-else>
                    <el-button
                        type="success"
                        plain
                        @click="submitEdit('VacationEditForm')"
                        >确定修改</el-button
                    >
                    <el-button
                        plain
                        @click="closeDialog()"
                        >取消操作</el-button
                    >
                </div>
                <common-approval-dialog
                    :id="id"
                    :ApprovalDialogVisible="ApprovalDialogVisible"
                    :data="ApprovalData"
                    :current_examine_staff_id="vacation.current_examine_staff_id"
                    :ApprovalAuth="ApprovalAuth"
                    :ApprovalRequest="VacationApprovalRequest"
                    @exitApprovalDialog="exitApprovalDialog"
                    @refresh="$emit('frefresh')"
                >
                </common-approval-dialog>
                <vacation-print-component
                    :show_print="show_print"
                    :data="vacation"
                    :ApprovalData="ApprovalData"
                    @exit_print="show_print = false"
                >
                </vacation-print-component>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import CommonUploadComponent from '@/components/common/CommonUploadComponent'
import CommonApprovalDialog from '@/components/common/CommonApprovalDialog'
import VacationPrintComponent from './VacationPrintComponent'

import {
    vacation_add_request,
    vacation_del_request,
    vacation_edit_request,
    vacation_to_approval_request,
    vacation_sel_approval_request,
    vacation_approval_request,
    vacation_withdraw_request,
    vacation_add_file_request
} from '@/network/hrm/vacation.js'
import { vacation_category_list_request } from '@/network/list.js'

export default {
    name: 'VacationEditComponent',
    data() {
        return {
            login_loading: false,
            ApprovalDialogVisible: false,
            vacation: {
                type: '',
                duration: '',
                start_date: '',
                start_time: '',
                end_date: '',
                end_time: '',
                content: '',
                other: [],
                print: []
            },
            rules: {
                type: [
                    {
                        required: true,
                        message: '请选择休假类型',
                        trigger: 'blur',
                    },
                ],
                duration: [
                    {
                        required: true,
                        message: '请填写休假时长',
                        trigger: 'blur',
                    },
                ],
                start_date: [
                    {
                        required: true,
                        message: '请选择开始日期',
                        trigger: 'blur',
                    },
                ],
                start_time: [
                    {
                        required: true,
                        message: '请选择开始时间',
                        trigger: 'blur',
                    },
                ],
                end_date: [
                    {
                        required: true,
                        message: '请选择结束日期',
                        trigger: 'blur',
                    }
                ],
                end_time: [
                    {
                        required: true,
                        message: '请选择结束时间',
                        trigger: 'blur',
                    }
                ],
                content: [
                    {
                        required: true,
                        message: '请填写休假原因',
                        trigger: 'blur',
                    },
                ],
            },
            AllType: [],
            ApprovalData: [],
            VacationApprovalRequest:vacation_approval_request,
            show_print: false,
            max_duration: 999,
            AddFileDialogVisible: false,
            //补充附件
            file_arr: []
        }
    },
    computed: {
        dialogFormTitle() {
            if(this.show_type === 0) {
                return this.vacation.name ? '休假单：' + this.vacation.name : '休假单详情'
            } else if (this.show_type === 1) {
                return '添加休假申请'
            } else {
                return '修改休假申请'
            }
        },
    },
    methods: {
        closeDialog() {
            this.vacation = {
                type: '',
                duration: '',
                start_date: '',
                start_time: '',
                end_date: '',
                end_time: '',
                content: '',
                other: [],
                print: []
            }
            this.$emit('exitDialog')
        },
        submitInsert(formName) {
            this.$refs[formName].validate((valid) => {
                if (!valid) {
                    return
                }
                if(!this.vacation.duration) {
                    this.$message.error('休假天数不能为0')
                    return
                }
                this.vacationInsert(formName)
            })
        },
        vacationInsert(formName) {
            this.login_loading = true
            this.vacation.other = this.$refs.upload.fileList
            vacation_add_request(this.vacation)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.$message.success('添加成功!')
                        this.$emit('exitDialog')
                        this.$emit('search')
                        this.$emit('details_row',s.result.id*1)
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        resetForm(formName) {
            this.$refs[formName].resetFields()
        },
        submitEdit(formName) {
            this.$refs[formName].validate((valid) => {
                if (!valid) {
                    return
                }
                if(!this.vacation.duration) {
                    this.$message.error('休假天数不能为0')
                    return
                }
                this.vacationEdit(formName)
            })
        },
        vacationEdit(formName) {
            this.login_loading = true
            this.vacation.id = this.id
            this.vacation.other = this.$refs.upload.fileList
            vacation_edit_request(this.vacation)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.$message.success('修改成功!')
                        this.$emit('exitDialog')
                        this.$emit('search')
                        this.$emit('details_row',this.vacation.id)
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        vacationDel(formName) {
            this.$confirm(
                '是否确定删除？（一经删除，将无法再进行恢复，请谨慎操作！）',
                '温馨提示',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            )
                .then(() => {
                    vacation_del_request(this.id)
                        .then((s) => {
                            if (s.status === 0) {
                                this.$message.success('删除成功!')
                                this.$emit('search')
                            } else {
                                this.$message.error(s.msg)
                            }
                        })
                        .catch((err) => {
                            this.$message.error(err)
                        })
                        .finally(() => this.closeDialog())
                })
                .catch((err) => {})
        },
        get_all_vacation_category_list() {
            vacation_category_list_request({})
                .then((s) => {
                    this.AllType = s.result
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        to_approval() {
            this.$prompt('请填写申请原因', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                }).then(({ value }) => {
                    this.login_loading = true
                    vacation_to_approval_request({id:this.id,note:value})
                        .then((s) => {
                            this.login_loading = false
                            if (s.status === 0) {
                                    this.$message.success('提交成功!')
                                    this.$emit('search')
                            } else {
                                this.$message.error(s.msg)
                            }
                        })
                        .catch((err) => {
                            this.login_loading = false
                            this.$message.error(err)
                        })
                        .finally(() => this.closeDialog())
                }).catch(()=>{});
        },
        exitApprovalDialog() {
            this.ApprovalDialogVisible = false
        },
        sel_approval() {
            this.login_loading = true
            vacation_sel_approval_request(this.id)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.ApprovalData = s.result
                        this.ApprovalDialogVisible = true
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        vacationWithdraw() {
            this.$confirm(
                '是否确定撤回？（请谨慎操作）',
                '温馨提示',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            )
                .then(() => {
                    this.login_loading = true
                    vacation_withdraw_request({id:this.id})
                        .then((s) => {
                            this.login_loading = false
                            if (s.status === 0) {
                                this.$message.success('已撤回!')
                                this.$emit('frefresh')
                                this.$emit('search')
                            } else {
                                this.$message.error(s.msg)
                            }
                        })
                        .catch((err) => {
                            this.login_loading = false
                            this.$message.error(err)
                        })
                })
                .catch((err) => {})
        },
        clk_print() {
            this.login_loading = true
            vacation_sel_approval_request(this.id)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.ApprovalData = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
            this.show_print = true
        },
        type_change(e) {
            this.vacation.duration = 0
            if(e===1) {
                //年假
                this.max_duration = this.$store.state.user.my_vacation.all_paid_vacation - this.$store.state.user.my_vacation.use_paid_vacation
                this.max_duration = Math.trunc(this.max_duration*2)/2
            } else if(e===8) {
                //育儿假
                this.max_duration = this.$store.state.user.my_vacation.all_child_vacation - this.$store.state.user.my_vacation.use_child_vacation
                this.max_duration = Math.trunc(this.max_duration*2)/2
                this.max_duration = this.max_duration < 0 ? 0 : this.max_duration
            } else if(e===4) {
                //调休
                const all_duration = this.$store.state.user.my_vacation.all_over_time
                const use_duration = Math.trunc(this.$store.state.user.my_vacation.use_over_time*8)
                const del_duration = this.$store.state.user.my_vacation.del_over_time
                this.max_duration = Math.trunc((all_duration - use_duration - del_duration)/4)/2
            } else {
                this.max_duration = 999
            }
        },
        add_file() {
            this.AddFileDialogVisible = true
        },
        exit_add_file() {
            this.AddFileDialogVisible = false
            this.file_arr = []
        },
        submit_add_file() {
            let data = {}
            this.file_arr = this.$refs.file_upload.fileList
            data['id'] = this.id
            data['file'] = this.file_arr
            vacation_add_file_request(data)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.$message.success('补充附件成功!')
                        this.$emit('frefresh')
                        this.AddFileDialogVisible = false
                        this.file_arr = []
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        }
    },
    filters: {
    },
    props: {
        dialogFormVisible: Boolean,
        id: {
            type: Number,
            default() {
                return 0
            },
        },
        vacation_details_data: {
            type: Object,
            default() {
                return {}
            },
        },
        show_type: {
            type:Number,
            default() {
                return 0
            },
        },
        edit_auth: {
            type:Boolean,
            default() {
                return false
            }
        },
        del_auth: {
            type:Boolean,
            default() {
                return false
            }
        },
        add_file_auth: {
            type:Boolean,
            default() {
                return false
            }
        },
        to_approval_auth: {
            type:Boolean,
            default() {
                return false
            }
        },
        sel_approval_auth: {
            type:Boolean,
            default() {
                return false
            }
        },
        ApprovalAuth: {
            type:Boolean,
            default() {
                return false
            }
        },
        withdraw_auth: {
            type:Boolean,
            default() {
                return false
            }
        },
        print_auth: String
    },
    created() {
        this.get_all_vacation_category_list()
    },
    destoryed() {},
    mounted() {},
    updated() {},
    components: {
        CommonUploadComponent,
        CommonApprovalDialog,
        VacationPrintComponent
    },
    watch: {
        vacation_details_data(newVal) {
            this.vacation = newVal
            this.vacation.id = this.id
        }
    },
}
</script>

<style> 
.el-date-editor.el-input {
    width: 100%;
}
.typec {
    width: 100%;
}
.input_number {
    width: 100%;
}
</style>